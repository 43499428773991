import cciLaborData from '../utils/cciLabor';
export class calculatorFormulas {
    laborType: string;
    fastenerPattern: string;
    cciLabor: number; // labor rate according to zipcode
    crewOP: number = 0;
    pordOP: number = 0;
    customLaborRate: number;
    zipCode: string;
    laborRate: number;
    squareFootage: number;
    screwCostPerFastener: number = 0.0145;
    cciXPord: number;

    constructor(laborType: string, fastenerPattern: string, squareFootage: number, screwCostPerFastener: number|null, customLaborRate: number|null, zipCode: string|null) {
        this.laborType = laborType;
        this.fastenerPattern = fastenerPattern;
        this.zipCode = zipCode;
        this.customLaborRate = customLaborRate ? customLaborRate : 0;
        this.squareFootage = squareFootage;
        this.screwCostPerFastener = screwCostPerFastener ? screwCostPerFastener : this.screwCostPerFastener;

        if(this.zipCode)
            this.setZipCodeRate();
        this.setCrew();
        this.setPord();
        this.setLaborRate();
        this.setCciXPord();
    }

    setZipCodeRate() {
        const zipDigits = this.zipCode.substring(0, 3);
        this.cciLabor = 1;
        
        cciLaborData.forEach((item) => {
            if(item.Zip == zipDigits) {
                this.cciLabor = item.Labor;
                this.customLaborRate = 0;
            }
        });
    }

    setCrew() {
        let carp = 0;
        let foremanInside = 0;
        let helper = 0;

        this.crewOP = 0;

        if(this.customLaborRate === 0) {
            if(this.laborType == 'union') {
                carp = 87.25; // TODO: get from the CMS?
                helper = 67.95; // TODO: cms
            } else if (this.laborType == 'open-shop') {
                carp = 68.75; // TODO: cms
                helper = 52.60; // TODO: cms
            }
    
            foremanInside = carp + 0.5;
            this.crewOP = carp + foremanInside + helper;
        }
    }

    setPord() {
        this.pordOP = 0;
        if(this.customLaborRate === 0) {
            if(this.laborType == 'union') {
                this.pordOP = 73.55; // TODO: get from the CMS?
            } else if(this.laborType == 'open-shop') {
                this.pordOP = 58.00; // TODO: get from the CMS?
            } 
        } else { // custom
            const pordRatioAv = (0.327 + 0.3043) / 2; // CCI-> Pord Ratio
            this.pordOP = this.customLaborRate * pordRatioAv;
        }
    }

    setLaborRate() {
        this.laborRate = this.customLaborRate ? this.customLaborRate : (this.crewOP * this.cciLabor);
    }

    setCciXPord() {
        if(this.laborType == 'union' || this.laborType == 'open-shop') {
            this.cciXPord = this.pordOP * this.cciLabor;
        } else {
            this.cciXPord = this.pordOP;
        }
    }

    getBoardCosts(patterns:string[]) {
        let results = [];
        
        for(const option of patterns) {
            let installationResults = 0; // (Total Labor per Day (Minutes) / 60);
            let installationResults_NGC = 0;
            let hoursPerSquareFeet = 0;
            let hoursPerSquareFeet_NGC = 0;
            let laborCostSf = 0;
            let laborCostSF_NGC = 0;
            let extLaborCost = 0;
            let extLaborCost_NGC = 0;
            let label = '';
            
            switch (option) {
                case 'v1212':
                    installationResults = 6.25; // TODO: cms | Vertical Installation | 12/12 Pattern | 1,200 SF --- Total Labor per Day (Hours)
                    installationResults_NGC = 5.42;
                    label = 'Vertical (12/12)';
                    break;

                case 'h1212':
                    installationResults = 6.08; // TODO: cms | Horizontal Installation | 12/12 Pattern | 1,200 SF --- Total Labor per Day (Hours)
                    installationResults_NGC = 5.5;
                    label = 'Horizontal (12/12)';
                    break;

                case 'v812':
                    installationResults = 6.33; // TODO: cms | Vertical Installation | 8/12 Pattern | 1,200 SF --- Total Labor per Day (Hours)
                    installationResults_NGC = 5.75;
                    label = 'Vertical (8/12)';
                    break;

                case 'h88':
                    installationResults = 6.5; // TODO: cms | Horizontal Installation | 8/8 Pattern | 1,200 SF --- Total Labor per Day (Hours)
                    installationResults_NGC = 5.92;
                    label = 'Horizontal (8/8)';
                    break;

                case 'v1616':
                    installationResults = (345 / 60); // TODO: cms | Vertical Installation | 16/16 Pattern | 1,200 SF -- Total Labor per Day (Hours)
                    installationResults_NGC = (290 / 60);
                    label = 'Vertical (16/16)';
                    break;

                case 'h1616':
                    installationResults = (290 / 60); // TODO: cms | Horizontal Installation | 16/16 Pattern | 1,200 SF
                    installationResults_NGC = (225 / 60);
                    label = 'Horizontal (16/16)';
                    break;

                case 'v812_16':
                    installationResults = (455 / 60); // TODO: cms | Vertical Installation | 8/12 Pattern | 1,200 SF
                    installationResults_NGC = (380 / 60);
                    label = 'Vertical (8/12)';
                    break;

                case 'h88_16':
                    installationResults = (410 / 60); // TODO: cms | Horizontal Installation | 8/8 Pattern | 1,200 SF
                    installationResults_NGC = (335 / 60);
                    label = 'Horizontal (8/8)';
                    break;
            }

            if(installationResults) {
                // Leading Competitor
                hoursPerSquareFeet = installationResults / 1200; // It's always 1200
                laborCostSf = this.laborRate * hoursPerSquareFeet;
                extLaborCost = laborCostSf * this.squareFootage;

                // National Gypsum
                hoursPerSquareFeet_NGC = installationResults_NGC / 1200;
                laborCostSF_NGC = this.laborRate * hoursPerSquareFeet_NGC;
                extLaborCost_NGC = laborCostSF_NGC * this.squareFootage;
            }

            const result = {
                label: label,
                laborCostSF: laborCostSf,
                extLaborCost: extLaborCost,
                laborCostSF_NGC: laborCostSF_NGC,
                extLaborCost_NGC: extLaborCost_NGC,
                savings: extLaborCost - extLaborCost_NGC,
                savingsPercentage: ((extLaborCost - extLaborCost_NGC) / extLaborCost) * 100
            }

            results.push(result);
        }

        return results;
    }

    getFastenerPatterns(patterns:string[]) {
        let results = [];
        
        for(const option of patterns) {
            let label = '';
            let spotting = 1;
            let average = 0;
            let extFastenerCost = 0;
            let spotFastenersLaborCostSF = 0;
            let spotFastenersExtLaborCost = 0;
            let totalCost = 0;
            let spottingMaterial = 0;

            switch (option) {
                case '12x12':
                    label = '12 x 12 Fastener Pattern';
                    average = 1.09 * this.squareFootage;
                    spotting = (95.75/1200); // Spotting sheet D9
                    // spottingMaterial = 0.00213; // Spotting Material Sheet C14
                    spottingMaterial = 0.002126;
                    break;
            
                case '8x12':
                    label = '8 x 12 Fastener Pattern';
                    average = 1.34 * this.squareFootage;
                    spotting = (101/1200); // H9
                    spottingMaterial = .002613; // D14
                    break;
                
                case '8x8':
                    label = '8 x 8 Fastener Pattern';
                    average = 1.59 * this.squareFootage;
                    spotting = (103.5/1200); // J9
                    spottingMaterial = 0.003101; // E14
                    break;
                
                case '16x16':
                    label = '16 x 16 Fastener Pattern';
                    average = 0.84 * this.squareFootage;
                    spotting = (38.5/1200); // D18
                    spottingMaterial = 0.003259; // I14
                    break;
                
                case '8x12_16': 
                    label = '8 x 12 Fastener Pattern';
                    average = 1.34 * this.squareFootage;
                    spotting = (101/1200); // H9
                    spottingMaterial = 0.005199; 
                    break;

                case '8x8_16':
                    label = '8 x 8 Fastener Pattern';
                    average = 1.59 * this.squareFootage;
                    spotting = (103.5/1200); // J9
                    spottingMaterial = 0.006169;
                    break;
            }

            const cciSpotting = this.cciXPord * spotting;

            extFastenerCost = average * this.screwCostPerFastener;
            spotFastenersLaborCostSF = cciSpotting / 60;
            spotFastenersExtLaborCost = spotFastenersLaborCostSF * this.squareFootage;
            totalCost = spotFastenersExtLaborCost + extFastenerCost;
            
            const result = {
                label: label, 
                average: average, 
                extFastenerCost: extFastenerCost,
                spotFastenersLaborCostSF: parseFloat(spotFastenersLaborCostSF.toFixed(3)),
                spotFastenersExtLaborCost: spotFastenersExtLaborCost,
                spotFastenerMaterialCostSF: spottingMaterial,
                spotFastenerExtMaterialCost: (spottingMaterial * this.squareFootage),
                totalCost: totalCost
            }

            results.push(result);
        }

        return results;
    }

    getMax12Results() {
        let result = {
            'boardResults': this.getBoardCosts(['v1212', 'h1212', 'v812', 'h88']),
            'fastenerPatternResults': this.getFastenerPatterns(['12x12', '8x12', '8x8'])
        }
        return result;
    }

    getMax16Results() {
        let result = {
            'boardResults': this.getBoardCosts(['v1616', 'h1616', 'v812_16', 'h88_16']),
            'fastenerPatternResults': this.getFastenerPatterns(['16x16', '8x12_16', '8x8_16'])
        }

        return result;
    }
}