import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { calculatorFormulas } from './calculatorFormulas';
import cciLaborData from '../utils/cciLabor';

const defaultOveralSavings = {
    total: 0,
    totalPercentage: 0
}

const gridMarxVerticalHeading = 'GridMarX Vertical';
const gridMarxHorizontalHeading = 'GridMarX Horizontal';
const industryVerticalHeading = 'Industry Standard Pattern Vertical (8/12)';
const industryHorizontalHeading = 'Industry Standard Pattern Horizontal (8/8)';

const logoMax12 = {
    url: '/dist/img/logos/max-12-logo.png',
    alt: 'MaX 12 Logo'
};
const logoMax16 = {
    url: '/dist/img/logos/max-16-white-logo.png',
    alt: 'MaX 16 Logo'
};

const baseUrl = location.href;

type resultSet = {
    boardResults: {
        label: string,
        laborCostSF: number,
        extLaborCost: number,
        laborCostSF_NGC: number,
        extLaborCost_NGC: number,
        savings: number,
        savingsPercentage: number
    }[],
    fastenerPatternResults: {
        label: string,
        average: number,
        extFastenerCost: number,
        spotFastenersLaborCostSF: number,
        spotFastenersExtLaborCost: number,
        spotFastenerMaterialCostSF: number,
        spotFastenerExtMaterialCost: number,
        totalCost: number
    }[]
}

type rowType = {
    laborCostSf: number,
    laborCost: number,
    totalAverageScrewSF: number,
    screwCost: number,
    spotFastenerLaborCostSF: number,
    spotFastenerLaborCost: number,
    spotFastenerMaterialCostSF: number,
    spotFastenerMaterialCost: number,
    total: number
}

type tableDataType = {
    gridMarx: rowType,
    industry: rowType,
    overalSaving: {
        laborCost: number,
        laborCostPercentage: number,
        spotFastener: number,
        spotFastenerPercentage: number,
        total: number,
        totalPercentage: number
    }
    overalMaterialSaving: {
        screwCost: number,
        screwCostPercentage: number,
        fastenerCost: number,
        fastenerCostPercentage: number,
        total: number,
        totalPercentage: number
    }
    overalMaterialAndLaborSaving: {
        total: number,
        totalPercentage: number
    }
}

type optionsType = {
    zipCode: string,
    laborCostPerHour: number,
    screwCostPerFastener: number,
    squareFootage: number,
    laborType: string,
    fastenerPattern: string,
    useGordianData: boolean
}

function Calculator(props) {
    const [tableData, tableDataSet] = useState<tableDataType>();
    const [max12Result, max12ResultSet] = useState<resultSet>();
    const [max16Result, max16ResultSet] = useState<resultSet>();
    const [overalSavings, overalSavingsSet] = useState(defaultOveralSavings);
    const [gridMarxTitle, gridMarxTitleSet] = useState(gridMarxVerticalHeading);
    const [industryTitle, industryTitleSet] = useState(industryVerticalHeading);
    const [buttonText, buttonTextSet] = useState('Calculate');
    const [orientation, orientationSet] = useState('vertical');
    const [showResults, showResultsSet] = useState(false);
    const [emailUrl, _emailUrlSet] = useState(baseUrl);
    const [pdfUrl, _pdfUrlSet] = useState('');
    const [logo, logoSet] = useState(logoMax12);
    const [validZip, validZipSet] = useState(true);
    const [hubspotFormId, _hubspotFormIdSet] = useState('');
    const [options, optionsSet] = useState<optionsType>({
        zipCode: "",
        laborCostPerHour: 200,
        screwCostPerFastener: 0.012,
        squareFootage: 50000,
        laborType: "union",
        fastenerPattern: "max-12",
        useGordianData: false
    });

    const emailUrlRef = useRef(emailUrl);
    const emailUrlSet = (data: string) => {
        emailUrlRef.current = data;
        _emailUrlSet(data);
    }

    const pdfUrlRef = useRef(pdfUrl);
    const pdfUrlSet = (data: string) => {
        pdfUrlRef.current = data;
        _pdfUrlSet(data);
    }

    const hubspotFormIdRef = useRef(hubspotFormId);
    const hubspotFormIdSet = (data: string) => {
        hubspotFormIdRef.current = data;
        _hubspotFormIdSet(data);
    }

    // Modal
    let gatedContent = document.querySelector('.CalculatorModal');
    const modalExit = document.querySelector('.ModalExit');

    async function postData(url = '', data = {}) {
        const response = await fetch(url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        });
        return response.json();
    }

    useEffect(() => {
        const queryString = window.location.search;
        let newOptions = options;

        if(queryString && !queryString.includes('utm') && !queryString.includes('x-craft-preview')) {
            const urlParameters = new URLSearchParams(queryString);
            for (const option in options) {
                if(option == 'laborCostPerHour' || option == 'screwCostPerFastener' || option == 'squareFootage') {
                    const paramValue = Number(urlParameters.get(option));
                    newOptions[option] = paramValue;
                } else if(option == 'useGordianData') {
                    const paramValue = urlParameters.get(option) === 'true';
                    newOptions[option] = paramValue;
                } else if(option == 'zipCode' || option == 'laborType' || option == 'fastenerPattern'){
                    newOptions[option] = urlParameters.get(option);
                }
            }

            optionsSet((options) =>  (options = newOptions));
            calculate();
        }

        // modal
        if (!gatedContent) {
            gatedContent = document.querySelector('.GatedContent');
        }

        const createHubspotForm = () => {
            const hubspotId = hubspotFormIdRef.current;
            const formEl = document.querySelector(`[data-hubspot-form-id="${ hubspotId }"]`) as HTMLElement;
            const portalId = (formEl) ? formEl.getAttribute('data-hubspot-portal-id') : '';
            const formId = (formEl) ? formEl.getAttribute('id') : '';
            const p = (formEl) ? formEl.querySelector("p.success-message") : null;
            if(p) formEl.removeChild(p);

            if(typeof window.hbspt !== "undefined" && portalId && hubspotId && formId) {
                window.hbspt.forms.create({
                    portalId: portalId,
                    formId: hubspotId,
                    target: `#${formId}`
                });
            }
        };

        const closeModalListener = () => {
            gatedContent.classList.add('hidden');
            createHubspotForm();
        }

        // message listener
        const messageListener = (event:any) => {
            if(
                event.data.type == 'hsFormCallback' &&
                event.data.eventName == 'onFormSubmit'
            ) {
                const userData = event.data.data;
                if(userData && emailUrlRef.current && pdfUrlRef.current) {
                    userData.forEach(element => {
                        if(element.name == 'email') {
                            const userEmail = element.value;
                            postData('/actions/appmodule/calculator/pdf-request', {
                                email: userEmail,
                                url: pdfUrlRef.current,
                                shareUrl: emailUrlRef.current
                            })
                            .then((data) => {
                                console.log(data);
                            })
                        }
                    });
                }
            }

            if (
                event.data.type === 'hsFormCallback' &&
                event.data.eventName === 'onFormSubmitted'
            ) {
                hubspotFormIdSet(event.data.id);
                const hubspotId = event.data.id;
                const formEl = document.querySelector(`[data-hubspot-form-id="${ hubspotId }"]`) as HTMLElement;
                const p = document.createElement("p");
                p.classList.add('success-message');
                p.innerHTML = "Thank you! We sent a pdf file with the results!";
                formEl.appendChild(p);
            }
        }
        window.addEventListener('message', messageListener);

        if (modalExit) {
            modalExit.addEventListener('click', closeModalListener);
        }

        return () => {
            window.removeEventListener('scroll', messageListener);
            if(modalExit) modalExit.removeEventListener('click', closeModalListener);
        }
    }, []);

    useEffect(() => {
        showResultsSet(false);
    }, [options]);

    useEffect(() => {
        updateEmailUrl();
        updatePdfUrl();
    }, [options, tableData, max12Result, max16Result]);

    useEffect(() => {
        if(typeof max12Result !== 'undefined' && typeof max16Result !== undefined) {
            // let result = (options.fastenerPattern == 'max-12') ? max12Result : max16Result;
            let gridMarxIndex = 0;
            let industryIndex = 2;
            let gridMarxFastenerIndex = 0;
            let industryFastenerIndex = 1;

            if(orientation == 'horizontal' && options.fastenerPattern == 'max-12') {
                gridMarxIndex = 1;
                industryIndex = 3;
                gridMarxFastenerIndex = 0;
                industryFastenerIndex = 2;
            } else if(orientation == 'vertical' && options.fastenerPattern == 'max-16') {
                gridMarxIndex = 0;
                industryIndex = 2;
                gridMarxFastenerIndex = 0;
                industryFastenerIndex = 1;
            } else if(orientation == 'horizontal' && options.fastenerPattern == 'max-16') {
                gridMarxIndex = 1;
                industryIndex = 3;
                gridMarxFastenerIndex = 0;
                industryFastenerIndex = 2;
            }

            let dataResult = buildTableData(gridMarxIndex, industryIndex, gridMarxFastenerIndex, industryFastenerIndex);
            if(dataResult.dataResult) tableDataSet(dataResult.dataResult);
            if(dataResult.overalMaterialAndLaborSavingData) overalSavingsSet(dataResult.overalMaterialAndLaborSavingData);
        }
    }, [max12Result, max16Result, orientation]);

    const buildTableData = (gridMarxIndex: number, industryIndex: number, gridMarxFastenerIndex:number, industryFastenerIndex:number) => {
        let result = (options.fastenerPattern == 'max-12') ? max12Result : max16Result;

        const gridMarxTotal = result.boardResults[gridMarxIndex].extLaborCost_NGC
        + result.fastenerPatternResults[gridMarxFastenerIndex].extFastenerCost
        + result.fastenerPatternResults[gridMarxFastenerIndex].spotFastenersExtLaborCost
        + result.fastenerPatternResults[gridMarxFastenerIndex].spotFastenerExtMaterialCost;

        const gridMarxData = {
            laborCostSf: result.boardResults[gridMarxIndex].laborCostSF_NGC,
            laborCost: result.boardResults[gridMarxIndex].extLaborCost_NGC,
            totalAverageScrewSF: result.fastenerPatternResults[gridMarxFastenerIndex].average,
            screwCost: result.fastenerPatternResults[gridMarxFastenerIndex].extFastenerCost,
            spotFastenerLaborCostSF: result.fastenerPatternResults[gridMarxFastenerIndex].spotFastenersLaborCostSF,
            spotFastenerLaborCost: result.fastenerPatternResults[gridMarxFastenerIndex].spotFastenersExtLaborCost,
            spotFastenerMaterialCostSF: result.fastenerPatternResults[gridMarxFastenerIndex].spotFastenerMaterialCostSF,
            spotFastenerMaterialCost: result.fastenerPatternResults[gridMarxFastenerIndex].spotFastenerExtMaterialCost,
            total: gridMarxTotal
        }

        const industryTotal = result.boardResults[industryIndex].extLaborCost
        + result.fastenerPatternResults[industryFastenerIndex].extFastenerCost
        + result.fastenerPatternResults[industryFastenerIndex].spotFastenersExtLaborCost
        + result.fastenerPatternResults[industryFastenerIndex].spotFastenerExtMaterialCost;

        const industryData = {
            laborCostSf: result.boardResults[industryIndex].laborCostSF,
            laborCost: result.boardResults[industryIndex].extLaborCost,
            totalAverageScrewSF: result.fastenerPatternResults[industryFastenerIndex].average,
            screwCost: result.fastenerPatternResults[industryFastenerIndex].extFastenerCost,
            spotFastenerLaborCostSF: result.fastenerPatternResults[industryFastenerIndex].spotFastenersLaborCostSF,
            spotFastenerLaborCost: result.fastenerPatternResults[industryFastenerIndex].spotFastenersExtLaborCost,
            spotFastenerMaterialCostSF: result.fastenerPatternResults[industryFastenerIndex].spotFastenerMaterialCostSF,
            spotFastenerMaterialCost: result.fastenerPatternResults[industryFastenerIndex].spotFastenerExtMaterialCost,
            total: industryTotal
        }

        const extraLaborSavings = result.boardResults[industryIndex].extLaborCost - result.boardResults[gridMarxIndex].extLaborCost_NGC;
        const spotFastenerSavings = result.fastenerPatternResults[industryFastenerIndex].spotFastenersExtLaborCost - result.fastenerPatternResults[gridMarxFastenerIndex].spotFastenersExtLaborCost;
        const totalSavings = extraLaborSavings + spotFastenerSavings;

        const overalSavingData = {
            laborCostPercentage: (extraLaborSavings / result.boardResults[industryIndex].extLaborCost) * 100,
            laborCost: extraLaborSavings,
            spotFastenerPercentage: (spotFastenerSavings / result.fastenerPatternResults[industryFastenerIndex].spotFastenersExtLaborCost) * 100,
            spotFastener: spotFastenerSavings,
            total: totalSavings,
            totalPercentage: (totalSavings / industryTotal) * 100
        }

        const screwCostPercentage = (1 - (gridMarxData.totalAverageScrewSF / industryData.totalAverageScrewSF)) * 100;
        const screwCost = industryData.screwCost - gridMarxData.screwCost;
        const fastenerCostPercentage = (1 - (gridMarxData.spotFastenerMaterialCostSF / industryData.spotFastenerMaterialCostSF)) * 100;
        const fastenerCost = industryData.spotFastenerMaterialCost - gridMarxData.spotFastenerMaterialCost;
        const totalMaterialSavings = screwCost + fastenerCost;

        const overalMaterialSavingData = {
            screwCostPercentage: screwCostPercentage,
            screwCost: screwCost,
            fastenerCostPercentage: fastenerCostPercentage,
            fastenerCost: fastenerCost,
            total: totalMaterialSavings,
            totalPercentage: (totalMaterialSavings / industryTotal) * 100
        }

        const materialLaborSavings = overalSavingData.total + overalMaterialSavingData.total;
        const materialLaborSavingsPercentage = overalSavingData.totalPercentage + overalMaterialSavingData.totalPercentage;

        const overalMaterialAndLaborSavingData = {
            total: materialLaborSavings,
            totalPercentage: materialLaborSavingsPercentage
        }

        const dataResult = {
            gridMarx: gridMarxData,
            industry: industryData,
            overalSaving: overalSavingData,
            overalMaterialSaving: overalMaterialSavingData,
            overalMaterialAndLaborSaving: overalMaterialAndLaborSavingData
        }

        return({dataResult, overalMaterialAndLaborSavingData})
    }

    const isValidZipCode = (zipCode: string) => {
        let isValid = false;

        if(zipCode) {
            const zipDigits = zipCode.substring(0, 3);
            cciLaborData.forEach((item) => {
                if(item.Zip == zipDigits)
                    isValid = true;
            });
        }

        validZipSet(isValid);

        return isValid;
    };

    const updateEmailUrl = () => {
        let newUrl = '';
        for (const option in options) {
            newUrl += (newUrl ? '&' : '?') + `${option}=${options[option as keyof typeof options]}`;
        }
        emailUrlSet(baseUrl + newUrl);
    }

    const updatePdfUrl = () => {
        let newUrl = location.origin + '/goldbond-max-12-calculator';
        if(max12Result && max16Result) {
            const fullData = {
                "horizontal": buildTableData(1, 3, 0, 2),
                "vertical": buildTableData(0, 2, 0, 1)
            };
            const encodedData = encodeURIComponent(JSON.stringify(fullData));
            const optionsEncoded = encodeURIComponent(JSON.stringify(options));
            newUrl = `${newUrl}?tableData=${encodedData}&options=${optionsEncoded}`;
        }
        pdfUrlSet(newUrl);
    }

    const calculate = () => {
        showResultsSet((showResults) => showResults = false);

        if((options.useGordianData && isValidZipCode(options.zipCode)) || !options.useGordianData) {
            buttonTextSet("Recalculate");
            let laborType = options.laborType;

            if(!options.useGordianData)
                laborType = 'custom';

            const calc = new calculatorFormulas(laborType, options.fastenerPattern, options.squareFootage, options.screwCostPerFastener, options.laborCostPerHour, options.zipCode);
            max12ResultSet(calc.getMax12Results());
            max16ResultSet(calc.getMax16Results());

            setTimeout(() => {
                showResultsSet(true);
                const resultEl = document.getElementById('calculator-result');
                if(resultEl) {
                    resultEl.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100);
        }
    }

    const handleFormSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        calculate();
    }

    const handleChange = (event:any) => {
        optionsSet({...options, [event.target.name]: event.target.value});

        if(event.target.name == 'fastenerPattern')
            logoSet( event.target.value == 'max-12' ? logoMax12 : logoMax16 );

    }

    const handleGordianChange = (event:any) => {
        optionsSet({...options, [event.target.name]: !options.useGordianData, zipCode: (options.useGordianData ? '' : options.zipCode)});
    }

    const toggleOrientation = (ev:any) => {
        const value = ev.target.value;
        orientationSet(value);

        if(value == 'vertical') {
            gridMarxTitleSet(gridMarxVerticalHeading);
            industryTitleSet(industryVerticalHeading);
        } else {
            gridMarxTitleSet(gridMarxHorizontalHeading);
            industryTitleSet(industryHorizontalHeading);
        }
    }

    const handleEmailClick = (ev:any) => {
        ev.preventDefault();
        const gatedContent = document.querySelector('.CalculatorModal');
        if(gatedContent) {
            gatedContent.classList.remove('hidden');
        }
    };

    const formatNumber = (value:number, decimals: number) => {
        return parseFloat(value.toFixed(decimals)).toLocaleString('en-US');
    }

    return (
        <div className="calculator__wrapper bg-white">
            <div className="container mx-auto">
                {/* <!-- Header --> */}
                <form className="calculator__header mb-6 pt-9 pb-10 px-5 lg:mb-10 lg:flex lg:flex-wrap lg:items-center lg:px-11 lg:py-6 lg:justify-between | print:hidden" onSubmit={handleFormSubmit}>
                    <div className={`calculator__fields grid grid-cols-2 gap-x-5 lg:order-1 lg:flex lg:gap-x-12 ${ options.useGordianData ? 'lg:items-start calculator__fields--wider' : '' }`}>
                        { options.useGordianData ? (
                            <div className="calculator__fieldBlock">
                                <label className="calculator__fieldLabel">Zip Code:</label>
                                <input id='zipCode' className={`calculator__input font-bold ${ !validZip ? 'border border-red-600' : ''  }`} type="text" name='zipCode' minLength={5} required value={options.zipCode} onChange={handleChange} />
                                { !validZip ? (<span className="calculator__helper">invalid zip code</span>) : null }
                            </div>
                        ) : (
                            <>
                                <div className="calculator__fieldBlock">
                                    <label className="calculator__fieldLabel">Labor Cost per Hour</label>
                                    <input className="calculator__input" type="number" name='laborCostPerHour' min={1} step={0.01} required value={options.laborCostPerHour} onChange={handleChange} />
                                </div>

                                <div className="calculator__fieldBlock">
                                    <label className="calculator__fieldLabel">Cost per Fastener</label>
                                    <input className="calculator__input" type="number" name='screwCostPerFastener' min={0.0001} step={0.0001} required value={options.screwCostPerFastener} onChange={handleChange} />
                                </div>
                            </>
                        ) }

                        <div className="calculator__fieldBlock">
                            <label className="calculator__fieldLabel">Square Footage</label>
                            <div className="calculator__inputWrapper">
                                <input className="calculator__input" type="number" name='squareFootage' min={1} required value={options.squareFootage} onChange={handleChange} />
                                <span className="calculator__inputInfo">ft</span>
                            </div>
                        </div>

                        <div className="calculator__fieldBlock">
                            <label className="calculator__fieldLabel">Labor Type</label>
                            <select className="calculator__select" name='laborType' value={options.laborType} onChange={handleChange} >
                                <option value="union">Union</option>
                                <option value="open-shop">Open Shop</option>
                            </select>
                        </div>

                        <div className="calculator__fieldBlock">
                            <label className="calculator__fieldLabel">fastener pattern</label>
                            <select className="calculator__select" name='fastenerPattern' value={options.fastenerPattern} onChange={handleChange} >
                                <option value="max-12">MaX 12</option>
                                <option value="max-16">MaX 16</option>
                            </select>
                        </div>
                    </div>

                    <label htmlFor="gordian" className="inline-block mb-4 lg:block lg:mb-0 lg:order-3 lg:flex-grow lg:mt-6">
                        <input type="checkbox" id="gordian" name="useGordianData" className="calculator__checkbox" checked={options.useGordianData} onChange={handleGordianChange} />
                        <span className="opacity-40 text-primary-contrast text-lg">{ options.useGordianData ? 'Using RSMeans data from Gordian. Click here to customize fastener and labor costs.' : 'Don’t have labor or fastener cost handy? Use RSMeans data from Gordian.' }</span>
                    </label>

                    <button className="calculator__button lg:order-2" type="submit">{ buttonText }</button>
                </form>

                {/* <!-- Result --> */}
                <div id="calculator-result">
                { (showResults && typeof tableData !== 'undefined' ) &&
                    <div className="calculator__result">
                        <div className="pb-7 lg:flex lg:justify-between lg:items-center | print:flex print:justify-between print:items-center">
                            <div className="text-center lg:flex lg:items-center | print:flex print:items-center">
                                <h5 className="font-bold text-xl mb-4 lg:mb-0 lg:mr-6 | print:mb-0 print:mr-6">
                                    <span className="lg:hidden print:hidden">Compare</span>
                                    <span className="hidden lg:inline-block print:inline-block">Orientation</span>
                                </h5>

                                <div className="calculator__switch bg-light text-center flex mx-auto p-1">
                                    <input type="radio" className="calculator__switch__radio" name="orientation" id="option-1" value="vertical" checked={orientation=='vertical'} onChange={toggleOrientation} />
                                    <label htmlFor="option-1" className="calculator__switch__label">Vertical</label>
                                    <input type="radio" className="calculator__switch__radio" name="orientation" id="option-2" value="horizontal" checked={orientation=='horizontal'} onChange={toggleOrientation} />
                                    <label htmlFor="option-2" className="calculator__switch__label">Horizontal</label>
                                </div>
                            </div>

                            <div className="hidden lg:flex | print:flex">
                                <div><strong>Applicable Wall Square Footage:</strong> {options.squareFootage} ft</div>
                                <div className="ml-9"><strong>Labor Type:</strong> { options.laborType == "union" ? "Union" : "Open Shop" } </div>
                            </div>
                        </div>

                        <div className="calculator__result__content">
                            <div className={`calculator__result__heading ${ options.fastenerPattern == 'max-12' ? 'bg-primary-base' : 'bg-black text-white' } py-8 px-10 lg:flex lg:justify-between lg:px-14 | print:flex print:justify-between print:px-14`}>
                                <div className="text-center mb-7 lg:text-left lg:mb-0 | print:text-left print:mb-0">
                                    <img src={logo.url} alt={logo.alt} className="hidden calculator__result__logo lg:block lg:mb-3 | print:block print:mb-3" />
                                    <h6 className="text-lg font-bold"><span className="lg:sr-only | print:sr-only">{ options.fastenerPattern == 'max-12' ? 'MaX 12' : 'MaX 16' }</span> vs. Leading Competitor {orientation == 'vertical' ? '8/12' : '8/8'}</h6>
                                </div>

                                <div className="flex justify-between">
                                    <div className="calculator__result__saving">
                                        <p><strong>${ formatNumber(overalSavings.total, 2) }</strong></p>
                                        <p>Total Cost Savings</p>
                                    </div>

                                    <div className="calculator__result__saving">
                                        <p><strong>{ formatNumber(overalSavings.totalPercentage, 2) }%</strong></p>
                                        <p>Savings Percentage</p>
                                    </div>
                                </div>
                            </div>

                            <div className="calculator__result__table overflow-auto lg:overflow-visible | print:overflow-visible">
                                {/* @see: https://developer.mozilla.org/en-US/docs/Learn/HTML/Tables/Advanced#adding_structure_with_thead_tfoot_and_tbody */}
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="sr-only">&nbsp;</th>
                                            <th>&nbsp;</th>
                                            <th id="labor-cost-sf">labor cost/sf</th>
                                            <th id="labor-cost">labor cost</th>
                                            <th id="average-screws">Total Average Fasteners/SF</th>
                                            <th id="screw-cost">fastener cost</th>
                                            <th id="fastener-labor-cost-sf">Spot Fasteners Labor Cost/SF</th>
                                            <th id="fastener-labor-cost">Spot Fasteners Labor Cost</th>
                                            <th id="fastener-material-cost-sf">Spot fasteners Material Cost/SF</th>
                                            <th id="fastener-material-cost">Spot Fasteners Material Cost</th>
                                            <th id="total">Total $</th>
                                            <th id="total-percentage">Total (%)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th rowSpan={2} id="compare" className="calculator__result__floatingTitle sr-only lg:not-sr-only | print:not-sr-only"><span>Compare</span></th>
                                            <th id="gridmarx">{ gridMarxTitle } { options.fastenerPattern == 'max-12' ? '(12/12)' : '(16/16)' }</th>
                                            <td headers="labor-cost-sf gridmarx">${ formatNumber(tableData.gridMarx.laborCostSf, 3) }</td>
                                            <td headers="labor-cost gridmarx">${ formatNumber(tableData.gridMarx.laborCost, 2) }</td>
                                            <td headers="average-screws gridmarx">{ formatNumber(tableData.gridMarx.totalAverageScrewSF, 2) }</td>
                                            <td headers="screw-cost gridmarx">${ formatNumber(tableData.gridMarx.screwCost, 2) }</td>
                                            <td headers="fastener-labor-cost-sf gridmarx">${ formatNumber(tableData.gridMarx.spotFastenerLaborCostSF, 3) }</td>
                                            <td headers="fastener-labor-cost gridmarx">${ formatNumber(tableData.gridMarx.spotFastenerLaborCost, 2) }</td>
                                            <td headers="fastener-material-cost-sf gridmarx">${ formatNumber(tableData.gridMarx.spotFastenerMaterialCostSF, 5) }</td>
                                            <td headers="fastener-material-cost gridmarx">${ formatNumber(tableData.gridMarx.spotFastenerMaterialCost, 2) }</td>
                                            <td headers="total gridmarx">${ formatNumber(tableData.gridMarx.total, 2) }</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr className="text-dark bg-[#C0C0C0] lg:hidden | print:hidden">
                                            <th colSpan={12}>Compare to</th>
                                        </tr>
                                        <tr className="add-border">
                                            <th className="sr-only lg:hidden | print:hidden">&nbsp;</th>
                                            <th id="industry-standard">{ industryTitle }</th>
                                            <td headers="labor-cost-sf industry-standard">${ formatNumber(tableData.industry.laborCostSf, 3) }</td>
                                            <td headers="labor-cost industry-standard">${ formatNumber(tableData.industry.laborCost, 2) }</td>
                                            <td headers="average-screws industry-standard">{ formatNumber(tableData.industry.totalAverageScrewSF, 2) }</td>
                                            <td headers="screw-cost industry-standard">${ formatNumber(tableData.industry.screwCost, 2) }</td>
                                            <td headers="fastener-labor-cost-sf industry-standard">${ formatNumber(tableData.industry.spotFastenerLaborCostSF, 3) }</td>
                                            <td headers="fastener-labor-cost industry-standard">${ formatNumber(tableData.industry.spotFastenerLaborCost, 2) }</td>
                                            <td headers="fastener-material-cost-sf industry-standard">${ formatNumber(tableData.industry.spotFastenerMaterialCostSF, 5) }</td>
                                            <td headers="fastener-material-cost industry-standard">${ formatNumber(tableData.industry.spotFastenerMaterialCost, 2) }</td>
                                            <td headers="total industry-standard">${ formatNumber(tableData.industry.total, 2) }</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <th rowSpan={2} id="savings" className="calculator__result__floatingTitle sr-only lg:not-sr-only | print:not-sr-only"><span>Savings</span></th>
                                            <th id="labor-savings">Overall Labor Savings</th>
                                            <td headers="labor-cost-sf labor-savings">{ formatNumber(tableData.overalSaving.laborCostPercentage, 2) }%</td>
                                            <td headers="labor-cost labor-savings">${ formatNumber(tableData.overalSaving.laborCost, 2) }</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td headers="fastener-labor-cost-sf labor-savings">{ formatNumber(tableData.overalSaving.spotFastenerPercentage, 2) }%</td>
                                            <td headers="fastener-labor-cost labor-savings">${ formatNumber(tableData.overalSaving.spotFastener, 2) }</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td headers="total labor-savings">${ formatNumber(tableData.overalSaving.total, 2) }</td>
                                            <td headers="total-percentage labor-savings">{ formatNumber(tableData.overalSaving.totalPercentage, 2) }%</td>
                                        </tr>
                                        <tr>
                                            <th className="sr-only lg:hidden | print:hidden">&nbsp;</th>
                                            <th id="material-savings">Overall Material Savings</th>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td headers="average-screws material-savings">{ formatNumber(tableData.overalMaterialSaving.screwCostPercentage, 2) }%</td>
                                            <td headers="screw-cost material-savings">${ formatNumber(tableData.overalMaterialSaving.screwCost, 2) }</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td headers="fastener-material-cost-sf material-savings">{ formatNumber(tableData.overalMaterialSaving.fastenerCostPercentage, 2) }%</td>
                                            <td headers="fastener-material-cost material-savings">${ formatNumber(tableData.overalMaterialSaving.fastenerCost, 2) }</td>
                                            <td headers="total material-savings">${ formatNumber(tableData.overalMaterialSaving.total, 2) }</td>
                                            <td headers="total-percentage material-savings">{ formatNumber(tableData.overalMaterialSaving.totalPercentage, 2) }%</td>
                                        </tr>
                                    </tbody>

                                    <tfoot>
                                        <tr>
                                            <td className="sr-only">&nbsp;</td>
                                            <th id="overall-saving">Overall Material &amp; Labor Savings</th>
                                            <td colSpan={8}>&nbsp;</td>
                                            <td headers="total overall-saving">${ formatNumber(tableData.overalMaterialAndLaborSaving.total, 2) }</td>
                                            <td headers="total-percentage overall-saving">{ formatNumber(tableData.overalMaterialAndLaborSaving.totalPercentage, 2) }%</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>

                            <div className="calculator__result__disclaimer mt-5 px-5 lg:px-0">
                                <p className="text-sm text-dark">{ props.disclaimer }</p>
                                <img src="/dist/img/logos/gordian-logo.png" alt="gordian logo" />
                            </div>
                        </div>

                        <div className="calculator__result__actions mt-12 mb-12 flex items-start px-5 flex-col lg:flex-row lg:justify-center lg:px-0 | print:hidden">
                            <button type='button' className="mb-4 lg:mr-18 lg:mb-0" onClick={() => window.print()}>
                                <img src="/dist/img/icons/print-icon.svg" alt="print icon" />
                                <span>Print Calculations (PDF)</span>
                            </button>
                            <button type='button' onClick={handleEmailClick}>
                                <img src="/dist/img/icons/mail-icon.svg" alt="mail icon" />
                                <span>Email Calculations</span>
                            </button>
                        </div>
                    </div>
                }
                </div>
            </div>
        </div>
    );
}

export default function onInit(disclaimer = '') {
    ReactDOM.render(
        <Calculator disclaimer={disclaimer} />,
        document.getElementById('CalculatorModule')
    );
}