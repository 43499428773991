const cciLabor = [
    {
        "Year": "2023Q1",
        "Zip": "009",
        "State": "PR",
        "City": "SAN JUAN",
        "Div": 999,
        "Mat": 1.256,
        "Labor": 0.329,
        "Total": 0.91,
        "Location": "SAN JUAN, PR"
    },
    {
        "Year": "2023Q1",
        "Zip": "010",
        "State": "MA",
        "City": "SPRINGFIELD",
        "Div": 999,
        "Mat": 1.025,
        "Labor": 1.06,
        "Total": 1.038,
        "Location": "SPRINGFIELD, MA"
    },
    {
        "Year": "2023Q1",
        "Zip": "011",
        "State": "MA",
        "City": "SPRINGFIELD",
        "Div": 999,
        "Mat": 1.025,
        "Labor": 1.06,
        "Total": 1.038,
        "Location": "SPRINGFIELD, MA"
    },
    {
        "Year": "2023Q1",
        "Zip": "012",
        "State": "MA",
        "City": "PITTSFIELD",
        "Div": 999,
        "Mat": 1.014,
        "Labor": 1.003,
        "Total": 1.01,
        "Location": "PITTSFIELD, MA"
    },
    {
        "Year": "2023Q1",
        "Zip": "013",
        "State": "MA",
        "City": "GREENFIELD",
        "Div": 999,
        "Mat": 0.994,
        "Labor": 1.048,
        "Total": 1.014,
        "Location": "GREENFIELD, MA"
    },
    {
        "Year": "2023Q1",
        "Zip": "014",
        "State": "MA",
        "City": "FITCHBURG",
        "Div": 999,
        "Mat": 0.977,
        "Labor": 1.076,
        "Total": 1.014,
        "Location": "FITCHBURG, MA"
    },
    {
        "Year": "2023Q1",
        "Zip": "015",
        "State": "MA",
        "City": "WORCESTER",
        "Div": 999,
        "Mat": 1.025,
        "Labor": 1.129,
        "Total": 1.064,
        "Location": "WORCESTER, MA"
    },
    {
        "Year": "2023Q1",
        "Zip": "016",
        "State": "MA",
        "City": "WORCESTER",
        "Div": 999,
        "Mat": 1.025,
        "Labor": 1.129,
        "Total": 1.064,
        "Location": "WORCESTER, MA"
    },
    {
        "Year": "2023Q1",
        "Zip": "017",
        "State": "MA",
        "City": "FRAMINGHAM",
        "Div": 999,
        "Mat": 0.973,
        "Labor": 1.165,
        "Total": 1.044,
        "Location": "FRAMINGHAM, MA"
    },
    {
        "Year": "2023Q1",
        "Zip": "018",
        "State": "MA",
        "City": "LOWELL",
        "Div": 999,
        "Mat": 1.016,
        "Labor": 1.208,
        "Total": 1.088,
        "Location": "LOWELL, MA"
    },
    {
        "Year": "2023Q1",
        "Zip": "019",
        "State": "MA",
        "City": "LAWRENCE",
        "Div": 999,
        "Mat": 1.025,
        "Labor": 1.19,
        "Total": 1.086,
        "Location": "LAWRENCE, MA"
    },
    {
        "Year": "2023Q1",
        "Zip": "020",
        "State": "MA",
        "City": "BOSTON",
        "Div": 999,
        "Mat": 1.044,
        "Labor": 1.362,
        "Total": 1.162,
        "Location": "BOSTON, MA"
    },
    {
        "Year": "2023Q1",
        "Zip": "021",
        "State": "MA",
        "City": "BOSTON",
        "Div": 999,
        "Mat": 1.044,
        "Labor": 1.362,
        "Total": 1.162,
        "Location": "BOSTON, MA"
    },
    {
        "Year": "2023Q1",
        "Zip": "022",
        "State": "MA",
        "City": "BOSTON",
        "Div": 999,
        "Mat": 1.044,
        "Labor": 1.362,
        "Total": 1.162,
        "Location": "BOSTON, MA"
    },
    {
        "Year": "2023Q1",
        "Zip": "023",
        "State": "MA",
        "City": "BROCKTON",
        "Div": 999,
        "Mat": 1.021,
        "Labor": 1.117,
        "Total": 1.057,
        "Location": "BROCKTON, MA"
    },
    {
        "Year": "2023Q1",
        "Zip": "024",
        "State": "MA",
        "City": "BOSTON",
        "Div": 999,
        "Mat": 1.044,
        "Labor": 1.362,
        "Total": 1.162,
        "Location": "BOSTON, MA"
    },
    {
        "Year": "2023Q1",
        "Zip": "025",
        "State": "MA",
        "City": "BUZZARDS BAY",
        "Div": 999,
        "Mat": 0.958,
        "Labor": 1.091,
        "Total": 1.008,
        "Location": "BUZZARDS BAY, MA"
    },
    {
        "Year": "2023Q1",
        "Zip": "026",
        "State": "MA",
        "City": "HYANNIS",
        "Div": 999,
        "Mat": 0.988,
        "Labor": 1.118,
        "Total": 1.037,
        "Location": "HYANNIS, MA"
    },
    {
        "Year": "2023Q1",
        "Zip": "027",
        "State": "MA",
        "City": "NEW BEDFORD",
        "Div": 999,
        "Mat": 1.015,
        "Labor": 1.106,
        "Total": 1.049,
        "Location": "NEW BEDFORD, MA"
    },
    {
        "Year": "2023Q1",
        "Zip": "028",
        "State": "RI",
        "City": "NEWPORT",
        "Div": 999,
        "Mat": 1.01,
        "Labor": 1.131,
        "Total": 1.055,
        "Location": "NEWPORT, RI"
    },
    {
        "Year": "2023Q1",
        "Zip": "029",
        "State": "RI",
        "City": "PROVIDENCE",
        "Div": 999,
        "Mat": 1.058,
        "Labor": 1.145,
        "Total": 1.091,
        "Location": "PROVIDENCE, RI"
    },
    {
        "Year": "2023Q1",
        "Zip": "030",
        "State": "NH",
        "City": "NASHUA",
        "Div": 999,
        "Mat": 1.028,
        "Labor": 0.927,
        "Total": 0.991,
        "Location": "NASHUA, NH"
    },
    {
        "Year": "2023Q1",
        "Zip": "031",
        "State": "NH",
        "City": "MANCHESTER",
        "Div": 999,
        "Mat": 1.038,
        "Labor": 0.942,
        "Total": 1.002,
        "Location": "MANCHESTER, NH"
    },
    {
        "Year": "2023Q1",
        "Zip": "032",
        "State": "NH",
        "City": "CONCORD",
        "Div": 999,
        "Mat": 1.037,
        "Labor": 0.932,
        "Total": 0.998,
        "Location": "CONCORD, NH"
    },
    {
        "Year": "2023Q1",
        "Zip": "033",
        "State": "NH",
        "City": "CONCORD",
        "Div": 999,
        "Mat": 1.037,
        "Labor": 0.932,
        "Total": 0.998,
        "Location": "CONCORD, NH"
    },
    {
        "Year": "2023Q1",
        "Zip": "034",
        "State": "NH",
        "City": "KEENE",
        "Div": 999,
        "Mat": 0.981,
        "Labor": 0.829,
        "Total": 0.924,
        "Location": "KEENE, NH"
    },
    {
        "Year": "2023Q1",
        "Zip": "035",
        "State": "NH",
        "City": "LITTLETON",
        "Div": 999,
        "Mat": 0.985,
        "Labor": 0.748,
        "Total": 0.897,
        "Location": "LITTLETON, NH"
    },
    {
        "Year": "2023Q1",
        "Zip": "036",
        "State": "NH",
        "City": "CHARLESTON",
        "Div": 999,
        "Mat": 0.978,
        "Labor": 0.823,
        "Total": 0.92,
        "Location": "CHARLESTON, NH"
    },
    {
        "Year": "2023Q1",
        "Zip": "037",
        "State": "NH",
        "City": "CLAREMONT",
        "Div": 999,
        "Mat": 0.972,
        "Labor": 0.823,
        "Total": 0.916,
        "Location": "CLAREMONT, NH"
    },
    {
        "Year": "2023Q1",
        "Zip": "038",
        "State": "NH",
        "City": "PORTSMOUTH",
        "Div": 999,
        "Mat": 0.994,
        "Labor": 0.905,
        "Total": 0.961,
        "Location": "PORTSMOUTH, NH"
    },
    {
        "Year": "2023Q1",
        "Zip": "039",
        "State": "ME",
        "City": "KITTERY",
        "Div": 999,
        "Mat": 0.97,
        "Labor": 0.829,
        "Total": 0.918,
        "Location": "KITTERY, ME"
    },
    {
        "Year": "2023Q1",
        "Zip": "040",
        "State": "ME",
        "City": "PORTLAND",
        "Div": 999,
        "Mat": 1.035,
        "Labor": 0.882,
        "Total": 0.978,
        "Location": "PORTLAND, ME"
    },
    {
        "Year": "2023Q1",
        "Zip": "041",
        "State": "ME",
        "City": "PORTLAND",
        "Div": 999,
        "Mat": 1.035,
        "Labor": 0.882,
        "Total": 0.978,
        "Location": "PORTLAND, ME"
    },
    {
        "Year": "2023Q1",
        "Zip": "042",
        "State": "ME",
        "City": "LEWISTON",
        "Div": 999,
        "Mat": 1.013,
        "Labor": 0.854,
        "Total": 0.953,
        "Location": "LEWISTON, ME"
    },
    {
        "Year": "2023Q1",
        "Zip": "043",
        "State": "ME",
        "City": "AUGUSTA",
        "Div": 999,
        "Mat": 1.048,
        "Labor": 0.845,
        "Total": 0.973,
        "Location": "AUGUSTA, ME"
    },
    {
        "Year": "2023Q1",
        "Zip": "044",
        "State": "ME",
        "City": "BANGOR",
        "Div": 999,
        "Mat": 1,
        "Labor": 0.841,
        "Total": 0.941,
        "Location": "BANGOR, ME"
    },
    {
        "Year": "2023Q1",
        "Zip": "045",
        "State": "ME",
        "City": "BATH",
        "Div": 999,
        "Mat": 0.982,
        "Labor": 0.827,
        "Total": 0.924,
        "Location": "BATH, ME"
    },
    {
        "Year": "2023Q1",
        "Zip": "046",
        "State": "ME",
        "City": "MACHIAS",
        "Div": 999,
        "Mat": 0.976,
        "Labor": 0.826,
        "Total": 0.92,
        "Location": "MACHIAS, ME"
    },
    {
        "Year": "2023Q1",
        "Zip": "047",
        "State": "ME",
        "City": "HOULTON",
        "Div": 999,
        "Mat": 0.977,
        "Labor": 0.825,
        "Total": 0.921,
        "Location": "HOULTON, ME"
    },
    {
        "Year": "2023Q1",
        "Zip": "048",
        "State": "ME",
        "City": "ROCKLAND",
        "Div": 999,
        "Mat": 0.969,
        "Labor": 0.827,
        "Total": 0.916,
        "Location": "ROCKLAND, ME"
    },
    {
        "Year": "2023Q1",
        "Zip": "049",
        "State": "ME",
        "City": "WATERVILLE",
        "Div": 999,
        "Mat": 0.98,
        "Labor": 0.826,
        "Total": 0.923,
        "Location": "WATERVILLE, ME"
    },
    {
        "Year": "2023Q1",
        "Zip": "050",
        "State": "VT",
        "City": "WHITE RIVER JCT.",
        "Div": 999,
        "Mat": 1.009,
        "Labor": 0.775,
        "Total": 0.922,
        "Location": "WHITE RIVER JCT., VT"
    },
    {
        "Year": "2023Q1",
        "Zip": "051",
        "State": "VT",
        "City": "BELLOWS FALLS",
        "Div": 999,
        "Mat": 0.992,
        "Labor": 0.876,
        "Total": 0.949,
        "Location": "BELLOWS FALLS, VT"
    },
    {
        "Year": "2023Q1",
        "Zip": "052",
        "State": "VT",
        "City": "BENNINGTON",
        "Div": 999,
        "Mat": 0.997,
        "Labor": 0.844,
        "Total": 0.94,
        "Location": "BENNINGTON, VT"
    },
    {
        "Year": "2023Q1",
        "Zip": "053",
        "State": "VT",
        "City": "BRATTLEBORO",
        "Div": 999,
        "Mat": 1,
        "Labor": 0.876,
        "Total": 0.954,
        "Location": "BRATTLEBORO, VT"
    },
    {
        "Year": "2023Q1",
        "Zip": "054",
        "State": "VT",
        "City": "BURLINGTON",
        "Div": 999,
        "Mat": 1.065,
        "Labor": 0.82,
        "Total": 0.974,
        "Location": "BURLINGTON, VT"
    },
    {
        "Year": "2023Q1",
        "Zip": "056",
        "State": "VT",
        "City": "MONTPELIER",
        "Div": 999,
        "Mat": 1.04,
        "Labor": 0.849,
        "Total": 0.969,
        "Location": "MONTPELIER, VT"
    },
    {
        "Year": "2023Q1",
        "Zip": "057",
        "State": "VT",
        "City": "RUTLAND",
        "Div": 999,
        "Mat": 1.029,
        "Labor": 0.815,
        "Total": 0.949,
        "Location": "RUTLAND, VT"
    },
    {
        "Year": "2023Q1",
        "Zip": "058",
        "State": "VT",
        "City": "ST. JOHNSBURY",
        "Div": 999,
        "Mat": 1.011,
        "Labor": 0.771,
        "Total": 0.922,
        "Location": "ST. JOHNSBURY, VT"
    },
    {
        "Year": "2023Q1",
        "Zip": "059",
        "State": "VT",
        "City": "GUILDHALL",
        "Div": 999,
        "Mat": 0.997,
        "Labor": 0.771,
        "Total": 0.913,
        "Location": "GUILDHALL, VT"
    },
    {
        "Year": "2023Q1",
        "Zip": "060",
        "State": "CT",
        "City": "NEW BRITAIN",
        "Div": 999,
        "Mat": 1.034,
        "Labor": 1.177,
        "Total": 1.087,
        "Location": "NEW BRITAIN, CT"
    },
    {
        "Year": "2023Q1",
        "Zip": "061",
        "State": "CT",
        "City": "HARTFORD",
        "Div": 999,
        "Mat": 1.061,
        "Labor": 1.181,
        "Total": 1.106,
        "Location": "HARTFORD, CT"
    },
    {
        "Year": "2023Q1",
        "Zip": "062",
        "State": "CT",
        "City": "WILLIMANTIC",
        "Div": 999,
        "Mat": 1.046,
        "Labor": 1.157,
        "Total": 1.087,
        "Location": "WILLIMANTIC, CT"
    },
    {
        "Year": "2023Q1",
        "Zip": "063",
        "State": "CT",
        "City": "NEW LONDON",
        "Div": 999,
        "Mat": 0.999,
        "Labor": 1.156,
        "Total": 1.057,
        "Location": "NEW LONDON, CT"
    },
    {
        "Year": "2023Q1",
        "Zip": "064",
        "State": "CT",
        "City": "MERIDEN",
        "Div": 999,
        "Mat": 1.022,
        "Labor": 1.156,
        "Total": 1.072,
        "Location": "MERIDEN, CT"
    },
    {
        "Year": "2023Q1",
        "Zip": "065",
        "State": "CT",
        "City": "NEW HAVEN",
        "Div": 999,
        "Mat": 1.046,
        "Labor": 1.167,
        "Total": 1.091,
        "Location": "NEW HAVEN, CT"
    },
    {
        "Year": "2023Q1",
        "Zip": "066",
        "State": "CT",
        "City": "BRIDGEPORT",
        "Div": 999,
        "Mat": 1.049,
        "Labor": 1.166,
        "Total": 1.093,
        "Location": "BRIDGEPORT, CT"
    },
    {
        "Year": "2023Q1",
        "Zip": "067",
        "State": "CT",
        "City": "WATERBURY",
        "Div": 999,
        "Mat": 1.045,
        "Labor": 1.168,
        "Total": 1.091,
        "Location": "WATERBURY, CT"
    },
    {
        "Year": "2023Q1",
        "Zip": "068",
        "State": "CT",
        "City": "NORWALK",
        "Div": 999,
        "Mat": 1.044,
        "Labor": 1.166,
        "Total": 1.089,
        "Location": "NORWALK, CT"
    },
    {
        "Year": "2023Q1",
        "Zip": "069",
        "State": "CT",
        "City": "STAMFORD",
        "Div": 999,
        "Mat": 1.045,
        "Labor": 1.17,
        "Total": 1.092,
        "Location": "STAMFORD, CT"
    },
    {
        "Year": "2023Q1",
        "Zip": "070",
        "State": "NJ",
        "City": "NEWARK",
        "Div": 999,
        "Mat": 1.037,
        "Labor": 1.383,
        "Total": 1.166,
        "Location": "NEWARK, NJ"
    },
    {
        "Year": "2023Q1",
        "Zip": "071",
        "State": "NJ",
        "City": "NEWARK",
        "Div": 999,
        "Mat": 1.037,
        "Labor": 1.383,
        "Total": 1.166,
        "Location": "NEWARK, NJ"
    },
    {
        "Year": "2023Q1",
        "Zip": "072",
        "State": "NJ",
        "City": "ELIZABETH",
        "Div": 999,
        "Mat": 0.994,
        "Labor": 1.394,
        "Total": 1.143,
        "Location": "ELIZABETH, NJ"
    },
    {
        "Year": "2023Q1",
        "Zip": "073",
        "State": "NJ",
        "City": "JERSEY CITY",
        "Div": 999,
        "Mat": 0.996,
        "Labor": 1.375,
        "Total": 1.137,
        "Location": "JERSEY CITY, NJ"
    },
    {
        "Year": "2023Q1",
        "Zip": "074",
        "State": "NJ",
        "City": "PATERSON",
        "Div": 999,
        "Mat": 1.007,
        "Labor": 1.396,
        "Total": 1.152,
        "Location": "PATERSON, NJ"
    },
    {
        "Year": "2023Q1",
        "Zip": "075",
        "State": "NJ",
        "City": "PATERSON",
        "Div": 999,
        "Mat": 1.007,
        "Labor": 1.396,
        "Total": 1.152,
        "Location": "PATERSON, NJ"
    },
    {
        "Year": "2023Q1",
        "Zip": "076",
        "State": "NJ",
        "City": "HACKENSACK",
        "Div": 999,
        "Mat": 0.982,
        "Labor": 1.374,
        "Total": 1.129,
        "Location": "HACKENSACK, NJ"
    },
    {
        "Year": "2023Q1",
        "Zip": "077",
        "State": "NJ",
        "City": "LONG BRANCH",
        "Div": 999,
        "Mat": 0.98,
        "Labor": 1.341,
        "Total": 1.115,
        "Location": "LONG BRANCH, NJ"
    },
    {
        "Year": "2023Q1",
        "Zip": "078",
        "State": "NJ",
        "City": "DOVER",
        "Div": 999,
        "Mat": 0.983,
        "Labor": 1.395,
        "Total": 1.137,
        "Location": "DOVER, NJ"
    },
    {
        "Year": "2023Q1",
        "Zip": "079",
        "State": "NJ",
        "City": "SUMMIT",
        "Div": 999,
        "Mat": 0.985,
        "Labor": 1.384,
        "Total": 1.134,
        "Location": "SUMMIT, NJ"
    },
    {
        "Year": "2023Q1",
        "Zip": "080",
        "State": "NJ",
        "City": "VINELAND",
        "Div": 999,
        "Mat": 0.992,
        "Labor": 1.339,
        "Total": 1.121,
        "Location": "VINELAND, NJ"
    },
    {
        "Year": "2023Q1",
        "Zip": "081",
        "State": "NJ",
        "City": "CAMDEN",
        "Div": 999,
        "Mat": 1.018,
        "Labor": 1.331,
        "Total": 1.135,
        "Location": "CAMDEN, NJ"
    },
    {
        "Year": "2023Q1",
        "Zip": "082",
        "State": "NJ",
        "City": "ATLANTIC CITY",
        "Div": 999,
        "Mat": 0.997,
        "Labor": 1.338,
        "Total": 1.124,
        "Location": "ATLANTIC CITY, NJ"
    },
    {
        "Year": "2023Q1",
        "Zip": "083",
        "State": "NJ",
        "City": "VINELAND",
        "Div": 999,
        "Mat": 0.992,
        "Labor": 1.339,
        "Total": 1.121,
        "Location": "VINELAND, NJ"
    },
    {
        "Year": "2023Q1",
        "Zip": "084",
        "State": "NJ",
        "City": "ATLANTIC CITY",
        "Div": 999,
        "Mat": 0.997,
        "Labor": 1.338,
        "Total": 1.124,
        "Location": "ATLANTIC CITY, NJ"
    },
    {
        "Year": "2023Q1",
        "Zip": "085",
        "State": "NJ",
        "City": "TRENTON",
        "Div": 999,
        "Mat": 1.038,
        "Labor": 1.33,
        "Total": 1.147,
        "Location": "TRENTON, NJ"
    },
    {
        "Year": "2023Q1",
        "Zip": "086",
        "State": "NJ",
        "City": "TRENTON",
        "Div": 999,
        "Mat": 1.038,
        "Labor": 1.33,
        "Total": 1.147,
        "Location": "TRENTON, NJ"
    },
    {
        "Year": "2023Q1",
        "Zip": "087",
        "State": "NJ",
        "City": "POINT PLEASANT",
        "Div": 999,
        "Mat": 1.007,
        "Labor": 1.274,
        "Total": 1.106,
        "Location": "POINT PLEASANT, NJ"
    },
    {
        "Year": "2023Q1",
        "Zip": "088",
        "State": "NJ",
        "City": "NEW BRUNSWICK",
        "Div": 999,
        "Mat": 1.012,
        "Labor": 1.36,
        "Total": 1.142,
        "Location": "NEW BRUNSWICK, NJ"
    },
    {
        "Year": "2023Q1",
        "Zip": "089",
        "State": "NJ",
        "City": "NEW BRUNSWICK",
        "Div": 999,
        "Mat": 1.012,
        "Labor": 1.36,
        "Total": 1.142,
        "Location": "NEW BRUNSWICK, NJ"
    },
    {
        "Year": "2023Q1",
        "Zip": 100,
        "State": "NY",
        "City": "NEW YORK",
        "Div": 999,
        "Mat": 1.031,
        "Labor": 1.72,
        "Total": 1.288,
        "Location": "NEW YORK, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 101,
        "State": "NY",
        "City": "NEW YORK",
        "Div": 999,
        "Mat": 1.031,
        "Labor": 1.72,
        "Total": 1.288,
        "Location": "NEW YORK, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 102,
        "State": "NY",
        "City": "NEW YORK",
        "Div": 999,
        "Mat": 1.031,
        "Labor": 1.72,
        "Total": 1.288,
        "Location": "NEW YORK, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 103,
        "State": "NY",
        "City": "STATEN ISLAND",
        "Div": 999,
        "Mat": 0.969,
        "Labor": 1.667,
        "Total": 1.229,
        "Location": "STATEN ISLAND, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 104,
        "State": "NY",
        "City": "BRONX",
        "Div": 999,
        "Mat": 0.955,
        "Labor": 1.685,
        "Total": 1.227,
        "Location": "BRONX, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 105,
        "State": "NY",
        "City": "MOUNT VERNON",
        "Div": 999,
        "Mat": 0.942,
        "Labor": 1.442,
        "Total": 1.128,
        "Location": "MOUNT VERNON, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 106,
        "State": "NY",
        "City": "WHITE PLAINS",
        "Div": 999,
        "Mat": 0.951,
        "Labor": 1.472,
        "Total": 1.145,
        "Location": "WHITE PLAINS, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 107,
        "State": "NY",
        "City": "YONKERS",
        "Div": 999,
        "Mat": 0.999,
        "Labor": 1.486,
        "Total": 1.18,
        "Location": "YONKERS, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 108,
        "State": "NY",
        "City": "NEW ROCHELLE",
        "Div": 999,
        "Mat": 0.946,
        "Labor": 1.389,
        "Total": 1.111,
        "Location": "NEW ROCHELLE, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 109,
        "State": "NY",
        "City": "SUFFERN",
        "Div": 999,
        "Mat": 0.942,
        "Labor": 1.185,
        "Total": 1.033,
        "Location": "SUFFERN, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 110,
        "State": "NY",
        "City": "QUEENS",
        "Div": 999,
        "Mat": 1.032,
        "Labor": 1.739,
        "Total": 1.295,
        "Location": "QUEENS, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 111,
        "State": "NY",
        "City": "LONG ISLAND CITY",
        "Div": 999,
        "Mat": 1.046,
        "Labor": 1.739,
        "Total": 1.304,
        "Location": "LONG ISLAND CITY, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 112,
        "State": "NY",
        "City": "BROOKLYN",
        "Div": 999,
        "Mat": 1.053,
        "Labor": 1.714,
        "Total": 1.299,
        "Location": "BROOKLYN, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 113,
        "State": "NY",
        "City": "FLUSHING",
        "Div": 999,
        "Mat": 1.037,
        "Labor": 1.739,
        "Total": 1.299,
        "Location": "FLUSHING, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 114,
        "State": "NY",
        "City": "JAMAICA",
        "Div": 999,
        "Mat": 1.025,
        "Labor": 1.739,
        "Total": 1.291,
        "Location": "JAMAICA, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 115,
        "State": "NY",
        "City": "HICKSVILLE",
        "Div": 999,
        "Mat": 1.03,
        "Labor": 1.463,
        "Total": 1.191,
        "Location": "HICKSVILLE, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 116,
        "State": "NY",
        "City": "FAR ROCKAWAY",
        "Div": 999,
        "Mat": 1.039,
        "Labor": 1.739,
        "Total": 1.3,
        "Location": "FAR ROCKAWAY, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 117,
        "State": "NY",
        "City": "HICKSVILLE",
        "Div": 999,
        "Mat": 1.03,
        "Labor": 1.463,
        "Total": 1.191,
        "Location": "HICKSVILLE, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 118,
        "State": "NY",
        "City": "HICKSVILLE",
        "Div": 999,
        "Mat": 1.03,
        "Labor": 1.463,
        "Total": 1.191,
        "Location": "HICKSVILLE, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 119,
        "State": "NY",
        "City": "RIVERHEAD",
        "Div": 999,
        "Mat": 1.038,
        "Labor": 1.509,
        "Total": 1.214,
        "Location": "RIVERHEAD, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 120,
        "State": "NY",
        "City": "ALBANY",
        "Div": 999,
        "Mat": 1.039,
        "Labor": 1.084,
        "Total": 1.056,
        "Location": "ALBANY, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 121,
        "State": "NY",
        "City": "ALBANY",
        "Div": 999,
        "Mat": 1.039,
        "Labor": 1.084,
        "Total": 1.056,
        "Location": "ALBANY, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 122,
        "State": "NY",
        "City": "ALBANY",
        "Div": 999,
        "Mat": 1.039,
        "Labor": 1.084,
        "Total": 1.056,
        "Location": "ALBANY, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 123,
        "State": "NY",
        "City": "SCHENECTADY",
        "Div": 999,
        "Mat": 1.034,
        "Labor": 1.085,
        "Total": 1.053,
        "Location": "SCHENECTADY, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 124,
        "State": "NY",
        "City": "KINGSTON",
        "Div": 999,
        "Mat": 1.056,
        "Labor": 1.22,
        "Total": 1.117,
        "Location": "KINGSTON, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 125,
        "State": "NY",
        "City": "POUGHKEEPSIE",
        "Div": 999,
        "Mat": 1.049,
        "Labor": 1.322,
        "Total": 1.151,
        "Location": "POUGHKEEPSIE, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 126,
        "State": "NY",
        "City": "POUGHKEEPSIE",
        "Div": 999,
        "Mat": 1.049,
        "Labor": 1.322,
        "Total": 1.151,
        "Location": "POUGHKEEPSIE, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 127,
        "State": "NY",
        "City": "MONTICELLO",
        "Div": 999,
        "Mat": 1.044,
        "Labor": 1.228,
        "Total": 1.113,
        "Location": "MONTICELLO, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 128,
        "State": "NY",
        "City": "GLENS FALLS",
        "Div": 999,
        "Mat": 0.978,
        "Labor": 0.991,
        "Total": 0.983,
        "Location": "GLENS FALLS, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 129,
        "State": "NY",
        "City": "PLATTSBURGH",
        "Div": 999,
        "Mat": 1.018,
        "Labor": 0.874,
        "Total": 0.964,
        "Location": "PLATTSBURGH, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 130,
        "State": "NY",
        "City": "SYRACUSE",
        "Div": 999,
        "Mat": 1.014,
        "Labor": 1.01,
        "Total": 1.013,
        "Location": "SYRACUSE, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 131,
        "State": "NY",
        "City": "SYRACUSE",
        "Div": 999,
        "Mat": 1.014,
        "Labor": 1.01,
        "Total": 1.013,
        "Location": "SYRACUSE, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 132,
        "State": "NY",
        "City": "SYRACUSE",
        "Div": 999,
        "Mat": 1.014,
        "Labor": 1.01,
        "Total": 1.013,
        "Location": "SYRACUSE, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 133,
        "State": "NY",
        "City": "UTICA",
        "Div": 999,
        "Mat": 0.992,
        "Labor": 1.014,
        "Total": 1,
        "Location": "UTICA, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 134,
        "State": "NY",
        "City": "UTICA",
        "Div": 999,
        "Mat": 0.992,
        "Labor": 1.014,
        "Total": 1,
        "Location": "UTICA, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 135,
        "State": "NY",
        "City": "UTICA",
        "Div": 999,
        "Mat": 0.992,
        "Labor": 1.014,
        "Total": 1,
        "Location": "UTICA, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 136,
        "State": "NY",
        "City": "WATERTOWN",
        "Div": 999,
        "Mat": 1.008,
        "Labor": 0.961,
        "Total": 0.99,
        "Location": "WATERTOWN, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 137,
        "State": "NY",
        "City": "BINGHAMTON",
        "Div": 999,
        "Mat": 1.011,
        "Labor": 0.985,
        "Total": 1.001,
        "Location": "BINGHAMTON, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 138,
        "State": "NY",
        "City": "BINGHAMTON",
        "Div": 999,
        "Mat": 1.011,
        "Labor": 0.985,
        "Total": 1.001,
        "Location": "BINGHAMTON, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 139,
        "State": "NY",
        "City": "BINGHAMTON",
        "Div": 999,
        "Mat": 1.011,
        "Labor": 0.985,
        "Total": 1.001,
        "Location": "BINGHAMTON, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 140,
        "State": "NY",
        "City": "BUFFALO",
        "Div": 999,
        "Mat": 1.057,
        "Labor": 1.085,
        "Total": 1.067,
        "Location": "BUFFALO, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 141,
        "State": "NY",
        "City": "BUFFALO",
        "Div": 999,
        "Mat": 1.057,
        "Labor": 1.085,
        "Total": 1.067,
        "Location": "BUFFALO, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 142,
        "State": "NY",
        "City": "BUFFALO",
        "Div": 999,
        "Mat": 1.057,
        "Labor": 1.085,
        "Total": 1.067,
        "Location": "BUFFALO, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 143,
        "State": "NY",
        "City": "NIAGARA FALLS",
        "Div": 999,
        "Mat": 1.004,
        "Labor": 0.984,
        "Total": 0.997,
        "Location": "NIAGARA FALLS, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 144,
        "State": "NY",
        "City": "ROCHESTER",
        "Div": 999,
        "Mat": 1.044,
        "Labor": 0.999,
        "Total": 1.027,
        "Location": "ROCHESTER, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 145,
        "State": "NY",
        "City": "ROCHESTER",
        "Div": 999,
        "Mat": 1.044,
        "Labor": 0.999,
        "Total": 1.027,
        "Location": "ROCHESTER, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 146,
        "State": "NY",
        "City": "ROCHESTER",
        "Div": 999,
        "Mat": 1.044,
        "Labor": 0.999,
        "Total": 1.027,
        "Location": "ROCHESTER, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 147,
        "State": "NY",
        "City": "JAMESTOWN",
        "Div": 999,
        "Mat": 0.995,
        "Labor": 0.878,
        "Total": 0.951,
        "Location": "JAMESTOWN, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 148,
        "State": "NY",
        "City": "ELMIRA",
        "Div": 999,
        "Mat": 0.996,
        "Labor": 0.987,
        "Total": 0.992,
        "Location": "ELMIRA, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 149,
        "State": "NY",
        "City": "ELMIRA",
        "Div": 999,
        "Mat": 0.996,
        "Labor": 0.987,
        "Total": 0.992,
        "Location": "ELMIRA, NY"
    },
    {
        "Year": "2023Q1",
        "Zip": 150,
        "State": "PA",
        "City": "PITTSBURGH",
        "Div": 999,
        "Mat": 1.025,
        "Labor": 1.046,
        "Total": 1.033,
        "Location": "PITTSBURGH, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 151,
        "State": "PA",
        "City": "PITTSBURGH",
        "Div": 999,
        "Mat": 1.025,
        "Labor": 1.046,
        "Total": 1.033,
        "Location": "PITTSBURGH, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 152,
        "State": "PA",
        "City": "PITTSBURGH",
        "Div": 999,
        "Mat": 1.025,
        "Labor": 1.046,
        "Total": 1.033,
        "Location": "PITTSBURGH, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 153,
        "State": "PA",
        "City": "WASHINGTON",
        "Div": 999,
        "Mat": 0.979,
        "Labor": 1.005,
        "Total": 0.988,
        "Location": "WASHINGTON, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 154,
        "State": "PA",
        "City": "UNIONTOWN",
        "Div": 999,
        "Mat": 0.986,
        "Labor": 0.979,
        "Total": 0.983,
        "Location": "UNIONTOWN, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 155,
        "State": "PA",
        "City": "BEDFORD",
        "Div": 999,
        "Mat": 0.992,
        "Labor": 0.871,
        "Total": 0.947,
        "Location": "BEDFORD, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 156,
        "State": "PA",
        "City": "GREENSBURG",
        "Div": 999,
        "Mat": 0.992,
        "Labor": 0.946,
        "Total": 0.975,
        "Location": "GREENSBURG, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 157,
        "State": "PA",
        "City": "INDIANA",
        "Div": 999,
        "Mat": 0.981,
        "Labor": 0.964,
        "Total": 0.975,
        "Location": "INDIANA, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 158,
        "State": "PA",
        "City": "DUBOIS",
        "Div": 999,
        "Mat": 0.995,
        "Labor": 0.915,
        "Total": 0.965,
        "Location": "DUBOIS, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 159,
        "State": "PA",
        "City": "JOHNSTOWN",
        "Div": 999,
        "Mat": 0.991,
        "Labor": 0.973,
        "Total": 0.984,
        "Location": "JOHNSTOWN, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 160,
        "State": "PA",
        "City": "BUTLER",
        "Div": 999,
        "Mat": 0.944,
        "Labor": 0.946,
        "Total": 0.945,
        "Location": "BUTLER, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 161,
        "State": "PA",
        "City": "NEW CASTLE",
        "Div": 999,
        "Mat": 0.944,
        "Labor": 0.964,
        "Total": 0.951,
        "Location": "NEW CASTLE, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 162,
        "State": "PA",
        "City": "KITTANNING",
        "Div": 999,
        "Mat": 0.948,
        "Labor": 0.952,
        "Total": 0.949,
        "Location": "KITTANNING, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 163,
        "State": "PA",
        "City": "OIL CITY",
        "Div": 999,
        "Mat": 0.943,
        "Labor": 0.894,
        "Total": 0.925,
        "Location": "OIL CITY, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 164,
        "State": "PA",
        "City": "ERIE",
        "Div": 999,
        "Mat": 0.966,
        "Labor": 0.943,
        "Total": 0.957,
        "Location": "ERIE, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 165,
        "State": "PA",
        "City": "ERIE",
        "Div": 999,
        "Mat": 0.966,
        "Labor": 0.943,
        "Total": 0.957,
        "Location": "ERIE, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 166,
        "State": "PA",
        "City": "ALTOONA",
        "Div": 999,
        "Mat": 0.967,
        "Labor": 0.947,
        "Total": 0.96,
        "Location": "ALTOONA, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 167,
        "State": "PA",
        "City": "BRADFORD",
        "Div": 999,
        "Mat": 0.976,
        "Labor": 0.901,
        "Total": 0.948,
        "Location": "BRADFORD, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 168,
        "State": "PA",
        "City": "STATE COLLEGE",
        "Div": 999,
        "Mat": 0.971,
        "Labor": 0.968,
        "Total": 0.97,
        "Location": "STATE COLLEGE, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 169,
        "State": "PA",
        "City": "WELLSBORO",
        "Div": 999,
        "Mat": 0.98,
        "Labor": 0.837,
        "Total": 0.927,
        "Location": "WELLSBORO, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 170,
        "State": "PA",
        "City": "HARRISBURG",
        "Div": 999,
        "Mat": 1,
        "Labor": 0.974,
        "Total": 0.99,
        "Location": "HARRISBURG, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 171,
        "State": "PA",
        "City": "HARRISBURG",
        "Div": 999,
        "Mat": 1,
        "Labor": 0.974,
        "Total": 0.99,
        "Location": "HARRISBURG, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 172,
        "State": "PA",
        "City": "CHAMBERSBURG",
        "Div": 999,
        "Mat": 0.955,
        "Labor": 0.804,
        "Total": 0.899,
        "Location": "CHAMBERSBURG, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 173,
        "State": "PA",
        "City": "YORK",
        "Div": 999,
        "Mat": 0.964,
        "Labor": 0.936,
        "Total": 0.954,
        "Location": "YORK, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 174,
        "State": "PA",
        "City": "YORK",
        "Div": 999,
        "Mat": 0.964,
        "Labor": 0.936,
        "Total": 0.954,
        "Location": "YORK, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 175,
        "State": "PA",
        "City": "LANCASTER",
        "Div": 999,
        "Mat": 0.944,
        "Labor": 0.947,
        "Total": 0.945,
        "Location": "LANCASTER, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 176,
        "State": "PA",
        "City": "LANCASTER",
        "Div": 999,
        "Mat": 0.944,
        "Labor": 0.947,
        "Total": 0.945,
        "Location": "LANCASTER, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 177,
        "State": "PA",
        "City": "WILLIAMSPORT",
        "Div": 999,
        "Mat": 0.931,
        "Labor": 0.904,
        "Total": 0.921,
        "Location": "WILLIAMSPORT, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 178,
        "State": "PA",
        "City": "SUNBURY",
        "Div": 999,
        "Mat": 0.949,
        "Labor": 0.835,
        "Total": 0.907,
        "Location": "SUNBURY, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 179,
        "State": "PA",
        "City": "POTTSVILLE",
        "Div": 999,
        "Mat": 0.943,
        "Labor": 0.869,
        "Total": 0.915,
        "Location": "POTTSVILLE, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 180,
        "State": "PA",
        "City": "LEHIGH VALLEY",
        "Div": 999,
        "Mat": 0.977,
        "Labor": 1.008,
        "Total": 0.989,
        "Location": "LEHIGH VALLEY, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 181,
        "State": "PA",
        "City": "ALLENTOWN",
        "Div": 999,
        "Mat": 0.999,
        "Labor": 1.043,
        "Total": 1.015,
        "Location": "ALLENTOWN, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 182,
        "State": "PA",
        "City": "HAZLETON",
        "Div": 999,
        "Mat": 0.973,
        "Labor": 0.867,
        "Total": 0.934,
        "Location": "HAZLETON, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 183,
        "State": "PA",
        "City": "STROUDSBURG",
        "Div": 999,
        "Mat": 0.972,
        "Labor": 0.946,
        "Total": 0.962,
        "Location": "STROUDSBURG, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 184,
        "State": "PA",
        "City": "SCRANTON",
        "Div": 999,
        "Mat": 1.01,
        "Labor": 0.95,
        "Total": 0.988,
        "Location": "SCRANTON, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 185,
        "State": "PA",
        "City": "SCRANTON",
        "Div": 999,
        "Mat": 1.01,
        "Labor": 0.95,
        "Total": 0.988,
        "Location": "SCRANTON, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 186,
        "State": "PA",
        "City": "WILKES-BARRE",
        "Div": 999,
        "Mat": 0.97,
        "Labor": 0.934,
        "Total": 0.957,
        "Location": "WILKES-BARRE, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 187,
        "State": "PA",
        "City": "WILKES-BARRE",
        "Div": 999,
        "Mat": 0.97,
        "Labor": 0.934,
        "Total": 0.957,
        "Location": "WILKES-BARRE, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 188,
        "State": "PA",
        "City": "MONTROSE",
        "Div": 999,
        "Mat": 0.968,
        "Labor": 0.875,
        "Total": 0.933,
        "Location": "MONTROSE, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 189,
        "State": "PA",
        "City": "DOYLESTOWN",
        "Div": 999,
        "Mat": 0.966,
        "Labor": 1.156,
        "Total": 1.037,
        "Location": "DOYLESTOWN, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 190,
        "State": "PA",
        "City": "PHILADELPHIA",
        "Div": 999,
        "Mat": 1.041,
        "Labor": 1.386,
        "Total": 1.17,
        "Location": "PHILADELPHIA, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 191,
        "State": "PA",
        "City": "PHILADELPHIA",
        "Div": 999,
        "Mat": 1.041,
        "Labor": 1.386,
        "Total": 1.17,
        "Location": "PHILADELPHIA, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 193,
        "State": "PA",
        "City": "WESTCHESTER",
        "Div": 999,
        "Mat": 1.001,
        "Labor": 1.161,
        "Total": 1.06,
        "Location": "WESTCHESTER, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 194,
        "State": "PA",
        "City": "NORRISTOWN",
        "Div": 999,
        "Mat": 0.992,
        "Labor": 1.176,
        "Total": 1.061,
        "Location": "NORRISTOWN, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 195,
        "State": "PA",
        "City": "READING",
        "Div": 999,
        "Mat": 1.012,
        "Labor": 0.999,
        "Total": 1.007,
        "Location": "READING, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 196,
        "State": "PA",
        "City": "READING",
        "Div": 999,
        "Mat": 1.012,
        "Labor": 0.999,
        "Total": 1.007,
        "Location": "READING, PA"
    },
    {
        "Year": "2023Q1",
        "Zip": 197,
        "State": "DE",
        "City": "NEWARK",
        "Div": 999,
        "Mat": 1.038,
        "Labor": 1.103,
        "Total": 1.062,
        "Location": "NEWARK, DE"
    },
    {
        "Year": "2023Q1",
        "Zip": 198,
        "State": "DE",
        "City": "WILMINGTON",
        "Div": 999,
        "Mat": 1.048,
        "Labor": 1.102,
        "Total": 1.068,
        "Location": "WILMINGTON, DE"
    },
    {
        "Year": "2023Q1",
        "Zip": 199,
        "State": "DE",
        "City": "DOVER",
        "Div": 999,
        "Mat": 1.053,
        "Labor": 1.103,
        "Total": 1.072,
        "Location": "DOVER, DE"
    },
    {
        "Year": "2023Q1",
        "Zip": 200,
        "State": "DC",
        "City": "WASHINGTON",
        "Div": 999,
        "Mat": 1.055,
        "Labor": 0.895,
        "Total": 0.995,
        "Location": "WASHINGTON, DC"
    },
    {
        "Year": "2023Q1",
        "Zip": 201,
        "State": "DC",
        "City": "WASHINGTON",
        "Div": 999,
        "Mat": 1.055,
        "Labor": 0.895,
        "Total": 0.995,
        "Location": "WASHINGTON, DC"
    },
    {
        "Year": "2023Q1",
        "Zip": 202,
        "State": "DC",
        "City": "WASHINGTON",
        "Div": 999,
        "Mat": 1.055,
        "Labor": 0.895,
        "Total": 0.995,
        "Location": "WASHINGTON, DC"
    },
    {
        "Year": "2023Q1",
        "Zip": 203,
        "State": "DC",
        "City": "WASHINGTON",
        "Div": 999,
        "Mat": 1.055,
        "Labor": 0.895,
        "Total": 0.995,
        "Location": "WASHINGTON, DC"
    },
    {
        "Year": "2023Q1",
        "Zip": 204,
        "State": "DC",
        "City": "WASHINGTON",
        "Div": 999,
        "Mat": 1.055,
        "Labor": 0.895,
        "Total": 0.995,
        "Location": "WASHINGTON, DC"
    },
    {
        "Year": "2023Q1",
        "Zip": 205,
        "State": "DC",
        "City": "WASHINGTON",
        "Div": 999,
        "Mat": 1.055,
        "Labor": 0.895,
        "Total": 0.995,
        "Location": "WASHINGTON, DC"
    },
    {
        "Year": "2023Q1",
        "Zip": 206,
        "State": "MD",
        "City": "WALDORF",
        "Div": 999,
        "Mat": 1.002,
        "Labor": 0.803,
        "Total": 0.928,
        "Location": "WALDORF, MD"
    },
    {
        "Year": "2023Q1",
        "Zip": 207,
        "State": "MD",
        "City": "COLLEGE PARK",
        "Div": 999,
        "Mat": 0.999,
        "Labor": 0.813,
        "Total": 0.93,
        "Location": "COLLEGE PARK, MD"
    },
    {
        "Year": "2023Q1",
        "Zip": 208,
        "State": "MD",
        "City": "COLLEGE PARK",
        "Div": 999,
        "Mat": 0.999,
        "Labor": 0.813,
        "Total": 0.93,
        "Location": "COLLEGE PARK, MD"
    },
    {
        "Year": "2023Q1",
        "Zip": 209,
        "State": "MD",
        "City": "SILVER SPRING",
        "Div": 999,
        "Mat": 0.996,
        "Labor": 0.804,
        "Total": 0.925,
        "Location": "SILVER SPRING, MD"
    },
    {
        "Year": "2023Q1",
        "Zip": 210,
        "State": "MD",
        "City": "BALTIMORE",
        "Div": 999,
        "Mat": 1.056,
        "Labor": 0.849,
        "Total": 0.979,
        "Location": "BALTIMORE, MD"
    },
    {
        "Year": "2023Q1",
        "Zip": 211,
        "State": "MD",
        "City": "BALTIMORE",
        "Div": 999,
        "Mat": 1.056,
        "Labor": 0.849,
        "Total": 0.979,
        "Location": "BALTIMORE, MD"
    },
    {
        "Year": "2023Q1",
        "Zip": 212,
        "State": "MD",
        "City": "BALTIMORE",
        "Div": 999,
        "Mat": 1.056,
        "Labor": 0.849,
        "Total": 0.979,
        "Location": "BALTIMORE, MD"
    },
    {
        "Year": "2023Q1",
        "Zip": 214,
        "State": "MD",
        "City": "ANNAPOLIS",
        "Div": 999,
        "Mat": 1.061,
        "Labor": 0.801,
        "Total": 0.964,
        "Location": "ANNAPOLIS, MD"
    },
    {
        "Year": "2023Q1",
        "Zip": 215,
        "State": "MD",
        "City": "CUMBERLAND",
        "Div": 999,
        "Mat": 1.003,
        "Labor": 0.797,
        "Total": 0.926,
        "Location": "CUMBERLAND, MD"
    },
    {
        "Year": "2023Q1",
        "Zip": 216,
        "State": "MD",
        "City": "EASTON",
        "Div": 999,
        "Mat": 1.02,
        "Labor": 0.683,
        "Total": 0.894,
        "Location": "EASTON, MD"
    },
    {
        "Year": "2023Q1",
        "Zip": 217,
        "State": "MD",
        "City": "HAGERSTOWN",
        "Div": 999,
        "Mat": 1.015,
        "Labor": 0.842,
        "Total": 0.951,
        "Location": "HAGERSTOWN, MD"
    },
    {
        "Year": "2023Q1",
        "Zip": 218,
        "State": "MD",
        "City": "SALISBURY",
        "Div": 999,
        "Mat": 1.023,
        "Labor": 0.611,
        "Total": 0.869,
        "Location": "SALISBURY, MD"
    },
    {
        "Year": "2023Q1",
        "Zip": 219,
        "State": "MD",
        "City": "ELKTON",
        "Div": 999,
        "Mat": 0.994,
        "Labor": 0.781,
        "Total": 0.915,
        "Location": "ELKTON, MD"
    },
    {
        "Year": "2023Q1",
        "Zip": 220,
        "State": "VA",
        "City": "FAIRFAX",
        "Div": 999,
        "Mat": 1.023,
        "Labor": 0.825,
        "Total": 0.949,
        "Location": "FAIRFAX, VA"
    },
    {
        "Year": "2023Q1",
        "Zip": 221,
        "State": "VA",
        "City": "FAIRFAX",
        "Div": 999,
        "Mat": 1.023,
        "Labor": 0.825,
        "Total": 0.949,
        "Location": "FAIRFAX, VA"
    },
    {
        "Year": "2023Q1",
        "Zip": 222,
        "State": "VA",
        "City": "ARLINGTON",
        "Div": 999,
        "Mat": 1.039,
        "Labor": 0.812,
        "Total": 0.955,
        "Location": "ARLINGTON, VA"
    },
    {
        "Year": "2023Q1",
        "Zip": 223,
        "State": "VA",
        "City": "ALEXANDRIA",
        "Div": 999,
        "Mat": 1.034,
        "Labor": 0.808,
        "Total": 0.95,
        "Location": "ALEXANDRIA, VA"
    },
    {
        "Year": "2023Q1",
        "Zip": 224,
        "State": "VA",
        "City": "FREDERICKSBURG",
        "Div": 999,
        "Mat": 1.014,
        "Labor": 0.756,
        "Total": 0.918,
        "Location": "FREDERICKSBURG, VA"
    },
    {
        "Year": "2023Q1",
        "Zip": 225,
        "State": "VA",
        "City": "FREDERICKSBURG",
        "Div": 999,
        "Mat": 1.014,
        "Labor": 0.756,
        "Total": 0.918,
        "Location": "FREDERICKSBURG, VA"
    },
    {
        "Year": "2023Q1",
        "Zip": 226,
        "State": "VA",
        "City": "WINCHESTER",
        "Div": 999,
        "Mat": 1.018,
        "Labor": 0.72,
        "Total": 0.907,
        "Location": "WINCHESTER, VA"
    },
    {
        "Year": "2023Q1",
        "Zip": 227,
        "State": "VA",
        "City": "CULPEPER",
        "Div": 999,
        "Mat": 1.018,
        "Labor": 0.777,
        "Total": 0.928,
        "Location": "CULPEPER, VA"
    },
    {
        "Year": "2023Q1",
        "Zip": 228,
        "State": "VA",
        "City": "HARRISONBURG",
        "Div": 999,
        "Mat": 1.019,
        "Labor": 0.745,
        "Total": 0.917,
        "Location": "HARRISONBURG, VA"
    },
    {
        "Year": "2023Q1",
        "Zip": 229,
        "State": "VA",
        "City": "CHARLOTTESVILLE",
        "Div": 999,
        "Mat": 1.023,
        "Labor": 0.684,
        "Total": 0.897,
        "Location": "CHARLOTTESVILLE, VA"
    },
    {
        "Year": "2023Q1",
        "Zip": 230,
        "State": "VA",
        "City": "RICHMOND",
        "Div": 999,
        "Mat": 1.024,
        "Labor": 0.704,
        "Total": 0.905,
        "Location": "RICHMOND, VA"
    },
    {
        "Year": "2023Q1",
        "Zip": 231,
        "State": "VA",
        "City": "RICHMOND",
        "Div": 999,
        "Mat": 1.024,
        "Labor": 0.704,
        "Total": 0.905,
        "Location": "RICHMOND, VA"
    },
    {
        "Year": "2023Q1",
        "Zip": 232,
        "State": "VA",
        "City": "RICHMOND",
        "Div": 999,
        "Mat": 1.024,
        "Labor": 0.704,
        "Total": 0.905,
        "Location": "RICHMOND, VA"
    },
    {
        "Year": "2023Q1",
        "Zip": 233,
        "State": "VA",
        "City": "NORFOLK",
        "Div": 999,
        "Mat": 1.023,
        "Labor": 0.666,
        "Total": 0.89,
        "Location": "NORFOLK, VA"
    },
    {
        "Year": "2023Q1",
        "Zip": 234,
        "State": "VA",
        "City": "NORFOLK",
        "Div": 999,
        "Mat": 1.023,
        "Labor": 0.666,
        "Total": 0.89,
        "Location": "NORFOLK, VA"
    },
    {
        "Year": "2023Q1",
        "Zip": 235,
        "State": "VA",
        "City": "NORFOLK",
        "Div": 999,
        "Mat": 1.023,
        "Labor": 0.666,
        "Total": 0.89,
        "Location": "NORFOLK, VA"
    },
    {
        "Year": "2023Q1",
        "Zip": 236,
        "State": "VA",
        "City": "NEWPORT NEWS",
        "Div": 999,
        "Mat": 1.012,
        "Labor": 0.667,
        "Total": 0.884,
        "Location": "NEWPORT NEWS, VA"
    },
    {
        "Year": "2023Q1",
        "Zip": 237,
        "State": "VA",
        "City": "PORTSMOUTH",
        "Div": 999,
        "Mat": 1.007,
        "Labor": 0.648,
        "Total": 0.873,
        "Location": "PORTSMOUTH, VA"
    },
    {
        "Year": "2023Q1",
        "Zip": 238,
        "State": "VA",
        "City": "PETERSBURG",
        "Div": 999,
        "Mat": 1.001,
        "Labor": 0.69,
        "Total": 0.885,
        "Location": "PETERSBURG, VA"
    },
    {
        "Year": "2023Q1",
        "Zip": 239,
        "State": "VA",
        "City": "FARMVILLE",
        "Div": 999,
        "Mat": 0.994,
        "Labor": 0.627,
        "Total": 0.857,
        "Location": "FARMVILLE, VA"
    },
    {
        "Year": "2023Q1",
        "Zip": 240,
        "State": "VA",
        "City": "ROANOKE",
        "Div": 999,
        "Mat": 1.041,
        "Labor": 0.657,
        "Total": 0.898,
        "Location": "ROANOKE, VA"
    },
    {
        "Year": "2023Q1",
        "Zip": 241,
        "State": "VA",
        "City": "ROANOKE",
        "Div": 999,
        "Mat": 1.041,
        "Labor": 0.657,
        "Total": 0.898,
        "Location": "ROANOKE, VA"
    },
    {
        "Year": "2023Q1",
        "Zip": 242,
        "State": "VA",
        "City": "BRISTOL",
        "Div": 999,
        "Mat": 1.011,
        "Labor": 0.567,
        "Total": 0.846,
        "Location": "BRISTOL, VA"
    },
    {
        "Year": "2023Q1",
        "Zip": 243,
        "State": "VA",
        "City": "PULASKI",
        "Div": 999,
        "Mat": 1.008,
        "Labor": 0.658,
        "Total": 0.877,
        "Location": "PULASKI, VA"
    },
    {
        "Year": "2023Q1",
        "Zip": 244,
        "State": "VA",
        "City": "STAUNTON",
        "Div": 999,
        "Mat": 1.017,
        "Labor": 0.648,
        "Total": 0.88,
        "Location": "STAUNTON, VA"
    },
    {
        "Year": "2023Q1",
        "Zip": 245,
        "State": "VA",
        "City": "LYNCHBURG",
        "Div": 999,
        "Mat": 1.017,
        "Labor": 0.666,
        "Total": 0.886,
        "Location": "LYNCHBURG, VA"
    },
    {
        "Year": "2023Q1",
        "Zip": 246,
        "State": "VA",
        "City": "GRUNDY",
        "Div": 999,
        "Mat": 1.012,
        "Labor": 0.6,
        "Total": 0.858,
        "Location": "GRUNDY, VA"
    },
    {
        "Year": "2023Q1",
        "Zip": 247,
        "State": "WV",
        "City": "BLUEFIELD",
        "Div": 999,
        "Mat": 1,
        "Labor": 0.804,
        "Total": 0.927,
        "Location": "BLUEFIELD, WV"
    },
    {
        "Year": "2023Q1",
        "Zip": 248,
        "State": "WV",
        "City": "BLUEFIELD",
        "Div": 999,
        "Mat": 1,
        "Labor": 0.804,
        "Total": 0.927,
        "Location": "BLUEFIELD, WV"
    },
    {
        "Year": "2023Q1",
        "Zip": 249,
        "State": "WV",
        "City": "LEWISBURG",
        "Div": 999,
        "Mat": 1.018,
        "Labor": 0.827,
        "Total": 0.947,
        "Location": "LEWISBURG, WV"
    },
    {
        "Year": "2023Q1",
        "Zip": 250,
        "State": "WV",
        "City": "CHARLESTON",
        "Div": 999,
        "Mat": 1.02,
        "Labor": 0.887,
        "Total": 0.97,
        "Location": "CHARLESTON, WV"
    },
    {
        "Year": "2023Q1",
        "Zip": 251,
        "State": "WV",
        "City": "CHARLESTON",
        "Div": 999,
        "Mat": 1.02,
        "Labor": 0.887,
        "Total": 0.97,
        "Location": "CHARLESTON, WV"
    },
    {
        "Year": "2023Q1",
        "Zip": 252,
        "State": "WV",
        "City": "CHARLESTON",
        "Div": 999,
        "Mat": 1.02,
        "Labor": 0.887,
        "Total": 0.97,
        "Location": "CHARLESTON, WV"
    },
    {
        "Year": "2023Q1",
        "Zip": 253,
        "State": "WV",
        "City": "CHARLESTON",
        "Div": 999,
        "Mat": 1.02,
        "Labor": 0.887,
        "Total": 0.97,
        "Location": "CHARLESTON, WV"
    },
    {
        "Year": "2023Q1",
        "Zip": 254,
        "State": "WV",
        "City": "MARTINSBURG",
        "Div": 999,
        "Mat": 1.006,
        "Labor": 0.771,
        "Total": 0.918,
        "Location": "MARTINSBURG, WV"
    },
    {
        "Year": "2023Q1",
        "Zip": 255,
        "State": "WV",
        "City": "HUNTINGTON",
        "Div": 999,
        "Mat": 1.028,
        "Labor": 0.869,
        "Total": 0.969,
        "Location": "HUNTINGTON, WV"
    },
    {
        "Year": "2023Q1",
        "Zip": 256,
        "State": "WV",
        "City": "HUNTINGTON",
        "Div": 999,
        "Mat": 1.028,
        "Labor": 0.869,
        "Total": 0.969,
        "Location": "HUNTINGTON, WV"
    },
    {
        "Year": "2023Q1",
        "Zip": 257,
        "State": "WV",
        "City": "HUNTINGTON",
        "Div": 999,
        "Mat": 1.028,
        "Labor": 0.869,
        "Total": 0.969,
        "Location": "HUNTINGTON, WV"
    },
    {
        "Year": "2023Q1",
        "Zip": 258,
        "State": "WV",
        "City": "BECKLEY",
        "Div": 999,
        "Mat": 0.993,
        "Labor": 0.838,
        "Total": 0.936,
        "Location": "BECKLEY, WV"
    },
    {
        "Year": "2023Q1",
        "Zip": 259,
        "State": "WV",
        "City": "BECKLEY",
        "Div": 999,
        "Mat": 0.993,
        "Labor": 0.838,
        "Total": 0.936,
        "Location": "BECKLEY, WV"
    },
    {
        "Year": "2023Q1",
        "Zip": 260,
        "State": "WV",
        "City": "WHEELING",
        "Div": 999,
        "Mat": 1.032,
        "Labor": 0.891,
        "Total": 0.979,
        "Location": "WHEELING, WV"
    },
    {
        "Year": "2023Q1",
        "Zip": 261,
        "State": "WV",
        "City": "PARKERSBURG",
        "Div": 999,
        "Mat": 1.023,
        "Labor": 0.861,
        "Total": 0.962,
        "Location": "PARKERSBURG, WV"
    },
    {
        "Year": "2023Q1",
        "Zip": 262,
        "State": "WV",
        "City": "BUCKHANNON",
        "Div": 999,
        "Mat": 1.012,
        "Labor": 0.89,
        "Total": 0.967,
        "Location": "BUCKHANNON, WV"
    },
    {
        "Year": "2023Q1",
        "Zip": 263,
        "State": "WV",
        "City": "CLARKSBURG",
        "Div": 999,
        "Mat": 1.018,
        "Labor": 0.899,
        "Total": 0.974,
        "Location": "CLARKSBURG, WV"
    },
    {
        "Year": "2023Q1",
        "Zip": 264,
        "State": "WV",
        "City": "CLARKSBURG",
        "Div": 999,
        "Mat": 1.018,
        "Labor": 0.899,
        "Total": 0.974,
        "Location": "CLARKSBURG, WV"
    },
    {
        "Year": "2023Q1",
        "Zip": 265,
        "State": "WV",
        "City": "MORGANTOWN",
        "Div": 999,
        "Mat": 1.018,
        "Labor": 0.906,
        "Total": 0.976,
        "Location": "MORGANTOWN, WV"
    },
    {
        "Year": "2023Q1",
        "Zip": 266,
        "State": "WV",
        "City": "GASSAWAY",
        "Div": 999,
        "Mat": 1.012,
        "Labor": 0.842,
        "Total": 0.949,
        "Location": "GASSAWAY, WV"
    },
    {
        "Year": "2023Q1",
        "Zip": 267,
        "State": "WV",
        "City": "ROMNEY",
        "Div": 999,
        "Mat": 1.013,
        "Labor": 0.816,
        "Total": 0.94,
        "Location": "ROMNEY, WV"
    },
    {
        "Year": "2023Q1",
        "Zip": 268,
        "State": "WV",
        "City": "PETERSBURG",
        "Div": 999,
        "Mat": 1.009,
        "Labor": 0.823,
        "Total": 0.94,
        "Location": "PETERSBURG, WV"
    },
    {
        "Year": "2023Q1",
        "Zip": 270,
        "State": "NC",
        "City": "GREENSBORO",
        "Div": 999,
        "Mat": 1.041,
        "Labor": 0.642,
        "Total": 0.892,
        "Location": "GREENSBORO, NC"
    },
    {
        "Year": "2023Q1",
        "Zip": 271,
        "State": "NC",
        "City": "WINSTON-SALEM",
        "Div": 999,
        "Mat": 1.037,
        "Labor": 0.642,
        "Total": 0.89,
        "Location": "WINSTON-SALEM, NC"
    },
    {
        "Year": "2023Q1",
        "Zip": 272,
        "State": "NC",
        "City": "GREENSBORO",
        "Div": 999,
        "Mat": 1.041,
        "Labor": 0.642,
        "Total": 0.892,
        "Location": "GREENSBORO, NC"
    },
    {
        "Year": "2023Q1",
        "Zip": 273,
        "State": "NC",
        "City": "GREENSBORO",
        "Div": 999,
        "Mat": 1.041,
        "Labor": 0.642,
        "Total": 0.892,
        "Location": "GREENSBORO, NC"
    },
    {
        "Year": "2023Q1",
        "Zip": 274,
        "State": "NC",
        "City": "GREENSBORO",
        "Div": 999,
        "Mat": 1.041,
        "Labor": 0.642,
        "Total": 0.892,
        "Location": "GREENSBORO, NC"
    },
    {
        "Year": "2023Q1",
        "Zip": 275,
        "State": "NC",
        "City": "RALEIGH",
        "Div": 999,
        "Mat": 1.031,
        "Labor": 0.648,
        "Total": 0.888,
        "Location": "RALEIGH, NC"
    },
    {
        "Year": "2023Q1",
        "Zip": 276,
        "State": "NC",
        "City": "RALEIGH",
        "Div": 999,
        "Mat": 1.031,
        "Labor": 0.648,
        "Total": 0.888,
        "Location": "RALEIGH, NC"
    },
    {
        "Year": "2023Q1",
        "Zip": 277,
        "State": "NC",
        "City": "DURHAM",
        "Div": 999,
        "Mat": 1.058,
        "Labor": 0.642,
        "Total": 0.903,
        "Location": "DURHAM, NC"
    },
    {
        "Year": "2023Q1",
        "Zip": 278,
        "State": "NC",
        "City": "ROCKY MOUNT",
        "Div": 999,
        "Mat": 1.004,
        "Labor": 0.636,
        "Total": 0.867,
        "Location": "ROCKY MOUNT, NC"
    },
    {
        "Year": "2023Q1",
        "Zip": 279,
        "State": "NC",
        "City": "ELIZABETH CITY",
        "Div": 999,
        "Mat": 1.015,
        "Labor": 0.645,
        "Total": 0.877,
        "Location": "ELIZABETH CITY, NC"
    },
    {
        "Year": "2023Q1",
        "Zip": 280,
        "State": "NC",
        "City": "GASTONIA",
        "Div": 999,
        "Mat": 1.014,
        "Labor": 0.682,
        "Total": 0.89,
        "Location": "GASTONIA, NC"
    },
    {
        "Year": "2023Q1",
        "Zip": 281,
        "State": "NC",
        "City": "CHARLOTTE",
        "Div": 999,
        "Mat": 1.013,
        "Labor": 0.717,
        "Total": 0.903,
        "Location": "CHARLOTTE, NC"
    },
    {
        "Year": "2023Q1",
        "Zip": 282,
        "State": "NC",
        "City": "CHARLOTTE",
        "Div": 999,
        "Mat": 1.013,
        "Labor": 0.717,
        "Total": 0.903,
        "Location": "CHARLOTTE, NC"
    },
    {
        "Year": "2023Q1",
        "Zip": 283,
        "State": "NC",
        "City": "FAYETTEVILLE",
        "Div": 999,
        "Mat": 1.045,
        "Labor": 0.629,
        "Total": 0.89,
        "Location": "FAYETTEVILLE, NC"
    },
    {
        "Year": "2023Q1",
        "Zip": 284,
        "State": "NC",
        "City": "WILMINGTON",
        "Div": 999,
        "Mat": 0.999,
        "Labor": 0.619,
        "Total": 0.858,
        "Location": "WILMINGTON, NC"
    },
    {
        "Year": "2023Q1",
        "Zip": 285,
        "State": "NC",
        "City": "KINSTON",
        "Div": 999,
        "Mat": 0.979,
        "Labor": 0.627,
        "Total": 0.848,
        "Location": "KINSTON, NC"
    },
    {
        "Year": "2023Q1",
        "Zip": 286,
        "State": "NC",
        "City": "HICKORY",
        "Div": 999,
        "Mat": 0.981,
        "Labor": 0.688,
        "Total": 0.872,
        "Location": "HICKORY, NC"
    },
    {
        "Year": "2023Q1",
        "Zip": 287,
        "State": "NC",
        "City": "ASHEVILLE",
        "Div": 999,
        "Mat": 1.001,
        "Labor": 0.637,
        "Total": 0.866,
        "Location": "ASHEVILLE, NC"
    },
    {
        "Year": "2023Q1",
        "Zip": 288,
        "State": "NC",
        "City": "ASHEVILLE",
        "Div": 999,
        "Mat": 1.001,
        "Labor": 0.637,
        "Total": 0.866,
        "Location": "ASHEVILLE, NC"
    },
    {
        "Year": "2023Q1",
        "Zip": 289,
        "State": "NC",
        "City": "MURPHY",
        "Div": 999,
        "Mat": 0.985,
        "Labor": 0.61,
        "Total": 0.846,
        "Location": "MURPHY, NC"
    },
    {
        "Year": "2023Q1",
        "Zip": 290,
        "State": "SC",
        "City": "COLUMBIA",
        "Div": 999,
        "Mat": 1.033,
        "Labor": 0.651,
        "Total": 0.891,
        "Location": "COLUMBIA, SC"
    },
    {
        "Year": "2023Q1",
        "Zip": 291,
        "State": "SC",
        "City": "COLUMBIA",
        "Div": 999,
        "Mat": 1.033,
        "Labor": 0.651,
        "Total": 0.891,
        "Location": "COLUMBIA, SC"
    },
    {
        "Year": "2023Q1",
        "Zip": 292,
        "State": "SC",
        "City": "COLUMBIA",
        "Div": 999,
        "Mat": 1.033,
        "Labor": 0.651,
        "Total": 0.891,
        "Location": "COLUMBIA, SC"
    },
    {
        "Year": "2023Q1",
        "Zip": 293,
        "State": "SC",
        "City": "SPARTANBURG",
        "Div": 999,
        "Mat": 1.019,
        "Labor": 0.671,
        "Total": 0.889,
        "Location": "SPARTANBURG, SC"
    },
    {
        "Year": "2023Q1",
        "Zip": 294,
        "State": "SC",
        "City": "CHARLESTON",
        "Div": 999,
        "Mat": 1.037,
        "Labor": 0.695,
        "Total": 0.909,
        "Location": "CHARLESTON, SC"
    },
    {
        "Year": "2023Q1",
        "Zip": 295,
        "State": "SC",
        "City": "FLORENCE",
        "Div": 999,
        "Mat": 1.018,
        "Labor": 0.644,
        "Total": 0.878,
        "Location": "FLORENCE, SC"
    },
    {
        "Year": "2023Q1",
        "Zip": 296,
        "State": "SC",
        "City": "GREENVILLE",
        "Div": 999,
        "Mat": 1.017,
        "Labor": 0.67,
        "Total": 0.888,
        "Location": "GREENVILLE, SC"
    },
    {
        "Year": "2023Q1",
        "Zip": 297,
        "State": "SC",
        "City": "ROCK HILL",
        "Div": 999,
        "Mat": 1.008,
        "Labor": 0.644,
        "Total": 0.873,
        "Location": "ROCK HILL, SC"
    },
    {
        "Year": "2023Q1",
        "Zip": 298,
        "State": "SC",
        "City": "AIKEN",
        "Div": 999,
        "Mat": 1.017,
        "Labor": 0.611,
        "Total": 0.865,
        "Location": "AIKEN, SC"
    },
    {
        "Year": "2023Q1",
        "Zip": 299,
        "State": "SC",
        "City": "BEAUFORT",
        "Div": 999,
        "Mat": 1.024,
        "Labor": 0.63,
        "Total": 0.877,
        "Location": "BEAUFORT, SC"
    },
    {
        "Year": "2023Q1",
        "Zip": 300,
        "State": "GA",
        "City": "ATLANTA",
        "Div": 999,
        "Mat": 1.058,
        "Labor": 0.763,
        "Total": 0.948,
        "Location": "ATLANTA, GA"
    },
    {
        "Year": "2023Q1",
        "Zip": 301,
        "State": "GA",
        "City": "ATLANTA",
        "Div": 999,
        "Mat": 1.058,
        "Labor": 0.763,
        "Total": 0.948,
        "Location": "ATLANTA, GA"
    },
    {
        "Year": "2023Q1",
        "Zip": 302,
        "State": "GA",
        "City": "ATLANTA",
        "Div": 999,
        "Mat": 1.058,
        "Labor": 0.763,
        "Total": 0.948,
        "Location": "ATLANTA, GA"
    },
    {
        "Year": "2023Q1",
        "Zip": 303,
        "State": "GA",
        "City": "ATLANTA",
        "Div": 999,
        "Mat": 1.058,
        "Labor": 0.763,
        "Total": 0.948,
        "Location": "ATLANTA, GA"
    },
    {
        "Year": "2023Q1",
        "Zip": 304,
        "State": "GA",
        "City": "STATESBORO",
        "Div": 999,
        "Mat": 1.035,
        "Labor": 0.684,
        "Total": 0.904,
        "Location": "STATESBORO, GA"
    },
    {
        "Year": "2023Q1",
        "Zip": 305,
        "State": "GA",
        "City": "GAINESVILLE",
        "Div": 999,
        "Mat": 1.016,
        "Labor": 0.614,
        "Total": 0.866,
        "Location": "GAINESVILLE, GA"
    },
    {
        "Year": "2023Q1",
        "Zip": 306,
        "State": "GA",
        "City": "ATHENS",
        "Div": 999,
        "Mat": 1.008,
        "Labor": 0.609,
        "Total": 0.859,
        "Location": "ATHENS, GA"
    },
    {
        "Year": "2023Q1",
        "Zip": 307,
        "State": "GA",
        "City": "DALTON",
        "Div": 999,
        "Mat": 1.032,
        "Labor": 0.644,
        "Total": 0.888,
        "Location": "DALTON, GA"
    },
    {
        "Year": "2023Q1",
        "Zip": 308,
        "State": "GA",
        "City": "AUGUSTA",
        "Div": 999,
        "Mat": 1.02,
        "Labor": 0.73,
        "Total": 0.912,
        "Location": "AUGUSTA, GA"
    },
    {
        "Year": "2023Q1",
        "Zip": 309,
        "State": "GA",
        "City": "AUGUSTA",
        "Div": 999,
        "Mat": 1.02,
        "Labor": 0.73,
        "Total": 0.912,
        "Location": "AUGUSTA, GA"
    },
    {
        "Year": "2023Q1",
        "Zip": 310,
        "State": "GA",
        "City": "MACON",
        "Div": 999,
        "Mat": 1.022,
        "Labor": 0.741,
        "Total": 0.917,
        "Location": "MACON, GA"
    },
    {
        "Year": "2023Q1",
        "Zip": 311,
        "State": "GA",
        "City": "MACON",
        "Div": 999,
        "Mat": 1.022,
        "Labor": 0.741,
        "Total": 0.917,
        "Location": "MACON, GA"
    },
    {
        "Year": "2023Q1",
        "Zip": 312,
        "State": "GA",
        "City": "MACON",
        "Div": 999,
        "Mat": 1.022,
        "Labor": 0.741,
        "Total": 0.917,
        "Location": "MACON, GA"
    },
    {
        "Year": "2023Q1",
        "Zip": 313,
        "State": "GA",
        "City": "SAVANNAH",
        "Div": 999,
        "Mat": 1.047,
        "Labor": 0.736,
        "Total": 0.931,
        "Location": "SAVANNAH, GA"
    },
    {
        "Year": "2023Q1",
        "Zip": 314,
        "State": "GA",
        "City": "SAVANNAH",
        "Div": 999,
        "Mat": 1.047,
        "Labor": 0.736,
        "Total": 0.931,
        "Location": "SAVANNAH, GA"
    },
    {
        "Year": "2023Q1",
        "Zip": 315,
        "State": "GA",
        "City": "WAYCROSS",
        "Div": 999,
        "Mat": 1.038,
        "Labor": 0.658,
        "Total": 0.896,
        "Location": "WAYCROSS, GA"
    },
    {
        "Year": "2023Q1",
        "Zip": 316,
        "State": "GA",
        "City": "VALDOSTA",
        "Div": 999,
        "Mat": 1.039,
        "Labor": 0.713,
        "Total": 0.917,
        "Location": "VALDOSTA, GA"
    },
    {
        "Year": "2023Q1",
        "Zip": 317,
        "State": "GA",
        "City": "ALBANY",
        "Div": 999,
        "Mat": 1.038,
        "Labor": 0.717,
        "Total": 0.918,
        "Location": "ALBANY, GA"
    },
    {
        "Year": "2023Q1",
        "Zip": 318,
        "State": "GA",
        "City": "COLUMBUS",
        "Div": 999,
        "Mat": 1.036,
        "Labor": 0.729,
        "Total": 0.922,
        "Location": "COLUMBUS, GA"
    },
    {
        "Year": "2023Q1",
        "Zip": 319,
        "State": "GA",
        "City": "COLUMBUS",
        "Div": 999,
        "Mat": 1.036,
        "Labor": 0.729,
        "Total": 0.922,
        "Location": "COLUMBUS, GA"
    },
    {
        "Year": "2023Q1",
        "Zip": 320,
        "State": "FL",
        "City": "JACKSONVILLE",
        "Div": 999,
        "Mat": 1.035,
        "Labor": 0.675,
        "Total": 0.901,
        "Location": "JACKSONVILLE, FL"
    },
    {
        "Year": "2023Q1",
        "Zip": 321,
        "State": "FL",
        "City": "DAYTONA BEACH",
        "Div": 999,
        "Mat": 1.038,
        "Labor": 0.693,
        "Total": 0.909,
        "Location": "DAYTONA BEACH, FL"
    },
    {
        "Year": "2023Q1",
        "Zip": 322,
        "State": "FL",
        "City": "JACKSONVILLE",
        "Div": 999,
        "Mat": 1.035,
        "Labor": 0.675,
        "Total": 0.901,
        "Location": "JACKSONVILLE, FL"
    },
    {
        "Year": "2023Q1",
        "Zip": 323,
        "State": "FL",
        "City": "TALLAHASSEE",
        "Div": 999,
        "Mat": 1.044,
        "Labor": 0.68,
        "Total": 0.908,
        "Location": "TALLAHASSEE, FL"
    },
    {
        "Year": "2023Q1",
        "Zip": 324,
        "State": "FL",
        "City": "PANAMA CITY",
        "Div": 999,
        "Mat": 1.05,
        "Labor": 0.676,
        "Total": 0.911,
        "Location": "PANAMA CITY, FL"
    },
    {
        "Year": "2023Q1",
        "Zip": 325,
        "State": "FL",
        "City": "PENSACOLA",
        "Div": 999,
        "Mat": 1.088,
        "Labor": 0.663,
        "Total": 0.929,
        "Location": "PENSACOLA, FL"
    },
    {
        "Year": "2023Q1",
        "Zip": 326,
        "State": "FL",
        "City": "GAINESVILLE",
        "Div": 999,
        "Mat": 1.048,
        "Labor": 0.67,
        "Total": 0.907,
        "Location": "GAINESVILLE, FL"
    },
    {
        "Year": "2023Q1",
        "Zip": 327,
        "State": "FL",
        "City": "ORLANDO",
        "Div": 999,
        "Mat": 1.081,
        "Labor": 0.677,
        "Total": 0.931,
        "Location": "ORLANDO, FL"
    },
    {
        "Year": "2023Q1",
        "Zip": 328,
        "State": "FL",
        "City": "ORLANDO",
        "Div": 999,
        "Mat": 1.081,
        "Labor": 0.677,
        "Total": 0.931,
        "Location": "ORLANDO, FL"
    },
    {
        "Year": "2023Q1",
        "Zip": 329,
        "State": "FL",
        "City": "MELBOURNE",
        "Div": 999,
        "Mat": 1.066,
        "Labor": 0.714,
        "Total": 0.935,
        "Location": "MELBOURNE, FL"
    },
    {
        "Year": "2023Q1",
        "Zip": 330,
        "State": "FL",
        "City": "MIAMI",
        "Div": 999,
        "Mat": 1.048,
        "Labor": 0.71,
        "Total": 0.922,
        "Location": "MIAMI, FL"
    },
    {
        "Year": "2023Q1",
        "Zip": 331,
        "State": "FL",
        "City": "MIAMI",
        "Div": 999,
        "Mat": 1.048,
        "Labor": 0.71,
        "Total": 0.922,
        "Location": "MIAMI, FL"
    },
    {
        "Year": "2023Q1",
        "Zip": 332,
        "State": "FL",
        "City": "MIAMI",
        "Div": 999,
        "Mat": 1.048,
        "Labor": 0.71,
        "Total": 0.922,
        "Location": "MIAMI, FL"
    },
    {
        "Year": "2023Q1",
        "Zip": 333,
        "State": "FL",
        "City": "FORT LAUDERDALE",
        "Div": 999,
        "Mat": 1.035,
        "Labor": 0.684,
        "Total": 0.904,
        "Location": "FORT LAUDERDALE, FL"
    },
    {
        "Year": "2023Q1",
        "Zip": 334,
        "State": "FL",
        "City": "WEST PALM BEACH",
        "Div": 999,
        "Mat": 1.023,
        "Labor": 0.645,
        "Total": 0.882,
        "Location": "WEST PALM BEACH, FL"
    },
    {
        "Year": "2023Q1",
        "Zip": 335,
        "State": "FL",
        "City": "TAMPA",
        "Div": 999,
        "Mat": 1.048,
        "Labor": 0.691,
        "Total": 0.915,
        "Location": "TAMPA, FL"
    },
    {
        "Year": "2023Q1",
        "Zip": 336,
        "State": "FL",
        "City": "TAMPA",
        "Div": 999,
        "Mat": 1.048,
        "Labor": 0.691,
        "Total": 0.915,
        "Location": "TAMPA, FL"
    },
    {
        "Year": "2023Q1",
        "Zip": 337,
        "State": "FL",
        "City": "ST. PETERSBURG",
        "Div": 999,
        "Mat": 1.08,
        "Labor": 0.65,
        "Total": 0.919,
        "Location": "ST. PETERSBURG, FL"
    },
    {
        "Year": "2023Q1",
        "Zip": 338,
        "State": "FL",
        "City": "LAKELAND",
        "Div": 999,
        "Mat": 1.044,
        "Labor": 0.664,
        "Total": 0.902,
        "Location": "LAKELAND, FL"
    },
    {
        "Year": "2023Q1",
        "Zip": 339,
        "State": "FL",
        "City": "FORT MYERS",
        "Div": 999,
        "Mat": 1.034,
        "Labor": 0.678,
        "Total": 0.902,
        "Location": "FORT MYERS, FL"
    },
    {
        "Year": "2023Q1",
        "Zip": 340,
        "State": "FL",
        "City": "MIAMI",
        "Div": 999,
        "Mat": 1.048,
        "Labor": 0.71,
        "Total": 0.922,
        "Location": "MIAMI, FL"
    },
    {
        "Year": "2023Q1",
        "Zip": 341,
        "State": "FL",
        "City": "FORT MYERS",
        "Div": 999,
        "Mat": 1.034,
        "Labor": 0.678,
        "Total": 0.902,
        "Location": "FORT MYERS, FL"
    },
    {
        "Year": "2023Q1",
        "Zip": 342,
        "State": "FL",
        "City": "SARASOTA",
        "Div": 999,
        "Mat": 1.042,
        "Labor": 0.652,
        "Total": 0.896,
        "Location": "SARASOTA, FL"
    },
    {
        "Year": "2023Q1",
        "Zip": 344,
        "State": "FL",
        "City": "GAINESVILLE",
        "Div": 999,
        "Mat": 1.048,
        "Labor": 0.67,
        "Total": 0.907,
        "Location": "GAINESVILLE, FL"
    },
    {
        "Year": "2023Q1",
        "Zip": 346,
        "State": "FL",
        "City": "TAMPA",
        "Div": 999,
        "Mat": 1.048,
        "Labor": 0.691,
        "Total": 0.915,
        "Location": "TAMPA, FL"
    },
    {
        "Year": "2023Q1",
        "Zip": 347,
        "State": "FL",
        "City": "ORLANDO",
        "Div": 999,
        "Mat": 1.081,
        "Labor": 0.677,
        "Total": 0.931,
        "Location": "ORLANDO, FL"
    },
    {
        "Year": "2023Q1",
        "Zip": 349,
        "State": "FL",
        "City": "WEST PALM BEACH",
        "Div": 999,
        "Mat": 1.023,
        "Labor": 0.645,
        "Total": 0.882,
        "Location": "WEST PALM BEACH, FL"
    },
    {
        "Year": "2023Q1",
        "Zip": 350,
        "State": "AL",
        "City": "BIRMINGHAM",
        "Div": 999,
        "Mat": 1.045,
        "Labor": 0.712,
        "Total": 0.921,
        "Location": "BIRMINGHAM, AL"
    },
    {
        "Year": "2023Q1",
        "Zip": 351,
        "State": "AL",
        "City": "BIRMINGHAM",
        "Div": 999,
        "Mat": 1.045,
        "Labor": 0.712,
        "Total": 0.921,
        "Location": "BIRMINGHAM, AL"
    },
    {
        "Year": "2023Q1",
        "Zip": 352,
        "State": "AL",
        "City": "BIRMINGHAM",
        "Div": 999,
        "Mat": 1.045,
        "Labor": 0.712,
        "Total": 0.921,
        "Location": "BIRMINGHAM, AL"
    },
    {
        "Year": "2023Q1",
        "Zip": 354,
        "State": "AL",
        "City": "TUSCALOOSA",
        "Div": 999,
        "Mat": 1.032,
        "Labor": 0.697,
        "Total": 0.907,
        "Location": "TUSCALOOSA, AL"
    },
    {
        "Year": "2023Q1",
        "Zip": 355,
        "State": "AL",
        "City": "JASPER",
        "Div": 999,
        "Mat": 1.05,
        "Labor": 0.668,
        "Total": 0.908,
        "Location": "JASPER, AL"
    },
    {
        "Year": "2023Q1",
        "Zip": 356,
        "State": "AL",
        "City": "DECATUR",
        "Div": 999,
        "Mat": 1.035,
        "Labor": 0.658,
        "Total": 0.894,
        "Location": "DECATUR, AL"
    },
    {
        "Year": "2023Q1",
        "Zip": 357,
        "State": "AL",
        "City": "HUNTSVILLE",
        "Div": 999,
        "Mat": 1.031,
        "Labor": 0.715,
        "Total": 0.913,
        "Location": "HUNTSVILLE, AL"
    },
    {
        "Year": "2023Q1",
        "Zip": 358,
        "State": "AL",
        "City": "HUNTSVILLE",
        "Div": 999,
        "Mat": 1.031,
        "Labor": 0.715,
        "Total": 0.913,
        "Location": "HUNTSVILLE, AL"
    },
    {
        "Year": "2023Q1",
        "Zip": 359,
        "State": "AL",
        "City": "GADSDEN",
        "Div": 999,
        "Mat": 1.047,
        "Labor": 0.696,
        "Total": 0.916,
        "Location": "GADSDEN, AL"
    },
    {
        "Year": "2023Q1",
        "Zip": 360,
        "State": "AL",
        "City": "MONTGOMERY",
        "Div": 999,
        "Mat": 1.03,
        "Labor": 0.715,
        "Total": 0.912,
        "Location": "MONTGOMERY, AL"
    },
    {
        "Year": "2023Q1",
        "Zip": 361,
        "State": "AL",
        "City": "MONTGOMERY",
        "Div": 999,
        "Mat": 1.03,
        "Labor": 0.715,
        "Total": 0.912,
        "Location": "MONTGOMERY, AL"
    },
    {
        "Year": "2023Q1",
        "Zip": 362,
        "State": "AL",
        "City": "ANNISTON",
        "Div": 999,
        "Mat": 1.029,
        "Labor": 0.635,
        "Total": 0.882,
        "Location": "ANNISTON, AL"
    },
    {
        "Year": "2023Q1",
        "Zip": 363,
        "State": "AL",
        "City": "DOTHAN",
        "Div": 999,
        "Mat": 1.012,
        "Labor": 0.713,
        "Total": 0.901,
        "Location": "DOTHAN, AL"
    },
    {
        "Year": "2023Q1",
        "Zip": 364,
        "State": "AL",
        "City": "EVERGREEN",
        "Div": 999,
        "Mat": 1.009,
        "Labor": 0.644,
        "Total": 0.873,
        "Location": "EVERGREEN, AL"
    },
    {
        "Year": "2023Q1",
        "Zip": 365,
        "State": "AL",
        "City": "MOBILE",
        "Div": 999,
        "Mat": 1.026,
        "Labor": 0.682,
        "Total": 0.898,
        "Location": "MOBILE, AL"
    },
    {
        "Year": "2023Q1",
        "Zip": 366,
        "State": "AL",
        "City": "MOBILE",
        "Div": 999,
        "Mat": 1.026,
        "Labor": 0.682,
        "Total": 0.898,
        "Location": "MOBILE, AL"
    },
    {
        "Year": "2023Q1",
        "Zip": 367,
        "State": "AL",
        "City": "SELMA",
        "Div": 999,
        "Mat": 1.01,
        "Labor": 0.679,
        "Total": 0.887,
        "Location": "SELMA, AL"
    },
    {
        "Year": "2023Q1",
        "Zip": 368,
        "State": "AL",
        "City": "PHENIX CITY",
        "Div": 999,
        "Mat": 1.016,
        "Labor": 0.689,
        "Total": 0.894,
        "Location": "PHENIX CITY, AL"
    },
    {
        "Year": "2023Q1",
        "Zip": 369,
        "State": "AL",
        "City": "BUTLER",
        "Div": 999,
        "Mat": 1.011,
        "Labor": 0.662,
        "Total": 0.881,
        "Location": "BUTLER, AL"
    },
    {
        "Year": "2023Q1",
        "Zip": 370,
        "State": "TN",
        "City": "NASHVILLE",
        "Div": 999,
        "Mat": 0.978,
        "Labor": 0.743,
        "Total": 0.89,
        "Location": "NASHVILLE, TN"
    },
    {
        "Year": "2023Q1",
        "Zip": 371,
        "State": "TN",
        "City": "NASHVILLE",
        "Div": 999,
        "Mat": 0.978,
        "Labor": 0.743,
        "Total": 0.89,
        "Location": "NASHVILLE, TN"
    },
    {
        "Year": "2023Q1",
        "Zip": 372,
        "State": "TN",
        "City": "NASHVILLE",
        "Div": 999,
        "Mat": 0.978,
        "Labor": 0.743,
        "Total": 0.89,
        "Location": "NASHVILLE, TN"
    },
    {
        "Year": "2023Q1",
        "Zip": 373,
        "State": "TN",
        "City": "CHATTANOOGA",
        "Div": 999,
        "Mat": 0.982,
        "Labor": 0.674,
        "Total": 0.867,
        "Location": "CHATTANOOGA, TN"
    },
    {
        "Year": "2023Q1",
        "Zip": 374,
        "State": "TN",
        "City": "CHATTANOOGA",
        "Div": 999,
        "Mat": 0.982,
        "Labor": 0.674,
        "Total": 0.867,
        "Location": "CHATTANOOGA, TN"
    },
    {
        "Year": "2023Q1",
        "Zip": 375,
        "State": "TN",
        "City": "MEMPHIS",
        "Div": 999,
        "Mat": 0.997,
        "Labor": 0.713,
        "Total": 0.891,
        "Location": "MEMPHIS, TN"
    },
    {
        "Year": "2023Q1",
        "Zip": 376,
        "State": "TN",
        "City": "JOHNSON CITY",
        "Div": 999,
        "Mat": 0.972,
        "Labor": 0.572,
        "Total": 0.823,
        "Location": "JOHNSON CITY, TN"
    },
    {
        "Year": "2023Q1",
        "Zip": 377,
        "State": "TN",
        "City": "KNOXVILLE",
        "Div": 999,
        "Mat": 0.951,
        "Labor": 0.682,
        "Total": 0.851,
        "Location": "KNOXVILLE, TN"
    },
    {
        "Year": "2023Q1",
        "Zip": 378,
        "State": "TN",
        "City": "KNOXVILLE",
        "Div": 999,
        "Mat": 0.951,
        "Labor": 0.682,
        "Total": 0.851,
        "Location": "KNOXVILLE, TN"
    },
    {
        "Year": "2023Q1",
        "Zip": 379,
        "State": "TN",
        "City": "KNOXVILLE",
        "Div": 999,
        "Mat": 0.951,
        "Labor": 0.682,
        "Total": 0.851,
        "Location": "KNOXVILLE, TN"
    },
    {
        "Year": "2023Q1",
        "Zip": 380,
        "State": "TN",
        "City": "MEMPHIS",
        "Div": 999,
        "Mat": 0.997,
        "Labor": 0.713,
        "Total": 0.891,
        "Location": "MEMPHIS, TN"
    },
    {
        "Year": "2023Q1",
        "Zip": 381,
        "State": "TN",
        "City": "MEMPHIS",
        "Div": 999,
        "Mat": 0.997,
        "Labor": 0.713,
        "Total": 0.891,
        "Location": "MEMPHIS, TN"
    },
    {
        "Year": "2023Q1",
        "Zip": 382,
        "State": "TN",
        "City": "MCKENZIE",
        "Div": 999,
        "Mat": 1.003,
        "Labor": 0.52,
        "Total": 0.823,
        "Location": "MCKENZIE, TN"
    },
    {
        "Year": "2023Q1",
        "Zip": 383,
        "State": "TN",
        "City": "JACKSON",
        "Div": 999,
        "Mat": 1.026,
        "Labor": 0.67,
        "Total": 0.893,
        "Location": "JACKSON, TN"
    },
    {
        "Year": "2023Q1",
        "Zip": 384,
        "State": "TN",
        "City": "COLUMBIA",
        "Div": 999,
        "Mat": 0.989,
        "Labor": 0.611,
        "Total": 0.848,
        "Location": "COLUMBIA, TN"
    },
    {
        "Year": "2023Q1",
        "Zip": 385,
        "State": "TN",
        "City": "COOKEVILLE",
        "Div": 999,
        "Mat": 1.002,
        "Labor": 0.533,
        "Total": 0.827,
        "Location": "COOKEVILLE, TN"
    },
    {
        "Year": "2023Q1",
        "Zip": 386,
        "State": "MS",
        "City": "CLARKSDALE",
        "Div": 999,
        "Mat": 1.004,
        "Labor": 0.508,
        "Total": 0.819,
        "Location": "CLARKSDALE, MS"
    },
    {
        "Year": "2023Q1",
        "Zip": 387,
        "State": "MS",
        "City": "GREENVILLE",
        "Div": 999,
        "Mat": 1.038,
        "Labor": 0.63,
        "Total": 0.886,
        "Location": "GREENVILLE, MS"
    },
    {
        "Year": "2023Q1",
        "Zip": 388,
        "State": "MS",
        "City": "TUPELO",
        "Div": 999,
        "Mat": 1.015,
        "Labor": 0.532,
        "Total": 0.835,
        "Location": "TUPELO, MS"
    },
    {
        "Year": "2023Q1",
        "Zip": 389,
        "State": "MS",
        "City": "GREENWOOD",
        "Div": 999,
        "Mat": 1.016,
        "Labor": 0.505,
        "Total": 0.826,
        "Location": "GREENWOOD, MS"
    },
    {
        "Year": "2023Q1",
        "Zip": 390,
        "State": "MS",
        "City": "JACKSON",
        "Div": 999,
        "Mat": 1.02,
        "Labor": 0.68,
        "Total": 0.893,
        "Location": "JACKSON, MS"
    },
    {
        "Year": "2023Q1",
        "Zip": 391,
        "State": "MS",
        "City": "JACKSON",
        "Div": 999,
        "Mat": 1.02,
        "Labor": 0.68,
        "Total": 0.893,
        "Location": "JACKSON, MS"
    },
    {
        "Year": "2023Q1",
        "Zip": 392,
        "State": "MS",
        "City": "JACKSON",
        "Div": 999,
        "Mat": 1.02,
        "Labor": 0.68,
        "Total": 0.893,
        "Location": "JACKSON, MS"
    },
    {
        "Year": "2023Q1",
        "Zip": 393,
        "State": "MS",
        "City": "MERIDIAN",
        "Div": 999,
        "Mat": 0.987,
        "Labor": 0.656,
        "Total": 0.863,
        "Location": "MERIDIAN, MS"
    },
    {
        "Year": "2023Q1",
        "Zip": 394,
        "State": "MS",
        "City": "LAUREL",
        "Div": 999,
        "Mat": 0.994,
        "Labor": 0.533,
        "Total": 0.822,
        "Location": "LAUREL, MS"
    },
    {
        "Year": "2023Q1",
        "Zip": 395,
        "State": "MS",
        "City": "BILOXI",
        "Div": 999,
        "Mat": 1.003,
        "Labor": 0.638,
        "Total": 0.867,
        "Location": "BILOXI, MS"
    },
    {
        "Year": "2023Q1",
        "Zip": 396,
        "State": "MS",
        "City": "MCCOMB",
        "Div": 999,
        "Mat": 0.98,
        "Labor": 0.509,
        "Total": 0.805,
        "Location": "MCCOMB, MS"
    },
    {
        "Year": "2023Q1",
        "Zip": 397,
        "State": "MS",
        "City": "COLUMBUS",
        "Div": 999,
        "Mat": 0.993,
        "Labor": 0.544,
        "Total": 0.825,
        "Location": "COLUMBUS, MS"
    },
    {
        "Year": "2023Q1",
        "Zip": 398,
        "State": "GA",
        "City": "ALBANY",
        "Div": 999,
        "Mat": 1.038,
        "Labor": 0.717,
        "Total": 0.918,
        "Location": "ALBANY, GA"
    },
    {
        "Year": "2023Q1",
        "Zip": 399,
        "State": "GA",
        "City": "ATLANTA",
        "Div": 999,
        "Mat": 1.058,
        "Labor": 0.763,
        "Total": 0.948,
        "Location": "ATLANTA, GA"
    },
    {
        "Year": "2023Q1",
        "Zip": 400,
        "State": "KY",
        "City": "LOUISVILLE",
        "Div": 999,
        "Mat": 1.008,
        "Labor": 0.794,
        "Total": 0.928,
        "Location": "LOUISVILLE, KY"
    },
    {
        "Year": "2023Q1",
        "Zip": 401,
        "State": "KY",
        "City": "LOUISVILLE",
        "Div": 999,
        "Mat": 1.008,
        "Labor": 0.794,
        "Total": 0.928,
        "Location": "LOUISVILLE, KY"
    },
    {
        "Year": "2023Q1",
        "Zip": 402,
        "State": "KY",
        "City": "LOUISVILLE",
        "Div": 999,
        "Mat": 1.008,
        "Labor": 0.794,
        "Total": 0.928,
        "Location": "LOUISVILLE, KY"
    },
    {
        "Year": "2023Q1",
        "Zip": 403,
        "State": "KY",
        "City": "LEXINGTON",
        "Div": 999,
        "Mat": 0.99,
        "Labor": 0.766,
        "Total": 0.906,
        "Location": "LEXINGTON, KY"
    },
    {
        "Year": "2023Q1",
        "Zip": 404,
        "State": "KY",
        "City": "LEXINGTON",
        "Div": 999,
        "Mat": 0.99,
        "Labor": 0.766,
        "Total": 0.906,
        "Location": "LEXINGTON, KY"
    },
    {
        "Year": "2023Q1",
        "Zip": 405,
        "State": "KY",
        "City": "LEXINGTON",
        "Div": 999,
        "Mat": 0.99,
        "Labor": 0.766,
        "Total": 0.906,
        "Location": "LEXINGTON, KY"
    },
    {
        "Year": "2023Q1",
        "Zip": 406,
        "State": "KY",
        "City": "FRANKFORT",
        "Div": 999,
        "Mat": 1.01,
        "Labor": 0.756,
        "Total": 0.915,
        "Location": "FRANKFORT, KY"
    },
    {
        "Year": "2023Q1",
        "Zip": 407,
        "State": "KY",
        "City": "CORBIN",
        "Div": 999,
        "Mat": 0.959,
        "Labor": 0.722,
        "Total": 0.871,
        "Location": "CORBIN, KY"
    },
    {
        "Year": "2023Q1",
        "Zip": 408,
        "State": "KY",
        "City": "CORBIN",
        "Div": 999,
        "Mat": 0.959,
        "Labor": 0.722,
        "Total": 0.871,
        "Location": "CORBIN, KY"
    },
    {
        "Year": "2023Q1",
        "Zip": 409,
        "State": "KY",
        "City": "CORBIN",
        "Div": 999,
        "Mat": 0.959,
        "Labor": 0.722,
        "Total": 0.871,
        "Location": "CORBIN, KY"
    },
    {
        "Year": "2023Q1",
        "Zip": 410,
        "State": "KY",
        "City": "COVINGTON",
        "Div": 999,
        "Mat": 0.96,
        "Labor": 0.76,
        "Total": 0.886,
        "Location": "COVINGTON, KY"
    },
    {
        "Year": "2023Q1",
        "Zip": 411,
        "State": "KY",
        "City": "ASHLAND",
        "Div": 999,
        "Mat": 0.946,
        "Labor": 0.823,
        "Total": 0.9,
        "Location": "ASHLAND, KY"
    },
    {
        "Year": "2023Q1",
        "Zip": 412,
        "State": "KY",
        "City": "ASHLAND",
        "Div": 999,
        "Mat": 0.946,
        "Labor": 0.823,
        "Total": 0.9,
        "Location": "ASHLAND, KY"
    },
    {
        "Year": "2023Q1",
        "Zip": 413,
        "State": "KY",
        "City": "CAMPTON",
        "Div": 999,
        "Mat": 0.961,
        "Labor": 0.734,
        "Total": 0.877,
        "Location": "CAMPTON, KY"
    },
    {
        "Year": "2023Q1",
        "Zip": 414,
        "State": "KY",
        "City": "CAMPTON",
        "Div": 999,
        "Mat": 0.961,
        "Labor": 0.734,
        "Total": 0.877,
        "Location": "CAMPTON, KY"
    },
    {
        "Year": "2023Q1",
        "Zip": 415,
        "State": "KY",
        "City": "PIKEVILLE",
        "Div": 999,
        "Mat": 0.967,
        "Labor": 0.783,
        "Total": 0.899,
        "Location": "PIKEVILLE, KY"
    },
    {
        "Year": "2023Q1",
        "Zip": 416,
        "State": "KY",
        "City": "PIKEVILLE",
        "Div": 999,
        "Mat": 0.967,
        "Labor": 0.783,
        "Total": 0.899,
        "Location": "PIKEVILLE, KY"
    },
    {
        "Year": "2023Q1",
        "Zip": 417,
        "State": "KY",
        "City": "HAZARD",
        "Div": 999,
        "Mat": 0.955,
        "Labor": 0.74,
        "Total": 0.875,
        "Location": "HAZARD, KY"
    },
    {
        "Year": "2023Q1",
        "Zip": 418,
        "State": "KY",
        "City": "HAZARD",
        "Div": 999,
        "Mat": 0.955,
        "Labor": 0.74,
        "Total": 0.875,
        "Location": "HAZARD, KY"
    },
    {
        "Year": "2023Q1",
        "Zip": 420,
        "State": "KY",
        "City": "PADUCAH",
        "Div": 999,
        "Mat": 0.936,
        "Labor": 0.769,
        "Total": 0.874,
        "Location": "PADUCAH, KY"
    },
    {
        "Year": "2023Q1",
        "Zip": 421,
        "State": "KY",
        "City": "BOWLING GREEN",
        "Div": 999,
        "Mat": 0.968,
        "Labor": 0.758,
        "Total": 0.889,
        "Location": "BOWLING GREEN, KY"
    },
    {
        "Year": "2023Q1",
        "Zip": 422,
        "State": "KY",
        "City": "BOWLING GREEN",
        "Div": 999,
        "Mat": 0.968,
        "Labor": 0.758,
        "Total": 0.889,
        "Location": "BOWLING GREEN, KY"
    },
    {
        "Year": "2023Q1",
        "Zip": 423,
        "State": "KY",
        "City": "OWENSBORO",
        "Div": 999,
        "Mat": 0.965,
        "Labor": 0.793,
        "Total": 0.901,
        "Location": "OWENSBORO, KY"
    },
    {
        "Year": "2023Q1",
        "Zip": 424,
        "State": "KY",
        "City": "HENDERSON",
        "Div": 999,
        "Mat": 0.935,
        "Labor": 0.757,
        "Total": 0.869,
        "Location": "HENDERSON, KY"
    },
    {
        "Year": "2023Q1",
        "Zip": 425,
        "State": "KY",
        "City": "SOMERSET",
        "Div": 999,
        "Mat": 0.936,
        "Labor": 0.737,
        "Total": 0.862,
        "Location": "SOMERSET, KY"
    },
    {
        "Year": "2023Q1",
        "Zip": 426,
        "State": "KY",
        "City": "SOMERSET",
        "Div": 999,
        "Mat": 0.936,
        "Labor": 0.737,
        "Total": 0.862,
        "Location": "SOMERSET, KY"
    },
    {
        "Year": "2023Q1",
        "Zip": 427,
        "State": "KY",
        "City": "ELIZABETHTOWN",
        "Div": 999,
        "Mat": 0.931,
        "Labor": 0.706,
        "Total": 0.847,
        "Location": "ELIZABETHTOWN, KY"
    },
    {
        "Year": "2023Q1",
        "Zip": 430,
        "State": "OH",
        "City": "COLUMBUS",
        "Div": 999,
        "Mat": 1.026,
        "Labor": 0.878,
        "Total": 0.971,
        "Location": "COLUMBUS, OH"
    },
    {
        "Year": "2023Q1",
        "Zip": 431,
        "State": "OH",
        "City": "COLUMBUS",
        "Div": 999,
        "Mat": 1.026,
        "Labor": 0.878,
        "Total": 0.971,
        "Location": "COLUMBUS, OH"
    },
    {
        "Year": "2023Q1",
        "Zip": 432,
        "State": "OH",
        "City": "COLUMBUS",
        "Div": 999,
        "Mat": 1.026,
        "Labor": 0.878,
        "Total": 0.971,
        "Location": "COLUMBUS, OH"
    },
    {
        "Year": "2023Q1",
        "Zip": 433,
        "State": "OH",
        "City": "MARION",
        "Div": 999,
        "Mat": 0.976,
        "Labor": 0.858,
        "Total": 0.932,
        "Location": "MARION, OH"
    },
    {
        "Year": "2023Q1",
        "Zip": 434,
        "State": "OH",
        "City": "TOLEDO",
        "Div": 999,
        "Mat": 1.01,
        "Labor": 0.92,
        "Total": 0.976,
        "Location": "TOLEDO, OH"
    },
    {
        "Year": "2023Q1",
        "Zip": 435,
        "State": "OH",
        "City": "TOLEDO",
        "Div": 999,
        "Mat": 1.01,
        "Labor": 0.92,
        "Total": 0.976,
        "Location": "TOLEDO, OH"
    },
    {
        "Year": "2023Q1",
        "Zip": 436,
        "State": "OH",
        "City": "TOLEDO",
        "Div": 999,
        "Mat": 1.01,
        "Labor": 0.92,
        "Total": 0.976,
        "Location": "TOLEDO, OH"
    },
    {
        "Year": "2023Q1",
        "Zip": 437,
        "State": "OH",
        "City": "ZANESVILLE",
        "Div": 999,
        "Mat": 0.981,
        "Labor": 0.848,
        "Total": 0.931,
        "Location": "ZANESVILLE, OH"
    },
    {
        "Year": "2023Q1",
        "Zip": 438,
        "State": "OH",
        "City": "ZANESVILLE",
        "Div": 999,
        "Mat": 0.981,
        "Labor": 0.848,
        "Total": 0.931,
        "Location": "ZANESVILLE, OH"
    },
    {
        "Year": "2023Q1",
        "Zip": 439,
        "State": "OH",
        "City": "STEUBENVILLE",
        "Div": 999,
        "Mat": 0.988,
        "Labor": 0.892,
        "Total": 0.952,
        "Location": "STEUBENVILLE, OH"
    },
    {
        "Year": "2023Q1",
        "Zip": 440,
        "State": "OH",
        "City": "LORAIN",
        "Div": 999,
        "Mat": 1.005,
        "Labor": 0.855,
        "Total": 0.949,
        "Location": "LORAIN, OH"
    },
    {
        "Year": "2023Q1",
        "Zip": 441,
        "State": "OH",
        "City": "CLEVELAND",
        "Div": 999,
        "Mat": 1.026,
        "Labor": 0.938,
        "Total": 0.993,
        "Location": "CLEVELAND, OH"
    },
    {
        "Year": "2023Q1",
        "Zip": 442,
        "State": "OH",
        "City": "AKRON",
        "Div": 999,
        "Mat": 1.012,
        "Labor": 0.886,
        "Total": 0.965,
        "Location": "AKRON, OH"
    },
    {
        "Year": "2023Q1",
        "Zip": 443,
        "State": "OH",
        "City": "AKRON",
        "Div": 999,
        "Mat": 1.012,
        "Labor": 0.886,
        "Total": 0.965,
        "Location": "AKRON, OH"
    },
    {
        "Year": "2023Q1",
        "Zip": 444,
        "State": "OH",
        "City": "YOUNGSTOWN",
        "Div": 999,
        "Mat": 1.007,
        "Labor": 0.835,
        "Total": 0.943,
        "Location": "YOUNGSTOWN, OH"
    },
    {
        "Year": "2023Q1",
        "Zip": 445,
        "State": "OH",
        "City": "YOUNGSTOWN",
        "Div": 999,
        "Mat": 1.007,
        "Labor": 0.835,
        "Total": 0.943,
        "Location": "YOUNGSTOWN, OH"
    },
    {
        "Year": "2023Q1",
        "Zip": 446,
        "State": "OH",
        "City": "CANTON",
        "Div": 999,
        "Mat": 1.008,
        "Labor": 0.817,
        "Total": 0.937,
        "Location": "CANTON, OH"
    },
    {
        "Year": "2023Q1",
        "Zip": 447,
        "State": "OH",
        "City": "CANTON",
        "Div": 999,
        "Mat": 1.008,
        "Labor": 0.817,
        "Total": 0.937,
        "Location": "CANTON, OH"
    },
    {
        "Year": "2023Q1",
        "Zip": 448,
        "State": "OH",
        "City": "MANSFIELD",
        "Div": 999,
        "Mat": 0.982,
        "Labor": 0.842,
        "Total": 0.93,
        "Location": "MANSFIELD, OH"
    },
    {
        "Year": "2023Q1",
        "Zip": 449,
        "State": "OH",
        "City": "MANSFIELD",
        "Div": 999,
        "Mat": 0.982,
        "Labor": 0.842,
        "Total": 0.93,
        "Location": "MANSFIELD, OH"
    },
    {
        "Year": "2023Q1",
        "Zip": 450,
        "State": "OH",
        "City": "HAMILTON",
        "Div": 999,
        "Mat": 0.999,
        "Labor": 0.806,
        "Total": 0.927,
        "Location": "HAMILTON, OH"
    },
    {
        "Year": "2023Q1",
        "Zip": 451,
        "State": "OH",
        "City": "CINCINNATI",
        "Div": 999,
        "Mat": 1.024,
        "Labor": 0.806,
        "Total": 0.943,
        "Location": "CINCINNATI, OH"
    },
    {
        "Year": "2023Q1",
        "Zip": 452,
        "State": "OH",
        "City": "CINCINNATI",
        "Div": 999,
        "Mat": 1.024,
        "Labor": 0.806,
        "Total": 0.943,
        "Location": "CINCINNATI, OH"
    },
    {
        "Year": "2023Q1",
        "Zip": 453,
        "State": "OH",
        "City": "DAYTON",
        "Div": 999,
        "Mat": 0.996,
        "Labor": 0.804,
        "Total": 0.924,
        "Location": "DAYTON, OH"
    },
    {
        "Year": "2023Q1",
        "Zip": 454,
        "State": "OH",
        "City": "DAYTON",
        "Div": 999,
        "Mat": 0.996,
        "Labor": 0.804,
        "Total": 0.924,
        "Location": "DAYTON, OH"
    },
    {
        "Year": "2023Q1",
        "Zip": 455,
        "State": "OH",
        "City": "SPRINGFIELD",
        "Div": 999,
        "Mat": 0.996,
        "Labor": 0.808,
        "Total": 0.926,
        "Location": "SPRINGFIELD, OH"
    },
    {
        "Year": "2023Q1",
        "Zip": 456,
        "State": "OH",
        "City": "CHILLICOTHE",
        "Div": 999,
        "Mat": 0.981,
        "Labor": 0.827,
        "Total": 0.924,
        "Location": "CHILLICOTHE, OH"
    },
    {
        "Year": "2023Q1",
        "Zip": 457,
        "State": "OH",
        "City": "ATHENS",
        "Div": 999,
        "Mat": 1.014,
        "Labor": 0.85,
        "Total": 0.953,
        "Location": "ATHENS, OH"
    },
    {
        "Year": "2023Q1",
        "Zip": 458,
        "State": "OH",
        "City": "LIMA",
        "Div": 999,
        "Mat": 1.014,
        "Labor": 0.812,
        "Total": 0.939,
        "Location": "LIMA, OH"
    },
    {
        "Year": "2023Q1",
        "Zip": 460,
        "State": "IN",
        "City": "ANDERSON",
        "Div": 999,
        "Mat": 0.968,
        "Labor": 0.797,
        "Total": 0.904,
        "Location": "ANDERSON, IN"
    },
    {
        "Year": "2023Q1",
        "Zip": 461,
        "State": "IN",
        "City": "INDIANAPOLIS",
        "Div": 999,
        "Mat": 0.998,
        "Labor": 0.862,
        "Total": 0.947,
        "Location": "INDIANAPOLIS, IN"
    },
    {
        "Year": "2023Q1",
        "Zip": 462,
        "State": "IN",
        "City": "INDIANAPOLIS",
        "Div": 999,
        "Mat": 0.998,
        "Labor": 0.862,
        "Total": 0.947,
        "Location": "INDIANAPOLIS, IN"
    },
    {
        "Year": "2023Q1",
        "Zip": 463,
        "State": "IN",
        "City": "GARY",
        "Div": 999,
        "Mat": 0.983,
        "Labor": 1.025,
        "Total": 0.999,
        "Location": "GARY, IN"
    },
    {
        "Year": "2023Q1",
        "Zip": 464,
        "State": "IN",
        "City": "GARY",
        "Div": 999,
        "Mat": 0.983,
        "Labor": 1.025,
        "Total": 0.999,
        "Location": "GARY, IN"
    },
    {
        "Year": "2023Q1",
        "Zip": 465,
        "State": "IN",
        "City": "SOUTH BEND",
        "Div": 999,
        "Mat": 1.011,
        "Labor": 0.843,
        "Total": 0.948,
        "Location": "SOUTH BEND, IN"
    },
    {
        "Year": "2023Q1",
        "Zip": 466,
        "State": "IN",
        "City": "SOUTH BEND",
        "Div": 999,
        "Mat": 1.011,
        "Labor": 0.843,
        "Total": 0.948,
        "Location": "SOUTH BEND, IN"
    },
    {
        "Year": "2023Q1",
        "Zip": 467,
        "State": "IN",
        "City": "FORT WAYNE",
        "Div": 999,
        "Mat": 0.975,
        "Labor": 0.784,
        "Total": 0.904,
        "Location": "FORT WAYNE, IN"
    },
    {
        "Year": "2023Q1",
        "Zip": 468,
        "State": "IN",
        "City": "FORT WAYNE",
        "Div": 999,
        "Mat": 0.975,
        "Labor": 0.784,
        "Total": 0.904,
        "Location": "FORT WAYNE, IN"
    },
    {
        "Year": "2023Q1",
        "Zip": 469,
        "State": "IN",
        "City": "KOKOMO",
        "Div": 999,
        "Mat": 0.94,
        "Labor": 0.79,
        "Total": 0.884,
        "Location": "KOKOMO, IN"
    },
    {
        "Year": "2023Q1",
        "Zip": 470,
        "State": "IN",
        "City": "LAWRENCEBURG",
        "Div": 999,
        "Mat": 0.944,
        "Labor": 0.767,
        "Total": 0.878,
        "Location": "LAWRENCEBURG, IN"
    },
    {
        "Year": "2023Q1",
        "Zip": 471,
        "State": "IN",
        "City": "NEW ALBANY",
        "Div": 999,
        "Mat": 0.954,
        "Labor": 0.744,
        "Total": 0.876,
        "Location": "NEW ALBANY, IN"
    },
    {
        "Year": "2023Q1",
        "Zip": 472,
        "State": "IN",
        "City": "COLUMBUS",
        "Div": 999,
        "Mat": 0.979,
        "Labor": 0.782,
        "Total": 0.905,
        "Location": "COLUMBUS, IN"
    },
    {
        "Year": "2023Q1",
        "Zip": 473,
        "State": "IN",
        "City": "MUNCIE",
        "Div": 999,
        "Mat": 0.986,
        "Labor": 0.784,
        "Total": 0.911,
        "Location": "MUNCIE, IN"
    },
    {
        "Year": "2023Q1",
        "Zip": 474,
        "State": "IN",
        "City": "BLOOMINGTON",
        "Div": 999,
        "Mat": 1,
        "Labor": 0.797,
        "Total": 0.924,
        "Location": "BLOOMINGTON, IN"
    },
    {
        "Year": "2023Q1",
        "Zip": 475,
        "State": "IN",
        "City": "WASHINGTON",
        "Div": 999,
        "Mat": 0.957,
        "Labor": 0.817,
        "Total": 0.905,
        "Location": "WASHINGTON, IN"
    },
    {
        "Year": "2023Q1",
        "Zip": 476,
        "State": "IN",
        "City": "EVANSVILLE",
        "Div": 999,
        "Mat": 0.979,
        "Labor": 0.841,
        "Total": 0.927,
        "Location": "EVANSVILLE, IN"
    },
    {
        "Year": "2023Q1",
        "Zip": 477,
        "State": "IN",
        "City": "EVANSVILLE",
        "Div": 999,
        "Mat": 0.979,
        "Labor": 0.841,
        "Total": 0.927,
        "Location": "EVANSVILLE, IN"
    },
    {
        "Year": "2023Q1",
        "Zip": 478,
        "State": "IN",
        "City": "TERRE HAUTE",
        "Div": 999,
        "Mat": 0.986,
        "Labor": 0.83,
        "Total": 0.928,
        "Location": "TERRE HAUTE, IN"
    },
    {
        "Year": "2023Q1",
        "Zip": 479,
        "State": "IN",
        "City": "LAFAYETTE",
        "Div": 999,
        "Mat": 0.973,
        "Labor": 0.802,
        "Total": 0.909,
        "Location": "LAFAYETTE, IN"
    },
    {
        "Year": "2023Q1",
        "Zip": 480,
        "State": "MI",
        "City": "ROYAL OAK",
        "Div": 999,
        "Mat": 0.932,
        "Labor": 0.927,
        "Total": 0.93,
        "Location": "ROYAL OAK, MI"
    },
    {
        "Year": "2023Q1",
        "Zip": 481,
        "State": "MI",
        "City": "ANN ARBOR",
        "Div": 999,
        "Mat": 0.96,
        "Labor": 0.97,
        "Total": 0.964,
        "Location": "ANN ARBOR, MI"
    },
    {
        "Year": "2023Q1",
        "Zip": 482,
        "State": "MI",
        "City": "DETROIT",
        "Div": 999,
        "Mat": 0.974,
        "Labor": 1.018,
        "Total": 0.99,
        "Location": "DETROIT, MI"
    },
    {
        "Year": "2023Q1",
        "Zip": 483,
        "State": "MI",
        "City": "ROYAL OAK",
        "Div": 999,
        "Mat": 0.932,
        "Labor": 0.927,
        "Total": 0.93,
        "Location": "ROYAL OAK, MI"
    },
    {
        "Year": "2023Q1",
        "Zip": 484,
        "State": "MI",
        "City": "FLINT",
        "Div": 999,
        "Mat": 0.96,
        "Labor": 0.89,
        "Total": 0.934,
        "Location": "FLINT, MI"
    },
    {
        "Year": "2023Q1",
        "Zip": 485,
        "State": "MI",
        "City": "FLINT",
        "Div": 999,
        "Mat": 0.96,
        "Labor": 0.89,
        "Total": 0.934,
        "Location": "FLINT, MI"
    },
    {
        "Year": "2023Q1",
        "Zip": 486,
        "State": "MI",
        "City": "SAGINAW",
        "Div": 999,
        "Mat": 0.956,
        "Labor": 0.846,
        "Total": 0.915,
        "Location": "SAGINAW, MI"
    },
    {
        "Year": "2023Q1",
        "Zip": 487,
        "State": "MI",
        "City": "BAY CITY",
        "Div": 999,
        "Mat": 0.96,
        "Labor": 0.826,
        "Total": 0.91,
        "Location": "BAY CITY, MI"
    },
    {
        "Year": "2023Q1",
        "Zip": 488,
        "State": "MI",
        "City": "LANSING",
        "Div": 999,
        "Mat": 0.996,
        "Labor": 0.878,
        "Total": 0.952,
        "Location": "LANSING, MI"
    },
    {
        "Year": "2023Q1",
        "Zip": 489,
        "State": "MI",
        "City": "LANSING",
        "Div": 999,
        "Mat": 0.996,
        "Labor": 0.878,
        "Total": 0.952,
        "Location": "LANSING, MI"
    },
    {
        "Year": "2023Q1",
        "Zip": 490,
        "State": "MI",
        "City": "BATTLE CREEK",
        "Div": 999,
        "Mat": 1.004,
        "Labor": 0.778,
        "Total": 0.92,
        "Location": "BATTLE CREEK, MI"
    },
    {
        "Year": "2023Q1",
        "Zip": 491,
        "State": "MI",
        "City": "KALAMAZOO",
        "Div": 999,
        "Mat": 1.005,
        "Labor": 0.802,
        "Total": 0.929,
        "Location": "KALAMAZOO, MI"
    },
    {
        "Year": "2023Q1",
        "Zip": 492,
        "State": "MI",
        "City": "JACKSON",
        "Div": 999,
        "Mat": 0.977,
        "Labor": 0.864,
        "Total": 0.935,
        "Location": "JACKSON, MI"
    },
    {
        "Year": "2023Q1",
        "Zip": 493,
        "State": "MI",
        "City": "GRAND RAPIDS",
        "Div": 999,
        "Mat": 1.028,
        "Labor": 0.808,
        "Total": 0.946,
        "Location": "GRAND RAPIDS, MI"
    },
    {
        "Year": "2023Q1",
        "Zip": 494,
        "State": "MI",
        "City": "MUSKEGON",
        "Div": 999,
        "Mat": 0.989,
        "Labor": 0.804,
        "Total": 0.92,
        "Location": "MUSKEGON, MI"
    },
    {
        "Year": "2023Q1",
        "Zip": 495,
        "State": "MI",
        "City": "GRAND RAPIDS",
        "Div": 999,
        "Mat": 1.028,
        "Labor": 0.808,
        "Total": 0.946,
        "Location": "GRAND RAPIDS, MI"
    },
    {
        "Year": "2023Q1",
        "Zip": 496,
        "State": "MI",
        "City": "TRAVERSE CITY",
        "Div": 999,
        "Mat": 0.971,
        "Labor": 0.741,
        "Total": 0.885,
        "Location": "TRAVERSE CITY, MI"
    },
    {
        "Year": "2023Q1",
        "Zip": 497,
        "State": "MI",
        "City": "GAYLORD",
        "Div": 999,
        "Mat": 0.987,
        "Labor": 0.764,
        "Total": 0.904,
        "Location": "GAYLORD, MI"
    },
    {
        "Year": "2023Q1",
        "Zip": 498,
        "State": "MI",
        "City": "IRON MOUNTAIN",
        "Div": 999,
        "Mat": 1.008,
        "Labor": 0.775,
        "Total": 0.921,
        "Location": "IRON MOUNTAIN, MI"
    },
    {
        "Year": "2023Q1",
        "Zip": 499,
        "State": "MI",
        "City": "IRON MOUNTAIN",
        "Div": 999,
        "Mat": 1.008,
        "Labor": 0.775,
        "Total": 0.921,
        "Location": "IRON MOUNTAIN, MI"
    },
    {
        "Year": "2023Q1",
        "Zip": 500,
        "State": "IA",
        "City": "DES MOINES",
        "Div": 999,
        "Mat": 1.017,
        "Labor": 0.921,
        "Total": 0.981,
        "Location": "DES MOINES, IA"
    },
    {
        "Year": "2023Q1",
        "Zip": 501,
        "State": "IA",
        "City": "DES MOINES",
        "Div": 999,
        "Mat": 1.017,
        "Labor": 0.921,
        "Total": 0.981,
        "Location": "DES MOINES, IA"
    },
    {
        "Year": "2023Q1",
        "Zip": 502,
        "State": "IA",
        "City": "DES MOINES",
        "Div": 999,
        "Mat": 1.017,
        "Labor": 0.921,
        "Total": 0.981,
        "Location": "DES MOINES, IA"
    },
    {
        "Year": "2023Q1",
        "Zip": 503,
        "State": "IA",
        "City": "DES MOINES",
        "Div": 999,
        "Mat": 1.017,
        "Labor": 0.921,
        "Total": 0.981,
        "Location": "DES MOINES, IA"
    },
    {
        "Year": "2023Q1",
        "Zip": 504,
        "State": "IA",
        "City": "MASON CITY",
        "Div": 999,
        "Mat": 0.988,
        "Labor": 0.678,
        "Total": 0.872,
        "Location": "MASON CITY, IA"
    },
    {
        "Year": "2023Q1",
        "Zip": 505,
        "State": "IA",
        "City": "FORT DODGE",
        "Div": 999,
        "Mat": 0.987,
        "Labor": 0.675,
        "Total": 0.871,
        "Location": "FORT DODGE, IA"
    },
    {
        "Year": "2023Q1",
        "Zip": 506,
        "State": "IA",
        "City": "WATERLOO",
        "Div": 999,
        "Mat": 1.011,
        "Labor": 0.77,
        "Total": 0.921,
        "Location": "WATERLOO, IA"
    },
    {
        "Year": "2023Q1",
        "Zip": 507,
        "State": "IA",
        "City": "WATERLOO",
        "Div": 999,
        "Mat": 1.011,
        "Labor": 0.77,
        "Total": 0.921,
        "Location": "WATERLOO, IA"
    },
    {
        "Year": "2023Q1",
        "Zip": 508,
        "State": "IA",
        "City": "CRESTON",
        "Div": 999,
        "Mat": 0.99,
        "Labor": 0.771,
        "Total": 0.908,
        "Location": "CRESTON, IA"
    },
    {
        "Year": "2023Q1",
        "Zip": 509,
        "State": "IA",
        "City": "DES MOINES",
        "Div": 999,
        "Mat": 1.017,
        "Labor": 0.921,
        "Total": 0.981,
        "Location": "DES MOINES, IA"
    },
    {
        "Year": "2023Q1",
        "Zip": 510,
        "State": "IA",
        "City": "SIOUX CITY",
        "Div": 999,
        "Mat": 0.998,
        "Labor": 0.77,
        "Total": 0.913,
        "Location": "SIOUX CITY, IA"
    },
    {
        "Year": "2023Q1",
        "Zip": 511,
        "State": "IA",
        "City": "SIOUX CITY",
        "Div": 999,
        "Mat": 0.998,
        "Labor": 0.77,
        "Total": 0.913,
        "Location": "SIOUX CITY, IA"
    },
    {
        "Year": "2023Q1",
        "Zip": 512,
        "State": "IA",
        "City": "SIBLEY",
        "Div": 999,
        "Mat": 0.982,
        "Labor": 0.575,
        "Total": 0.83,
        "Location": "SIBLEY, IA"
    },
    {
        "Year": "2023Q1",
        "Zip": 513,
        "State": "IA",
        "City": "SPENCER",
        "Div": 999,
        "Mat": 0.993,
        "Labor": 0.573,
        "Total": 0.837,
        "Location": "SPENCER, IA"
    },
    {
        "Year": "2023Q1",
        "Zip": 514,
        "State": "IA",
        "City": "CARROLL",
        "Div": 999,
        "Mat": 0.97,
        "Labor": 0.755,
        "Total": 0.89,
        "Location": "CARROLL, IA"
    },
    {
        "Year": "2023Q1",
        "Zip": 515,
        "State": "IA",
        "City": "COUNCIL BLUFFS",
        "Div": 999,
        "Mat": 1.005,
        "Labor": 0.802,
        "Total": 0.929,
        "Location": "COUNCIL BLUFFS, IA"
    },
    {
        "Year": "2023Q1",
        "Zip": 516,
        "State": "IA",
        "City": "SHENANDOAH",
        "Div": 999,
        "Mat": 0.975,
        "Labor": 0.751,
        "Total": 0.892,
        "Location": "SHENANDOAH, IA"
    },
    {
        "Year": "2023Q1",
        "Zip": 520,
        "State": "IA",
        "City": "DUBUQUE",
        "Div": 999,
        "Mat": 0.992,
        "Labor": 0.79,
        "Total": 0.917,
        "Location": "DUBUQUE, IA"
    },
    {
        "Year": "2023Q1",
        "Zip": 521,
        "State": "IA",
        "City": "DECORAH",
        "Div": 999,
        "Mat": 0.98,
        "Labor": 0.677,
        "Total": 0.867,
        "Location": "DECORAH, IA"
    },
    {
        "Year": "2023Q1",
        "Zip": 522,
        "State": "IA",
        "City": "CEDAR RAPIDS",
        "Div": 999,
        "Mat": 1.003,
        "Labor": 0.834,
        "Total": 0.94,
        "Location": "CEDAR RAPIDS, IA"
    },
    {
        "Year": "2023Q1",
        "Zip": 523,
        "State": "IA",
        "City": "CEDAR RAPIDS",
        "Div": 999,
        "Mat": 1.003,
        "Labor": 0.834,
        "Total": 0.94,
        "Location": "CEDAR RAPIDS, IA"
    },
    {
        "Year": "2023Q1",
        "Zip": 524,
        "State": "IA",
        "City": "CEDAR RAPIDS",
        "Div": 999,
        "Mat": 1.003,
        "Labor": 0.834,
        "Total": 0.94,
        "Location": "CEDAR RAPIDS, IA"
    },
    {
        "Year": "2023Q1",
        "Zip": 525,
        "State": "IA",
        "City": "OTTUMWA",
        "Div": 999,
        "Mat": 0.977,
        "Labor": 0.722,
        "Total": 0.882,
        "Location": "OTTUMWA, IA"
    },
    {
        "Year": "2023Q1",
        "Zip": 526,
        "State": "IA",
        "City": "BURLINGTON",
        "Div": 999,
        "Mat": 0.971,
        "Labor": 0.773,
        "Total": 0.897,
        "Location": "BURLINGTON, IA"
    },
    {
        "Year": "2023Q1",
        "Zip": 527,
        "State": "IA",
        "City": "DAVENPORT",
        "Div": 999,
        "Mat": 0.992,
        "Labor": 0.922,
        "Total": 0.966,
        "Location": "DAVENPORT, IA"
    },
    {
        "Year": "2023Q1",
        "Zip": 528,
        "State": "IA",
        "City": "DAVENPORT",
        "Div": 999,
        "Mat": 0.992,
        "Labor": 0.922,
        "Total": 0.966,
        "Location": "DAVENPORT, IA"
    },
    {
        "Year": "2023Q1",
        "Zip": 530,
        "State": "WI",
        "City": "MILWAUKEE",
        "Div": 999,
        "Mat": 1.021,
        "Labor": 1.113,
        "Total": 1.055,
        "Location": "MILWAUKEE, WI"
    },
    {
        "Year": "2023Q1",
        "Zip": 531,
        "State": "WI",
        "City": "KENOSHA",
        "Div": 999,
        "Mat": 1.014,
        "Labor": 1.036,
        "Total": 1.022,
        "Location": "KENOSHA, WI"
    },
    {
        "Year": "2023Q1",
        "Zip": 532,
        "State": "WI",
        "City": "MILWAUKEE",
        "Div": 999,
        "Mat": 1.021,
        "Labor": 1.113,
        "Total": 1.055,
        "Location": "MILWAUKEE, WI"
    },
    {
        "Year": "2023Q1",
        "Zip": 534,
        "State": "WI",
        "City": "RACINE",
        "Div": 999,
        "Mat": 1.007,
        "Labor": 1.035,
        "Total": 1.017,
        "Location": "RACINE, WI"
    },
    {
        "Year": "2023Q1",
        "Zip": 535,
        "State": "WI",
        "City": "BELOIT",
        "Div": 999,
        "Mat": 1.007,
        "Labor": 0.943,
        "Total": 0.983,
        "Location": "BELOIT, WI"
    },
    {
        "Year": "2023Q1",
        "Zip": 537,
        "State": "WI",
        "City": "MADISON",
        "Div": 999,
        "Mat": 1.023,
        "Labor": 1.015,
        "Total": 1.02,
        "Location": "MADISON, WI"
    },
    {
        "Year": "2023Q1",
        "Zip": 538,
        "State": "WI",
        "City": "LANCASTER",
        "Div": 999,
        "Mat": 0.976,
        "Labor": 0.892,
        "Total": 0.945,
        "Location": "LANCASTER, WI"
    },
    {
        "Year": "2023Q1",
        "Zip": 539,
        "State": "WI",
        "City": "PORTAGE",
        "Div": 999,
        "Mat": 0.963,
        "Labor": 0.926,
        "Total": 0.949,
        "Location": "PORTAGE, WI"
    },
    {
        "Year": "2023Q1",
        "Zip": 540,
        "State": "WI",
        "City": "NEW RICHMOND",
        "Div": 999,
        "Mat": 0.964,
        "Labor": 0.882,
        "Total": 0.934,
        "Location": "NEW RICHMOND, WI"
    },
    {
        "Year": "2023Q1",
        "Zip": 541,
        "State": "WI",
        "City": "GREEN BAY",
        "Div": 999,
        "Mat": 1.013,
        "Labor": 1.015,
        "Total": 1.014,
        "Location": "GREEN BAY, WI"
    },
    {
        "Year": "2023Q1",
        "Zip": 542,
        "State": "WI",
        "City": "GREEN BAY",
        "Div": 999,
        "Mat": 1.013,
        "Labor": 1.015,
        "Total": 1.014,
        "Location": "GREEN BAY, WI"
    },
    {
        "Year": "2023Q1",
        "Zip": 543,
        "State": "WI",
        "City": "GREEN BAY",
        "Div": 999,
        "Mat": 1.013,
        "Labor": 1.015,
        "Total": 1.014,
        "Location": "GREEN BAY, WI"
    },
    {
        "Year": "2023Q1",
        "Zip": 544,
        "State": "WI",
        "City": "WAUSAU",
        "Div": 999,
        "Mat": 0.959,
        "Labor": 0.882,
        "Total": 0.93,
        "Location": "WAUSAU, WI"
    },
    {
        "Year": "2023Q1",
        "Zip": 545,
        "State": "WI",
        "City": "RHINELANDER",
        "Div": 999,
        "Mat": 0.99,
        "Labor": 0.867,
        "Total": 0.944,
        "Location": "RHINELANDER, WI"
    },
    {
        "Year": "2023Q1",
        "Zip": 546,
        "State": "WI",
        "City": "LA CROSSE",
        "Div": 999,
        "Mat": 0.971,
        "Labor": 0.965,
        "Total": 0.969,
        "Location": "LA CROSSE, WI"
    },
    {
        "Year": "2023Q1",
        "Zip": 547,
        "State": "WI",
        "City": "EAU CLAIRE",
        "Div": 999,
        "Mat": 0.986,
        "Labor": 0.964,
        "Total": 0.978,
        "Location": "EAU CLAIRE, WI"
    },
    {
        "Year": "2023Q1",
        "Zip": 548,
        "State": "WI",
        "City": "SUPERIOR",
        "Div": 999,
        "Mat": 0.962,
        "Labor": 0.906,
        "Total": 0.942,
        "Location": "SUPERIOR, WI"
    },
    {
        "Year": "2023Q1",
        "Zip": 549,
        "State": "WI",
        "City": "OSHKOSH",
        "Div": 999,
        "Mat": 0.966,
        "Labor": 0.887,
        "Total": 0.936,
        "Location": "OSHKOSH, WI"
    },
    {
        "Year": "2023Q1",
        "Zip": 550,
        "State": "MN",
        "City": "SAINT PAUL",
        "Div": 999,
        "Mat": 1.022,
        "Labor": 1.189,
        "Total": 1.084,
        "Location": "SAINT PAUL, MN"
    },
    {
        "Year": "2023Q1",
        "Zip": 551,
        "State": "MN",
        "City": "SAINT PAUL",
        "Div": 999,
        "Mat": 1.022,
        "Labor": 1.189,
        "Total": 1.084,
        "Location": "SAINT PAUL, MN"
    },
    {
        "Year": "2023Q1",
        "Zip": 553,
        "State": "MN",
        "City": "MINNEAPOLIS",
        "Div": 999,
        "Mat": 1.013,
        "Labor": 1.193,
        "Total": 1.08,
        "Location": "MINNEAPOLIS, MN"
    },
    {
        "Year": "2023Q1",
        "Zip": 554,
        "State": "MN",
        "City": "MINNEAPOLIS",
        "Div": 999,
        "Mat": 1.013,
        "Labor": 1.193,
        "Total": 1.08,
        "Location": "MINNEAPOLIS, MN"
    },
    {
        "Year": "2023Q1",
        "Zip": 555,
        "State": "MN",
        "City": "MINNEAPOLIS",
        "Div": 999,
        "Mat": 1.013,
        "Labor": 1.193,
        "Total": 1.08,
        "Location": "MINNEAPOLIS, MN"
    },
    {
        "Year": "2023Q1",
        "Zip": 556,
        "State": "MN",
        "City": "DULUTH",
        "Div": 999,
        "Mat": 0.998,
        "Labor": 1.067,
        "Total": 1.024,
        "Location": "DULUTH, MN"
    },
    {
        "Year": "2023Q1",
        "Zip": 557,
        "State": "MN",
        "City": "DULUTH",
        "Div": 999,
        "Mat": 0.998,
        "Labor": 1.067,
        "Total": 1.024,
        "Location": "DULUTH, MN"
    },
    {
        "Year": "2023Q1",
        "Zip": 558,
        "State": "MN",
        "City": "DULUTH",
        "Div": 999,
        "Mat": 0.998,
        "Labor": 1.067,
        "Total": 1.024,
        "Location": "DULUTH, MN"
    },
    {
        "Year": "2023Q1",
        "Zip": 559,
        "State": "MN",
        "City": "ROCHESTER",
        "Div": 999,
        "Mat": 0.992,
        "Labor": 1.051,
        "Total": 1.014,
        "Location": "ROCHESTER, MN"
    },
    {
        "Year": "2023Q1",
        "Zip": 560,
        "State": "MN",
        "City": "MANKATO",
        "Div": 999,
        "Mat": 0.97,
        "Labor": 1.024,
        "Total": 0.99,
        "Location": "MANKATO, MN"
    },
    {
        "Year": "2023Q1",
        "Zip": 561,
        "State": "MN",
        "City": "WINDOM",
        "Div": 999,
        "Mat": 0.958,
        "Labor": 0.895,
        "Total": 0.934,
        "Location": "WINDOM, MN"
    },
    {
        "Year": "2023Q1",
        "Zip": 562,
        "State": "MN",
        "City": "WILLMAR",
        "Div": 999,
        "Mat": 0.956,
        "Labor": 1.067,
        "Total": 0.997,
        "Location": "WILLMAR, MN"
    },
    {
        "Year": "2023Q1",
        "Zip": 563,
        "State": "MN",
        "City": "ST. CLOUD",
        "Div": 999,
        "Mat": 0.973,
        "Labor": 1.157,
        "Total": 1.042,
        "Location": "ST. CLOUD, MN"
    },
    {
        "Year": "2023Q1",
        "Zip": 564,
        "State": "MN",
        "City": "BRAINERD",
        "Div": 999,
        "Mat": 0.972,
        "Labor": 1.009,
        "Total": 0.986,
        "Location": "BRAINERD, MN"
    },
    {
        "Year": "2023Q1",
        "Zip": 565,
        "State": "MN",
        "City": "DETROIT LAKES",
        "Div": 999,
        "Mat": 0.989,
        "Labor": 0.906,
        "Total": 0.958,
        "Location": "DETROIT LAKES, MN"
    },
    {
        "Year": "2023Q1",
        "Zip": 566,
        "State": "MN",
        "City": "BEMIDJI",
        "Div": 999,
        "Mat": 0.981,
        "Labor": 0.964,
        "Total": 0.974,
        "Location": "BEMIDJI, MN"
    },
    {
        "Year": "2023Q1",
        "Zip": 567,
        "State": "MN",
        "City": "THIEF RIVER FALLS",
        "Div": 999,
        "Mat": 0.978,
        "Labor": 0.931,
        "Total": 0.961,
        "Location": "THIEF RIVER FALLS, MN"
    },
    {
        "Year": "2023Q1",
        "Zip": 570,
        "State": "SD",
        "City": "SIOUX FALLS",
        "Div": 999,
        "Mat": 1.027,
        "Labor": 0.828,
        "Total": 0.953,
        "Location": "SIOUX FALLS, SD"
    },
    {
        "Year": "2023Q1",
        "Zip": 571,
        "State": "SD",
        "City": "SIOUX FALLS",
        "Div": 999,
        "Mat": 1.027,
        "Labor": 0.828,
        "Total": 0.953,
        "Location": "SIOUX FALLS, SD"
    },
    {
        "Year": "2023Q1",
        "Zip": 572,
        "State": "SD",
        "City": "WATERTOWN",
        "Div": 999,
        "Mat": 0.992,
        "Labor": 0.663,
        "Total": 0.869,
        "Location": "WATERTOWN, SD"
    },
    {
        "Year": "2023Q1",
        "Zip": 573,
        "State": "SD",
        "City": "MITCHELL",
        "Div": 999,
        "Mat": 0.981,
        "Labor": 0.602,
        "Total": 0.84,
        "Location": "MITCHELL, SD"
    },
    {
        "Year": "2023Q1",
        "Zip": 574,
        "State": "SD",
        "City": "ABERDEEN",
        "Div": 999,
        "Mat": 1.01,
        "Labor": 0.738,
        "Total": 0.908,
        "Location": "ABERDEEN, SD"
    },
    {
        "Year": "2023Q1",
        "Zip": 575,
        "State": "SD",
        "City": "PIERRE",
        "Div": 999,
        "Mat": 1.032,
        "Labor": 0.753,
        "Total": 0.928,
        "Location": "PIERRE, SD"
    },
    {
        "Year": "2023Q1",
        "Zip": 576,
        "State": "SD",
        "City": "MOBRIDGE",
        "Div": 999,
        "Mat": 0.987,
        "Labor": 0.613,
        "Total": 0.847,
        "Location": "MOBRIDGE, SD"
    },
    {
        "Year": "2023Q1",
        "Zip": 577,
        "State": "SD",
        "City": "RAPID CITY",
        "Div": 999,
        "Mat": 1.011,
        "Labor": 0.667,
        "Total": 0.883,
        "Location": "RAPID CITY, SD"
    },
    {
        "Year": "2023Q1",
        "Zip": 580,
        "State": "ND",
        "City": "FARGO",
        "Div": 999,
        "Mat": 1.017,
        "Labor": 0.763,
        "Total": 0.922,
        "Location": "FARGO, ND"
    },
    {
        "Year": "2023Q1",
        "Zip": 581,
        "State": "ND",
        "City": "FARGO",
        "Div": 999,
        "Mat": 1.017,
        "Labor": 0.763,
        "Total": 0.922,
        "Location": "FARGO, ND"
    },
    {
        "Year": "2023Q1",
        "Zip": 582,
        "State": "ND",
        "City": "GRAND FORKS",
        "Div": 999,
        "Mat": 1.01,
        "Labor": 0.781,
        "Total": 0.924,
        "Location": "GRAND FORKS, ND"
    },
    {
        "Year": "2023Q1",
        "Zip": 583,
        "State": "ND",
        "City": "DEVILS LAKE",
        "Div": 999,
        "Mat": 1.003,
        "Labor": 0.75,
        "Total": 0.909,
        "Location": "DEVILS LAKE, ND"
    },
    {
        "Year": "2023Q1",
        "Zip": 584,
        "State": "ND",
        "City": "JAMESTOWN",
        "Div": 999,
        "Mat": 1.005,
        "Labor": 0.739,
        "Total": 0.905,
        "Location": "JAMESTOWN, ND"
    },
    {
        "Year": "2023Q1",
        "Zip": 585,
        "State": "ND",
        "City": "BISMARCK",
        "Div": 999,
        "Mat": 1.033,
        "Labor": 0.863,
        "Total": 0.969,
        "Location": "BISMARCK, ND"
    },
    {
        "Year": "2023Q1",
        "Zip": 586,
        "State": "ND",
        "City": "DICKINSON",
        "Div": 999,
        "Mat": 1.006,
        "Labor": 0.736,
        "Total": 0.906,
        "Location": "DICKINSON, ND"
    },
    {
        "Year": "2023Q1",
        "Zip": 587,
        "State": "ND",
        "City": "MINOT",
        "Div": 999,
        "Mat": 1.009,
        "Labor": 0.738,
        "Total": 0.908,
        "Location": "MINOT, ND"
    },
    {
        "Year": "2023Q1",
        "Zip": 588,
        "State": "ND",
        "City": "WILLISTON",
        "Div": 999,
        "Mat": 0.994,
        "Labor": 0.768,
        "Total": 0.91,
        "Location": "WILLISTON, ND"
    },
    {
        "Year": "2023Q1",
        "Zip": 590,
        "State": "MT",
        "City": "BILLINGS",
        "Div": 999,
        "Mat": 1.055,
        "Labor": 0.777,
        "Total": 0.952,
        "Location": "BILLINGS, MT"
    },
    {
        "Year": "2023Q1",
        "Zip": 591,
        "State": "MT",
        "City": "BILLINGS",
        "Div": 999,
        "Mat": 1.055,
        "Labor": 0.777,
        "Total": 0.952,
        "Location": "BILLINGS, MT"
    },
    {
        "Year": "2023Q1",
        "Zip": 592,
        "State": "MT",
        "City": "WOLF POINT",
        "Div": 999,
        "Mat": 1.039,
        "Labor": 0.715,
        "Total": 0.918,
        "Location": "WOLF POINT, MT"
    },
    {
        "Year": "2023Q1",
        "Zip": 593,
        "State": "MT",
        "City": "MILES CITY",
        "Div": 999,
        "Mat": 1.02,
        "Labor": 0.716,
        "Total": 0.907,
        "Location": "MILES CITY, MT"
    },
    {
        "Year": "2023Q1",
        "Zip": 594,
        "State": "MT",
        "City": "GREAT FALLS",
        "Div": 999,
        "Mat": 1.062,
        "Labor": 0.744,
        "Total": 0.943,
        "Location": "GREAT FALLS, MT"
    },
    {
        "Year": "2023Q1",
        "Zip": 595,
        "State": "MT",
        "City": "HAVRE",
        "Div": 999,
        "Mat": 1.03,
        "Labor": 0.696,
        "Total": 0.906,
        "Location": "HAVRE, MT"
    },
    {
        "Year": "2023Q1",
        "Zip": 596,
        "State": "MT",
        "City": "HELENA",
        "Div": 999,
        "Mat": 1.05,
        "Labor": 0.719,
        "Total": 0.927,
        "Location": "HELENA, MT"
    },
    {
        "Year": "2023Q1",
        "Zip": 597,
        "State": "MT",
        "City": "BUTTE",
        "Div": 999,
        "Mat": 1.049,
        "Labor": 0.716,
        "Total": 0.925,
        "Location": "BUTTE, MT"
    },
    {
        "Year": "2023Q1",
        "Zip": 598,
        "State": "MT",
        "City": "MISSOULA",
        "Div": 999,
        "Mat": 1.03,
        "Labor": 0.713,
        "Total": 0.912,
        "Location": "MISSOULA, MT"
    },
    {
        "Year": "2023Q1",
        "Zip": 599,
        "State": "MT",
        "City": "KALISPELL",
        "Div": 999,
        "Mat": 1.011,
        "Labor": 0.705,
        "Total": 0.897,
        "Location": "KALISPELL, MT"
    },
    {
        "Year": "2023Q1",
        "Zip": 600,
        "State": "IL",
        "City": "NORTH SUBURBAN",
        "Div": 999,
        "Mat": 0.98,
        "Labor": 1.429,
        "Total": 1.147,
        "Location": "NORTH SUBURBAN, IL"
    },
    {
        "Year": "2023Q1",
        "Zip": 601,
        "State": "IL",
        "City": "NORTH SUBURBAN",
        "Div": 999,
        "Mat": 0.98,
        "Labor": 1.429,
        "Total": 1.147,
        "Location": "NORTH SUBURBAN, IL"
    },
    {
        "Year": "2023Q1",
        "Zip": 602,
        "State": "IL",
        "City": "NORTH SUBURBAN",
        "Div": 999,
        "Mat": 0.98,
        "Labor": 1.429,
        "Total": 1.147,
        "Location": "NORTH SUBURBAN, IL"
    },
    {
        "Year": "2023Q1",
        "Zip": 603,
        "State": "IL",
        "City": "NORTH SUBURBAN",
        "Div": 999,
        "Mat": 0.98,
        "Labor": 1.429,
        "Total": 1.147,
        "Location": "NORTH SUBURBAN, IL"
    },
    {
        "Year": "2023Q1",
        "Zip": 604,
        "State": "IL",
        "City": "JOLIET",
        "Div": 999,
        "Mat": 0.974,
        "Labor": 1.464,
        "Total": 1.157,
        "Location": "JOLIET, IL"
    },
    {
        "Year": "2023Q1",
        "Zip": 605,
        "State": "IL",
        "City": "SOUTH SUBURBAN",
        "Div": 999,
        "Mat": 0.98,
        "Labor": 1.432,
        "Total": 1.148,
        "Location": "SOUTH SUBURBAN, IL"
    },
    {
        "Year": "2023Q1",
        "Zip": 606,
        "State": "IL",
        "City": "CHICAGO",
        "Div": 999,
        "Mat": 1.007,
        "Labor": 1.483,
        "Total": 1.184,
        "Location": "CHICAGO, IL"
    },
    {
        "Year": "2023Q1",
        "Zip": 607,
        "State": "IL",
        "City": "CHICAGO",
        "Div": 999,
        "Mat": 1.007,
        "Labor": 1.483,
        "Total": 1.184,
        "Location": "CHICAGO, IL"
    },
    {
        "Year": "2023Q1",
        "Zip": 608,
        "State": "IL",
        "City": "CHICAGO",
        "Div": 999,
        "Mat": 1.007,
        "Labor": 1.483,
        "Total": 1.184,
        "Location": "CHICAGO, IL"
    },
    {
        "Year": "2023Q1",
        "Zip": 609,
        "State": "IL",
        "City": "KANKAKEE",
        "Div": 999,
        "Mat": 0.94,
        "Labor": 1.33,
        "Total": 1.085,
        "Location": "KANKAKEE, IL"
    },
    {
        "Year": "2023Q1",
        "Zip": 610,
        "State": "IL",
        "City": "ROCKFORD",
        "Div": 999,
        "Mat": 0.962,
        "Labor": 1.313,
        "Total": 1.093,
        "Location": "ROCKFORD, IL"
    },
    {
        "Year": "2023Q1",
        "Zip": 611,
        "State": "IL",
        "City": "ROCKFORD",
        "Div": 999,
        "Mat": 0.962,
        "Labor": 1.313,
        "Total": 1.093,
        "Location": "ROCKFORD, IL"
    },
    {
        "Year": "2023Q1",
        "Zip": 612,
        "State": "IL",
        "City": "ROCK ISLAND",
        "Div": 999,
        "Mat": 0.938,
        "Labor": 1,
        "Total": 0.961,
        "Location": "ROCK ISLAND, IL"
    },
    {
        "Year": "2023Q1",
        "Zip": 613,
        "State": "IL",
        "City": "LA SALLE",
        "Div": 999,
        "Mat": 0.947,
        "Labor": 1.267,
        "Total": 1.066,
        "Location": "LA SALLE, IL"
    },
    {
        "Year": "2023Q1",
        "Zip": 614,
        "State": "IL",
        "City": "GALESBURG",
        "Div": 999,
        "Mat": 0.945,
        "Labor": 1.078,
        "Total": 0.995,
        "Location": "GALESBURG, IL"
    },
    {
        "Year": "2023Q1",
        "Zip": 615,
        "State": "IL",
        "City": "PEORIA",
        "Div": 999,
        "Mat": 0.971,
        "Labor": 1.101,
        "Total": 1.019,
        "Location": "PEORIA, IL"
    },
    {
        "Year": "2023Q1",
        "Zip": 616,
        "State": "IL",
        "City": "PEORIA",
        "Div": 999,
        "Mat": 0.971,
        "Labor": 1.101,
        "Total": 1.019,
        "Location": "PEORIA, IL"
    },
    {
        "Year": "2023Q1",
        "Zip": 617,
        "State": "IL",
        "City": "BLOOMINGTON",
        "Div": 999,
        "Mat": 0.939,
        "Labor": 1.103,
        "Total": 1,
        "Location": "BLOOMINGTON, IL"
    },
    {
        "Year": "2023Q1",
        "Zip": 618,
        "State": "IL",
        "City": "CHAMPAIGN",
        "Div": 999,
        "Mat": 0.969,
        "Labor": 1.1,
        "Total": 1.018,
        "Location": "CHAMPAIGN, IL"
    },
    {
        "Year": "2023Q1",
        "Zip": 619,
        "State": "IL",
        "City": "CHAMPAIGN",
        "Div": 999,
        "Mat": 0.969,
        "Labor": 1.1,
        "Total": 1.018,
        "Location": "CHAMPAIGN, IL"
    },
    {
        "Year": "2023Q1",
        "Zip": 620,
        "State": "IL",
        "City": "EAST ST. LOUIS",
        "Div": 999,
        "Mat": 0.947,
        "Labor": 1.083,
        "Total": 0.998,
        "Location": "EAST ST. LOUIS, IL"
    },
    {
        "Year": "2023Q1",
        "Zip": 621,
        "State": "IL",
        "City": "EAST ST. LOUIS",
        "Div": 999,
        "Mat": 0.947,
        "Labor": 1.083,
        "Total": 0.998,
        "Location": "EAST ST. LOUIS, IL"
    },
    {
        "Year": "2023Q1",
        "Zip": 622,
        "State": "IL",
        "City": "EAST ST. LOUIS",
        "Div": 999,
        "Mat": 0.947,
        "Labor": 1.083,
        "Total": 0.998,
        "Location": "EAST ST. LOUIS, IL"
    },
    {
        "Year": "2023Q1",
        "Zip": 623,
        "State": "IL",
        "City": "QUINCY",
        "Div": 999,
        "Mat": 0.965,
        "Labor": 1.064,
        "Total": 1.001,
        "Location": "QUINCY, IL"
    },
    {
        "Year": "2023Q1",
        "Zip": 624,
        "State": "IL",
        "City": "EFFINGHAM",
        "Div": 999,
        "Mat": 0.954,
        "Labor": 1.078,
        "Total": 1,
        "Location": "EFFINGHAM, IL"
    },
    {
        "Year": "2023Q1",
        "Zip": 625,
        "State": "IL",
        "City": "DECATUR",
        "Div": 999,
        "Mat": 0.977,
        "Labor": 1.078,
        "Total": 1.015,
        "Location": "DECATUR, IL"
    },
    {
        "Year": "2023Q1",
        "Zip": 626,
        "State": "IL",
        "City": "SPRINGFIELD",
        "Div": 999,
        "Mat": 0.99,
        "Labor": 1.09,
        "Total": 1.027,
        "Location": "SPRINGFIELD, IL"
    },
    {
        "Year": "2023Q1",
        "Zip": 627,
        "State": "IL",
        "City": "SPRINGFIELD",
        "Div": 999,
        "Mat": 0.99,
        "Labor": 1.09,
        "Total": 1.027,
        "Location": "SPRINGFIELD, IL"
    },
    {
        "Year": "2023Q1",
        "Zip": 628,
        "State": "IL",
        "City": "CENTRALIA",
        "Div": 999,
        "Mat": 0.93,
        "Labor": 1.067,
        "Total": 0.981,
        "Location": "CENTRALIA, IL"
    },
    {
        "Year": "2023Q1",
        "Zip": 629,
        "State": "IL",
        "City": "CARBONDALE",
        "Div": 999,
        "Mat": 0.928,
        "Labor": 1.053,
        "Total": 0.975,
        "Location": "CARBONDALE, IL"
    },
    {
        "Year": "2023Q1",
        "Zip": 630,
        "State": "MO",
        "City": "ST. LOUIS",
        "Div": 999,
        "Mat": 0.974,
        "Labor": 1.042,
        "Total": 0.999,
        "Location": "ST. LOUIS, MO"
    },
    {
        "Year": "2023Q1",
        "Zip": 631,
        "State": "MO",
        "City": "ST. LOUIS",
        "Div": 999,
        "Mat": 0.974,
        "Labor": 1.042,
        "Total": 0.999,
        "Location": "ST. LOUIS, MO"
    },
    {
        "Year": "2023Q1",
        "Zip": 633,
        "State": "MO",
        "City": "BOWLING GREEN",
        "Div": 999,
        "Mat": 0.935,
        "Labor": 0.866,
        "Total": 0.909,
        "Location": "BOWLING GREEN, MO"
    },
    {
        "Year": "2023Q1",
        "Zip": 634,
        "State": "MO",
        "City": "HANNIBAL",
        "Div": 999,
        "Mat": 0.925,
        "Labor": 0.831,
        "Total": 0.89,
        "Location": "HANNIBAL, MO"
    },
    {
        "Year": "2023Q1",
        "Zip": 635,
        "State": "MO",
        "City": "KIRKSVILLE",
        "Div": 999,
        "Mat": 0.953,
        "Labor": 0.79,
        "Total": 0.893,
        "Location": "KIRKSVILLE, MO"
    },
    {
        "Year": "2023Q1",
        "Zip": 636,
        "State": "MO",
        "City": "FLAT RIVER",
        "Div": 999,
        "Mat": 0.942,
        "Labor": 0.848,
        "Total": 0.907,
        "Location": "FLAT RIVER, MO"
    },
    {
        "Year": "2023Q1",
        "Zip": 637,
        "State": "MO",
        "City": "CAPE GIRARDEAU",
        "Div": 999,
        "Mat": 0.948,
        "Labor": 0.85,
        "Total": 0.912,
        "Location": "CAPE GIRARDEAU, MO"
    },
    {
        "Year": "2023Q1",
        "Zip": 638,
        "State": "MO",
        "City": "SIKESTON",
        "Div": 999,
        "Mat": 0.929,
        "Labor": 0.795,
        "Total": 0.879,
        "Location": "SIKESTON, MO"
    },
    {
        "Year": "2023Q1",
        "Zip": 639,
        "State": "MO",
        "City": "POPLAR BLUFF",
        "Div": 999,
        "Mat": 0.924,
        "Labor": 0.796,
        "Total": 0.876,
        "Location": "POPLAR BLUFF, MO"
    },
    {
        "Year": "2023Q1",
        "Zip": 640,
        "State": "MO",
        "City": "KANSAS CITY",
        "Div": 999,
        "Mat": 0.997,
        "Labor": 1.022,
        "Total": 1.006,
        "Location": "KANSAS CITY, MO"
    },
    {
        "Year": "2023Q1",
        "Zip": 641,
        "State": "MO",
        "City": "KANSAS CITY",
        "Div": 999,
        "Mat": 0.997,
        "Labor": 1.022,
        "Total": 1.006,
        "Location": "KANSAS CITY, MO"
    },
    {
        "Year": "2023Q1",
        "Zip": 644,
        "State": "MO",
        "City": "ST. JOSEPH",
        "Div": 999,
        "Mat": 0.963,
        "Labor": 0.892,
        "Total": 0.937,
        "Location": "ST. JOSEPH, MO"
    },
    {
        "Year": "2023Q1",
        "Zip": 645,
        "State": "MO",
        "City": "ST. JOSEPH",
        "Div": 999,
        "Mat": 0.963,
        "Labor": 0.892,
        "Total": 0.937,
        "Location": "ST. JOSEPH, MO"
    },
    {
        "Year": "2023Q1",
        "Zip": 646,
        "State": "MO",
        "City": "CHILLICOTHE",
        "Div": 999,
        "Mat": 0.931,
        "Labor": 0.858,
        "Total": 0.904,
        "Location": "CHILLICOTHE, MO"
    },
    {
        "Year": "2023Q1",
        "Zip": 647,
        "State": "MO",
        "City": "HARRISONVILLE",
        "Div": 999,
        "Mat": 0.925,
        "Labor": 0.921,
        "Total": 0.924,
        "Location": "HARRISONVILLE, MO"
    },
    {
        "Year": "2023Q1",
        "Zip": 648,
        "State": "MO",
        "City": "JOPLIN",
        "Div": 999,
        "Mat": 0.951,
        "Labor": 0.763,
        "Total": 0.881,
        "Location": "JOPLIN, MO"
    },
    {
        "Year": "2023Q1",
        "Zip": 650,
        "State": "MO",
        "City": "JEFFERSON CITY",
        "Div": 999,
        "Mat": 0.971,
        "Labor": 0.905,
        "Total": 0.947,
        "Location": "JEFFERSON CITY, MO"
    },
    {
        "Year": "2023Q1",
        "Zip": 651,
        "State": "MO",
        "City": "JEFFERSON CITY",
        "Div": 999,
        "Mat": 0.971,
        "Labor": 0.905,
        "Total": 0.947,
        "Location": "JEFFERSON CITY, MO"
    },
    {
        "Year": "2023Q1",
        "Zip": 652,
        "State": "MO",
        "City": "COLUMBIA",
        "Div": 999,
        "Mat": 0.959,
        "Labor": 0.896,
        "Total": 0.935,
        "Location": "COLUMBIA, MO"
    },
    {
        "Year": "2023Q1",
        "Zip": 653,
        "State": "MO",
        "City": "SEDALIA",
        "Div": 999,
        "Mat": 0.957,
        "Labor": 0.829,
        "Total": 0.909,
        "Location": "SEDALIA, MO"
    },
    {
        "Year": "2023Q1",
        "Zip": 654,
        "State": "MO",
        "City": "ROLLA",
        "Div": 999,
        "Mat": 0.935,
        "Labor": 0.908,
        "Total": 0.925,
        "Location": "ROLLA, MO"
    },
    {
        "Year": "2023Q1",
        "Zip": 655,
        "State": "MO",
        "City": "ROLLA",
        "Div": 999,
        "Mat": 0.935,
        "Labor": 0.908,
        "Total": 0.925,
        "Location": "ROLLA, MO"
    },
    {
        "Year": "2023Q1",
        "Zip": 656,
        "State": "MO",
        "City": "SPRINGFIELD",
        "Div": 999,
        "Mat": 0.978,
        "Labor": 0.803,
        "Total": 0.913,
        "Location": "SPRINGFIELD, MO"
    },
    {
        "Year": "2023Q1",
        "Zip": 657,
        "State": "MO",
        "City": "SPRINGFIELD",
        "Div": 999,
        "Mat": 0.978,
        "Labor": 0.803,
        "Total": 0.913,
        "Location": "SPRINGFIELD, MO"
    },
    {
        "Year": "2023Q1",
        "Zip": 658,
        "State": "MO",
        "City": "SPRINGFIELD",
        "Div": 999,
        "Mat": 0.978,
        "Labor": 0.803,
        "Total": 0.913,
        "Location": "SPRINGFIELD, MO"
    },
    {
        "Year": "2023Q1",
        "Zip": 660,
        "State": "KS",
        "City": "KANSAS CITY",
        "Div": 999,
        "Mat": 0.972,
        "Labor": 0.985,
        "Total": 0.977,
        "Location": "KANSAS CITY, KS"
    },
    {
        "Year": "2023Q1",
        "Zip": 661,
        "State": "KS",
        "City": "KANSAS CITY",
        "Div": 999,
        "Mat": 0.972,
        "Labor": 0.985,
        "Total": 0.977,
        "Location": "KANSAS CITY, KS"
    },
    {
        "Year": "2023Q1",
        "Zip": 662,
        "State": "KS",
        "City": "KANSAS CITY",
        "Div": 999,
        "Mat": 0.972,
        "Labor": 0.985,
        "Total": 0.977,
        "Location": "KANSAS CITY, KS"
    },
    {
        "Year": "2023Q1",
        "Zip": 664,
        "State": "KS",
        "City": "TOPEKA",
        "Div": 999,
        "Mat": 0.983,
        "Labor": 0.744,
        "Total": 0.894,
        "Location": "TOPEKA, KS"
    },
    {
        "Year": "2023Q1",
        "Zip": 665,
        "State": "KS",
        "City": "TOPEKA",
        "Div": 999,
        "Mat": 0.983,
        "Labor": 0.744,
        "Total": 0.894,
        "Location": "TOPEKA, KS"
    },
    {
        "Year": "2023Q1",
        "Zip": 666,
        "State": "KS",
        "City": "TOPEKA",
        "Div": 999,
        "Mat": 0.983,
        "Labor": 0.744,
        "Total": 0.894,
        "Location": "TOPEKA, KS"
    },
    {
        "Year": "2023Q1",
        "Zip": 667,
        "State": "KS",
        "City": "FORT SCOTT",
        "Div": 999,
        "Mat": 0.945,
        "Labor": 0.699,
        "Total": 0.854,
        "Location": "FORT SCOTT, KS"
    },
    {
        "Year": "2023Q1",
        "Zip": 668,
        "State": "KS",
        "City": "EMPORIA",
        "Div": 999,
        "Mat": 0.944,
        "Labor": 0.704,
        "Total": 0.855,
        "Location": "EMPORIA, KS"
    },
    {
        "Year": "2023Q1",
        "Zip": 669,
        "State": "KS",
        "City": "BELLEVILLE",
        "Div": 999,
        "Mat": 0.958,
        "Labor": 0.659,
        "Total": 0.847,
        "Location": "BELLEVILLE, KS"
    },
    {
        "Year": "2023Q1",
        "Zip": 670,
        "State": "KS",
        "City": "WICHITA",
        "Div": 999,
        "Mat": 0.974,
        "Labor": 0.714,
        "Total": 0.877,
        "Location": "WICHITA, KS"
    },
    {
        "Year": "2023Q1",
        "Zip": 671,
        "State": "KS",
        "City": "WICHITA",
        "Div": 999,
        "Mat": 0.974,
        "Labor": 0.714,
        "Total": 0.877,
        "Location": "WICHITA, KS"
    },
    {
        "Year": "2023Q1",
        "Zip": 672,
        "State": "KS",
        "City": "WICHITA",
        "Div": 999,
        "Mat": 0.974,
        "Labor": 0.714,
        "Total": 0.877,
        "Location": "WICHITA, KS"
    },
    {
        "Year": "2023Q1",
        "Zip": 673,
        "State": "KS",
        "City": "INDEPENDENCE",
        "Div": 999,
        "Mat": 0.96,
        "Labor": 0.699,
        "Total": 0.862,
        "Location": "INDEPENDENCE, KS"
    },
    {
        "Year": "2023Q1",
        "Zip": 674,
        "State": "KS",
        "City": "SALINA",
        "Div": 999,
        "Mat": 0.968,
        "Labor": 0.705,
        "Total": 0.87,
        "Location": "SALINA, KS"
    },
    {
        "Year": "2023Q1",
        "Zip": 675,
        "State": "KS",
        "City": "HUTCHINSON",
        "Div": 999,
        "Mat": 0.923,
        "Labor": 0.667,
        "Total": 0.827,
        "Location": "HUTCHINSON, KS"
    },
    {
        "Year": "2023Q1",
        "Zip": 676,
        "State": "KS",
        "City": "HAYS",
        "Div": 999,
        "Mat": 0.955,
        "Labor": 0.673,
        "Total": 0.85,
        "Location": "HAYS, KS"
    },
    {
        "Year": "2023Q1",
        "Zip": 677,
        "State": "KS",
        "City": "COLBY",
        "Div": 999,
        "Mat": 0.961,
        "Labor": 0.685,
        "Total": 0.858,
        "Location": "COLBY, KS"
    },
    {
        "Year": "2023Q1",
        "Zip": 678,
        "State": "KS",
        "City": "DODGE CITY",
        "Div": 999,
        "Mat": 0.98,
        "Labor": 0.715,
        "Total": 0.881,
        "Location": "DODGE CITY, KS"
    },
    {
        "Year": "2023Q1",
        "Zip": 679,
        "State": "KS",
        "City": "LIBERAL",
        "Div": 999,
        "Mat": 0.956,
        "Labor": 0.664,
        "Total": 0.847,
        "Location": "LIBERAL, KS"
    },
    {
        "Year": "2023Q1",
        "Zip": 680,
        "State": "NE",
        "City": "OMAHA",
        "Div": 999,
        "Mat": 0.982,
        "Labor": 0.827,
        "Total": 0.924,
        "Location": "OMAHA, NE"
    },
    {
        "Year": "2023Q1",
        "Zip": 681,
        "State": "NE",
        "City": "OMAHA",
        "Div": 999,
        "Mat": 0.982,
        "Labor": 0.827,
        "Total": 0.924,
        "Location": "OMAHA, NE"
    },
    {
        "Year": "2023Q1",
        "Zip": 683,
        "State": "NE",
        "City": "LINCOLN",
        "Div": 999,
        "Mat": 0.995,
        "Labor": 0.764,
        "Total": 0.909,
        "Location": "LINCOLN, NE"
    },
    {
        "Year": "2023Q1",
        "Zip": 684,
        "State": "NE",
        "City": "LINCOLN",
        "Div": 999,
        "Mat": 0.995,
        "Labor": 0.764,
        "Total": 0.909,
        "Location": "LINCOLN, NE"
    },
    {
        "Year": "2023Q1",
        "Zip": 685,
        "State": "NE",
        "City": "LINCOLN",
        "Div": 999,
        "Mat": 0.995,
        "Labor": 0.764,
        "Total": 0.909,
        "Location": "LINCOLN, NE"
    },
    {
        "Year": "2023Q1",
        "Zip": 686,
        "State": "NE",
        "City": "COLUMBUS",
        "Div": 999,
        "Mat": 0.948,
        "Labor": 0.739,
        "Total": 0.87,
        "Location": "COLUMBUS, NE"
    },
    {
        "Year": "2023Q1",
        "Zip": 687,
        "State": "NE",
        "City": "NORFOLK",
        "Div": 999,
        "Mat": 0.962,
        "Labor": 0.711,
        "Total": 0.868,
        "Location": "NORFOLK, NE"
    },
    {
        "Year": "2023Q1",
        "Zip": 688,
        "State": "NE",
        "City": "GRAND ISLAND",
        "Div": 999,
        "Mat": 0.969,
        "Labor": 0.77,
        "Total": 0.895,
        "Location": "GRAND ISLAND, NE"
    },
    {
        "Year": "2023Q1",
        "Zip": 689,
        "State": "NE",
        "City": "HASTINGS",
        "Div": 999,
        "Mat": 0.958,
        "Labor": 0.716,
        "Total": 0.867,
        "Location": "HASTINGS, NE"
    },
    {
        "Year": "2023Q1",
        "Zip": 690,
        "State": "NE",
        "City": "MCCOOK",
        "Div": 999,
        "Mat": 0.947,
        "Labor": 0.666,
        "Total": 0.842,
        "Location": "MCCOOK, NE"
    },
    {
        "Year": "2023Q1",
        "Zip": 691,
        "State": "NE",
        "City": "NORTH PLATTE",
        "Div": 999,
        "Mat": 0.956,
        "Labor": 0.719,
        "Total": 0.868,
        "Location": "NORTH PLATTE, NE"
    },
    {
        "Year": "2023Q1",
        "Zip": 692,
        "State": "NE",
        "City": "VALENTINE",
        "Div": 999,
        "Mat": 0.971,
        "Labor": 0.643,
        "Total": 0.849,
        "Location": "VALENTINE, NE"
    },
    {
        "Year": "2023Q1",
        "Zip": 693,
        "State": "NE",
        "City": "ALLIANCE",
        "Div": 999,
        "Mat": 0.976,
        "Labor": 0.672,
        "Total": 0.863,
        "Location": "ALLIANCE, NE"
    },
    {
        "Year": "2023Q1",
        "Zip": 700,
        "State": "LA",
        "City": "NEW ORLEANS",
        "Div": 999,
        "Mat": 1.033,
        "Labor": 0.712,
        "Total": 0.913,
        "Location": "NEW ORLEANS, LA"
    },
    {
        "Year": "2023Q1",
        "Zip": 701,
        "State": "LA",
        "City": "NEW ORLEANS",
        "Div": 999,
        "Mat": 1.033,
        "Labor": 0.712,
        "Total": 0.913,
        "Location": "NEW ORLEANS, LA"
    },
    {
        "Year": "2023Q1",
        "Zip": 703,
        "State": "LA",
        "City": "THIBODAUX",
        "Div": 999,
        "Mat": 0.995,
        "Labor": 0.632,
        "Total": 0.86,
        "Location": "THIBODAUX, LA"
    },
    {
        "Year": "2023Q1",
        "Zip": 704,
        "State": "LA",
        "City": "HAMMOND",
        "Div": 999,
        "Mat": 0.975,
        "Labor": 0.619,
        "Total": 0.842,
        "Location": "HAMMOND, LA"
    },
    {
        "Year": "2023Q1",
        "Zip": 705,
        "State": "LA",
        "City": "LAFAYETTE",
        "Div": 999,
        "Mat": 0.998,
        "Labor": 0.665,
        "Total": 0.874,
        "Location": "LAFAYETTE, LA"
    },
    {
        "Year": "2023Q1",
        "Zip": 706,
        "State": "LA",
        "City": "LAKE CHARLES",
        "Div": 999,
        "Mat": 0.997,
        "Labor": 0.664,
        "Total": 0.873,
        "Location": "LAKE CHARLES, LA"
    },
    {
        "Year": "2023Q1",
        "Zip": 707,
        "State": "LA",
        "City": "BATON ROUGE",
        "Div": 999,
        "Mat": 1.035,
        "Labor": 0.667,
        "Total": 0.898,
        "Location": "BATON ROUGE, LA"
    },
    {
        "Year": "2023Q1",
        "Zip": 708,
        "State": "LA",
        "City": "BATON ROUGE",
        "Div": 999,
        "Mat": 1.035,
        "Labor": 0.667,
        "Total": 0.898,
        "Location": "BATON ROUGE, LA"
    },
    {
        "Year": "2023Q1",
        "Zip": 710,
        "State": "LA",
        "City": "SHREVEPORT",
        "Div": 999,
        "Mat": 1.054,
        "Labor": 0.641,
        "Total": 0.9,
        "Location": "SHREVEPORT, LA"
    },
    {
        "Year": "2023Q1",
        "Zip": 711,
        "State": "LA",
        "City": "SHREVEPORT",
        "Div": 999,
        "Mat": 1.054,
        "Labor": 0.641,
        "Total": 0.9,
        "Location": "SHREVEPORT, LA"
    },
    {
        "Year": "2023Q1",
        "Zip": 712,
        "State": "LA",
        "City": "MONROE",
        "Div": 999,
        "Mat": 1.033,
        "Labor": 0.615,
        "Total": 0.877,
        "Location": "MONROE, LA"
    },
    {
        "Year": "2023Q1",
        "Zip": 713,
        "State": "LA",
        "City": "ALEXANDRIA",
        "Div": 999,
        "Mat": 1.035,
        "Labor": 0.619,
        "Total": 0.88,
        "Location": "ALEXANDRIA, LA"
    },
    {
        "Year": "2023Q1",
        "Zip": 714,
        "State": "LA",
        "City": "ALEXANDRIA",
        "Div": 999,
        "Mat": 1.035,
        "Labor": 0.619,
        "Total": 0.88,
        "Location": "ALEXANDRIA, LA"
    },
    {
        "Year": "2023Q1",
        "Zip": 716,
        "State": "AR",
        "City": "PINE BLUFF",
        "Div": 999,
        "Mat": 1.045,
        "Labor": 0.621,
        "Total": 0.887,
        "Location": "PINE BLUFF, AR"
    },
    {
        "Year": "2023Q1",
        "Zip": 717,
        "State": "AR",
        "City": "CAMDEN",
        "Div": 999,
        "Mat": 1.019,
        "Labor": 0.609,
        "Total": 0.866,
        "Location": "CAMDEN, AR"
    },
    {
        "Year": "2023Q1",
        "Zip": 718,
        "State": "AR",
        "City": "TEXARKANA",
        "Div": 999,
        "Mat": 1.029,
        "Labor": 0.621,
        "Total": 0.877,
        "Location": "TEXARKANA, AR"
    },
    {
        "Year": "2023Q1",
        "Zip": 719,
        "State": "AR",
        "City": "HOT SPRINGS",
        "Div": 999,
        "Mat": 1.012,
        "Labor": 0.603,
        "Total": 0.86,
        "Location": "HOT SPRINGS, AR"
    },
    {
        "Year": "2023Q1",
        "Zip": 720,
        "State": "AR",
        "City": "LITTLE ROCK",
        "Div": 999,
        "Mat": 1.018,
        "Labor": 0.65,
        "Total": 0.881,
        "Location": "LITTLE ROCK, AR"
    },
    {
        "Year": "2023Q1",
        "Zip": 721,
        "State": "AR",
        "City": "LITTLE ROCK",
        "Div": 999,
        "Mat": 1.018,
        "Labor": 0.65,
        "Total": 0.881,
        "Location": "LITTLE ROCK, AR"
    },
    {
        "Year": "2023Q1",
        "Zip": 722,
        "State": "AR",
        "City": "LITTLE ROCK",
        "Div": 999,
        "Mat": 1.018,
        "Labor": 0.65,
        "Total": 0.881,
        "Location": "LITTLE ROCK, AR"
    },
    {
        "Year": "2023Q1",
        "Zip": 723,
        "State": "AR",
        "City": "WEST MEMPHIS",
        "Div": 999,
        "Mat": 0.985,
        "Labor": 0.653,
        "Total": 0.861,
        "Location": "WEST MEMPHIS, AR"
    },
    {
        "Year": "2023Q1",
        "Zip": 724,
        "State": "AR",
        "City": "JONESBORO",
        "Div": 999,
        "Mat": 0.997,
        "Labor": 0.643,
        "Total": 0.865,
        "Location": "JONESBORO, AR"
    },
    {
        "Year": "2023Q1",
        "Zip": 725,
        "State": "AR",
        "City": "BATESVILLE",
        "Div": 999,
        "Mat": 0.97,
        "Labor": 0.604,
        "Total": 0.833,
        "Location": "BATESVILLE, AR"
    },
    {
        "Year": "2023Q1",
        "Zip": 726,
        "State": "AR",
        "City": "HARRISON",
        "Div": 999,
        "Mat": 0.984,
        "Labor": 0.596,
        "Total": 0.839,
        "Location": "HARRISON, AR"
    },
    {
        "Year": "2023Q1",
        "Zip": 727,
        "State": "AR",
        "City": "FAYETTEVILLE",
        "Div": 999,
        "Mat": 0.962,
        "Labor": 0.614,
        "Total": 0.832,
        "Location": "FAYETTEVILLE, AR"
    },
    {
        "Year": "2023Q1",
        "Zip": 728,
        "State": "AR",
        "City": "RUSSELLVILLE",
        "Div": 999,
        "Mat": 0.973,
        "Labor": 0.595,
        "Total": 0.832,
        "Location": "RUSSELLVILLE, AR"
    },
    {
        "Year": "2023Q1",
        "Zip": 729,
        "State": "AR",
        "City": "FORT SMITH",
        "Div": 999,
        "Mat": 1.006,
        "Labor": 0.621,
        "Total": 0.862,
        "Location": "FORT SMITH, AR"
    },
    {
        "Year": "2023Q1",
        "Zip": 730,
        "State": "OK",
        "City": "OKLAHOMA CITY",
        "Div": 999,
        "Mat": 0.999,
        "Labor": 0.713,
        "Total": 0.893,
        "Location": "OKLAHOMA CITY, OK"
    },
    {
        "Year": "2023Q1",
        "Zip": 731,
        "State": "OK",
        "City": "OKLAHOMA CITY",
        "Div": 999,
        "Mat": 0.999,
        "Labor": 0.713,
        "Total": 0.893,
        "Location": "OKLAHOMA CITY, OK"
    },
    {
        "Year": "2023Q1",
        "Zip": 734,
        "State": "OK",
        "City": "ARDMORE",
        "Div": 999,
        "Mat": 0.971,
        "Labor": 0.666,
        "Total": 0.857,
        "Location": "ARDMORE, OK"
    },
    {
        "Year": "2023Q1",
        "Zip": 735,
        "State": "OK",
        "City": "LAWTON",
        "Div": 999,
        "Mat": 1.001,
        "Labor": 0.68,
        "Total": 0.881,
        "Location": "LAWTON, OK"
    },
    {
        "Year": "2023Q1",
        "Zip": 736,
        "State": "OK",
        "City": "CLINTON",
        "Div": 999,
        "Mat": 0.982,
        "Labor": 0.665,
        "Total": 0.864,
        "Location": "CLINTON, OK"
    },
    {
        "Year": "2023Q1",
        "Zip": 737,
        "State": "OK",
        "City": "ENID",
        "Div": 999,
        "Mat": 0.993,
        "Labor": 0.672,
        "Total": 0.873,
        "Location": "ENID, OK"
    },
    {
        "Year": "2023Q1",
        "Zip": 738,
        "State": "OK",
        "City": "WOODWARD",
        "Div": 999,
        "Mat": 0.97,
        "Labor": 0.683,
        "Total": 0.863,
        "Location": "WOODWARD, OK"
    },
    {
        "Year": "2023Q1",
        "Zip": 739,
        "State": "OK",
        "City": "GUYMON",
        "Div": 999,
        "Mat": 0.98,
        "Labor": 0.603,
        "Total": 0.839,
        "Location": "GUYMON, OK"
    },
    {
        "Year": "2023Q1",
        "Zip": 740,
        "State": "OK",
        "City": "TULSA",
        "Div": 999,
        "Mat": 1.001,
        "Labor": 0.671,
        "Total": 0.878,
        "Location": "TULSA, OK"
    },
    {
        "Year": "2023Q1",
        "Zip": 741,
        "State": "OK",
        "City": "TULSA",
        "Div": 999,
        "Mat": 1.001,
        "Labor": 0.671,
        "Total": 0.878,
        "Location": "TULSA, OK"
    },
    {
        "Year": "2023Q1",
        "Zip": 743,
        "State": "OK",
        "City": "MIAMI",
        "Div": 999,
        "Mat": 0.959,
        "Labor": 0.638,
        "Total": 0.839,
        "Location": "MIAMI, OK"
    },
    {
        "Year": "2023Q1",
        "Zip": 744,
        "State": "OK",
        "City": "MUSKOGEE",
        "Div": 999,
        "Mat": 0.989,
        "Labor": 0.663,
        "Total": 0.867,
        "Location": "MUSKOGEE, OK"
    },
    {
        "Year": "2023Q1",
        "Zip": 745,
        "State": "OK",
        "City": "MCALESTER",
        "Div": 999,
        "Mat": 0.956,
        "Labor": 0.65,
        "Total": 0.842,
        "Location": "MCALESTER, OK"
    },
    {
        "Year": "2023Q1",
        "Zip": 746,
        "State": "OK",
        "City": "PONCA CITY",
        "Div": 999,
        "Mat": 0.962,
        "Labor": 0.613,
        "Total": 0.832,
        "Location": "PONCA CITY, OK"
    },
    {
        "Year": "2023Q1",
        "Zip": 747,
        "State": "OK",
        "City": "DURANT",
        "Div": 999,
        "Mat": 0.962,
        "Labor": 0.626,
        "Total": 0.837,
        "Location": "DURANT, OK"
    },
    {
        "Year": "2023Q1",
        "Zip": 748,
        "State": "OK",
        "City": "SHAWNEE",
        "Div": 999,
        "Mat": 0.975,
        "Labor": 0.637,
        "Total": 0.849,
        "Location": "SHAWNEE, OK"
    },
    {
        "Year": "2023Q1",
        "Zip": 749,
        "State": "OK",
        "City": "POTEAU",
        "Div": 999,
        "Mat": 0.957,
        "Labor": 0.625,
        "Total": 0.833,
        "Location": "POTEAU, OK"
    },
    {
        "Year": "2023Q1",
        "Zip": 750,
        "State": "TX",
        "City": "MCKINNEY",
        "Div": 999,
        "Mat": 1.005,
        "Labor": 0.587,
        "Total": 0.849,
        "Location": "MCKINNEY, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 751,
        "State": "TX",
        "City": "WAXAHACHIE",
        "Div": 999,
        "Mat": 1.007,
        "Labor": 0.66,
        "Total": 0.878,
        "Location": "WAXAHACHIE, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 752,
        "State": "TX",
        "City": "DALLAS",
        "Div": 999,
        "Mat": 1.036,
        "Labor": 0.675,
        "Total": 0.901,
        "Location": "DALLAS, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 753,
        "State": "TX",
        "City": "DALLAS",
        "Div": 999,
        "Mat": 1.036,
        "Labor": 0.675,
        "Total": 0.901,
        "Location": "DALLAS, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 754,
        "State": "TX",
        "City": "GREENVILLE",
        "Div": 999,
        "Mat": 1.008,
        "Labor": 0.583,
        "Total": 0.849,
        "Location": "GREENVILLE, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 755,
        "State": "TX",
        "City": "TEXARKANA",
        "Div": 999,
        "Mat": 0.995,
        "Labor": 0.654,
        "Total": 0.868,
        "Location": "TEXARKANA, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 756,
        "State": "TX",
        "City": "LONGVIEW",
        "Div": 999,
        "Mat": 1.006,
        "Labor": 0.566,
        "Total": 0.842,
        "Location": "LONGVIEW, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 757,
        "State": "TX",
        "City": "TYLER",
        "Div": 999,
        "Mat": 1.01,
        "Labor": 0.614,
        "Total": 0.863,
        "Location": "TYLER, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 758,
        "State": "TX",
        "City": "PALESTINE",
        "Div": 999,
        "Mat": 0.979,
        "Labor": 0.562,
        "Total": 0.823,
        "Location": "PALESTINE, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 759,
        "State": "TX",
        "City": "LUFKIN",
        "Div": 999,
        "Mat": 0.986,
        "Labor": 0.575,
        "Total": 0.833,
        "Location": "LUFKIN, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 760,
        "State": "TX",
        "City": "FORT WORTH",
        "Div": 999,
        "Mat": 1.034,
        "Labor": 0.645,
        "Total": 0.889,
        "Location": "FORT WORTH, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 761,
        "State": "TX",
        "City": "FORT WORTH",
        "Div": 999,
        "Mat": 1.034,
        "Labor": 0.645,
        "Total": 0.889,
        "Location": "FORT WORTH, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 762,
        "State": "TX",
        "City": "DENTON",
        "Div": 999,
        "Mat": 1.017,
        "Labor": 0.592,
        "Total": 0.859,
        "Location": "DENTON, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 763,
        "State": "TX",
        "City": "WICHITA FALLS",
        "Div": 999,
        "Mat": 1.009,
        "Labor": 0.622,
        "Total": 0.865,
        "Location": "WICHITA FALLS, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 764,
        "State": "TX",
        "City": "EASTLAND",
        "Div": 999,
        "Mat": 0.994,
        "Labor": 0.567,
        "Total": 0.835,
        "Location": "EASTLAND, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 765,
        "State": "TX",
        "City": "TEMPLE",
        "Div": 999,
        "Mat": 0.975,
        "Labor": 0.542,
        "Total": 0.814,
        "Location": "TEMPLE, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 766,
        "State": "TX",
        "City": "WACO",
        "Div": 999,
        "Mat": 1.001,
        "Labor": 0.644,
        "Total": 0.868,
        "Location": "WACO, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 767,
        "State": "TX",
        "City": "WACO",
        "Div": 999,
        "Mat": 1.001,
        "Labor": 0.644,
        "Total": 0.868,
        "Location": "WACO, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 768,
        "State": "TX",
        "City": "BROWNWOOD",
        "Div": 999,
        "Mat": 1.02,
        "Labor": 0.551,
        "Total": 0.845,
        "Location": "BROWNWOOD, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 769,
        "State": "TX",
        "City": "SAN ANGELO",
        "Div": 999,
        "Mat": 1.016,
        "Labor": 0.572,
        "Total": 0.85,
        "Location": "SAN ANGELO, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 770,
        "State": "TX",
        "City": "HOUSTON",
        "Div": 999,
        "Mat": 1.031,
        "Labor": 0.675,
        "Total": 0.898,
        "Location": "HOUSTON, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 771,
        "State": "TX",
        "City": "HOUSTON",
        "Div": 999,
        "Mat": 1.031,
        "Labor": 0.675,
        "Total": 0.898,
        "Location": "HOUSTON, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 772,
        "State": "TX",
        "City": "HOUSTON",
        "Div": 999,
        "Mat": 1.031,
        "Labor": 0.675,
        "Total": 0.898,
        "Location": "HOUSTON, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 773,
        "State": "TX",
        "City": "HUNTSVILLE",
        "Div": 999,
        "Mat": 1,
        "Labor": 0.579,
        "Total": 0.843,
        "Location": "HUNTSVILLE, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 774,
        "State": "TX",
        "City": "WHARTON",
        "Div": 999,
        "Mat": 1.012,
        "Labor": 0.58,
        "Total": 0.851,
        "Location": "WHARTON, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 775,
        "State": "TX",
        "City": "GALVESTON",
        "Div": 999,
        "Mat": 0.994,
        "Labor": 0.629,
        "Total": 0.858,
        "Location": "GALVESTON, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 776,
        "State": "TX",
        "City": "BEAUMONT",
        "Div": 999,
        "Mat": 1.004,
        "Labor": 0.66,
        "Total": 0.876,
        "Location": "BEAUMONT, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 777,
        "State": "TX",
        "City": "BEAUMONT",
        "Div": 999,
        "Mat": 1.004,
        "Labor": 0.66,
        "Total": 0.876,
        "Location": "BEAUMONT, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 778,
        "State": "TX",
        "City": "BRYAN",
        "Div": 999,
        "Mat": 0.969,
        "Labor": 0.609,
        "Total": 0.835,
        "Location": "BRYAN, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 779,
        "State": "TX",
        "City": "VICTORIA",
        "Div": 999,
        "Mat": 1.012,
        "Labor": 0.574,
        "Total": 0.849,
        "Location": "VICTORIA, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 780,
        "State": "TX",
        "City": "LAREDO",
        "Div": 999,
        "Mat": 0.988,
        "Labor": 0.624,
        "Total": 0.852,
        "Location": "LAREDO, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 781,
        "State": "TX",
        "City": "SAN ANTONIO",
        "Div": 999,
        "Mat": 1.006,
        "Labor": 0.657,
        "Total": 0.876,
        "Location": "SAN ANTONIO, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 782,
        "State": "TX",
        "City": "SAN ANTONIO",
        "Div": 999,
        "Mat": 1.006,
        "Labor": 0.657,
        "Total": 0.876,
        "Location": "SAN ANTONIO, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 783,
        "State": "TX",
        "City": "CORPUS CHRISTI",
        "Div": 999,
        "Mat": 1.007,
        "Labor": 0.655,
        "Total": 0.876,
        "Location": "CORPUS CHRISTI, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 784,
        "State": "TX",
        "City": "CORPUS CHRISTI",
        "Div": 999,
        "Mat": 1.007,
        "Labor": 0.655,
        "Total": 0.876,
        "Location": "CORPUS CHRISTI, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 785,
        "State": "TX",
        "City": "MCALLEN",
        "Div": 999,
        "Mat": 1.006,
        "Labor": 0.547,
        "Total": 0.835,
        "Location": "MCALLEN, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 786,
        "State": "TX",
        "City": "AUSTIN",
        "Div": 999,
        "Mat": 1.006,
        "Labor": 0.66,
        "Total": 0.877,
        "Location": "AUSTIN, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 787,
        "State": "TX",
        "City": "AUSTIN",
        "Div": 999,
        "Mat": 1.006,
        "Labor": 0.66,
        "Total": 0.877,
        "Location": "AUSTIN, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 788,
        "State": "TX",
        "City": "DEL RIO",
        "Div": 999,
        "Mat": 1.006,
        "Labor": 0.564,
        "Total": 0.841,
        "Location": "DEL RIO, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 789,
        "State": "TX",
        "City": "GIDDINGS",
        "Div": 999,
        "Mat": 0.976,
        "Labor": 0.573,
        "Total": 0.826,
        "Location": "GIDDINGS, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 790,
        "State": "TX",
        "City": "AMARILLO",
        "Div": 999,
        "Mat": 1.018,
        "Labor": 0.638,
        "Total": 0.876,
        "Location": "AMARILLO, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 791,
        "State": "TX",
        "City": "AMARILLO",
        "Div": 999,
        "Mat": 1.018,
        "Labor": 0.638,
        "Total": 0.876,
        "Location": "AMARILLO, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 792,
        "State": "TX",
        "City": "CHILDRESS",
        "Div": 999,
        "Mat": 0.995,
        "Labor": 0.572,
        "Total": 0.838,
        "Location": "CHILDRESS, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 793,
        "State": "TX",
        "City": "LUBBOCK",
        "Div": 999,
        "Mat": 1.016,
        "Labor": 0.622,
        "Total": 0.869,
        "Location": "LUBBOCK, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 794,
        "State": "TX",
        "City": "LUBBOCK",
        "Div": 999,
        "Mat": 1.016,
        "Labor": 0.622,
        "Total": 0.869,
        "Location": "LUBBOCK, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 795,
        "State": "TX",
        "City": "ABILENE",
        "Div": 999,
        "Mat": 1.006,
        "Labor": 0.616,
        "Total": 0.861,
        "Location": "ABILENE, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 796,
        "State": "TX",
        "City": "ABILENE",
        "Div": 999,
        "Mat": 1.006,
        "Labor": 0.616,
        "Total": 0.861,
        "Location": "ABILENE, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 797,
        "State": "TX",
        "City": "MIDLAND",
        "Div": 999,
        "Mat": 1.013,
        "Labor": 0.65,
        "Total": 0.877,
        "Location": "MIDLAND, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 798,
        "State": "TX",
        "City": "EL PASO",
        "Div": 999,
        "Mat": 0.999,
        "Labor": 0.649,
        "Total": 0.869,
        "Location": "EL PASO, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 799,
        "State": "TX",
        "City": "EL PASO",
        "Div": 999,
        "Mat": 0.999,
        "Labor": 0.649,
        "Total": 0.869,
        "Location": "EL PASO, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 800,
        "State": "CO",
        "City": "DENVER",
        "Div": 999,
        "Mat": 1.072,
        "Labor": 0.777,
        "Total": 0.962,
        "Location": "DENVER, CO"
    },
    {
        "Year": "2023Q1",
        "Zip": 801,
        "State": "CO",
        "City": "DENVER",
        "Div": 999,
        "Mat": 1.072,
        "Labor": 0.777,
        "Total": 0.962,
        "Location": "DENVER, CO"
    },
    {
        "Year": "2023Q1",
        "Zip": 802,
        "State": "CO",
        "City": "DENVER",
        "Div": 999,
        "Mat": 1.072,
        "Labor": 0.777,
        "Total": 0.962,
        "Location": "DENVER, CO"
    },
    {
        "Year": "2023Q1",
        "Zip": 803,
        "State": "CO",
        "City": "BOULDER",
        "Div": 999,
        "Mat": 0.999,
        "Labor": 0.772,
        "Total": 0.914,
        "Location": "BOULDER, CO"
    },
    {
        "Year": "2023Q1",
        "Zip": 804,
        "State": "CO",
        "City": "GOLDEN",
        "Div": 999,
        "Mat": 1.016,
        "Labor": 0.725,
        "Total": 0.907,
        "Location": "GOLDEN, CO"
    },
    {
        "Year": "2023Q1",
        "Zip": 805,
        "State": "CO",
        "City": "FORT COLLINS",
        "Div": 999,
        "Mat": 1.035,
        "Labor": 0.748,
        "Total": 0.928,
        "Location": "FORT COLLINS, CO"
    },
    {
        "Year": "2023Q1",
        "Zip": 806,
        "State": "CO",
        "City": "GREELEY",
        "Div": 999,
        "Mat": 1.012,
        "Labor": 0.748,
        "Total": 0.913,
        "Location": "GREELEY, CO"
    },
    {
        "Year": "2023Q1",
        "Zip": 807,
        "State": "CO",
        "City": "FORT MORGAN",
        "Div": 999,
        "Mat": 1.004,
        "Labor": 0.729,
        "Total": 0.901,
        "Location": "FORT MORGAN, CO"
    },
    {
        "Year": "2023Q1",
        "Zip": 808,
        "State": "CO",
        "City": "COLORADO SPRINGS",
        "Div": 999,
        "Mat": 1.033,
        "Labor": 0.727,
        "Total": 0.919,
        "Location": "COLORADO SPRINGS, CO"
    },
    {
        "Year": "2023Q1",
        "Zip": 809,
        "State": "CO",
        "City": "COLORADO SPRINGS",
        "Div": 999,
        "Mat": 1.033,
        "Labor": 0.727,
        "Total": 0.919,
        "Location": "COLORADO SPRINGS, CO"
    },
    {
        "Year": "2023Q1",
        "Zip": 810,
        "State": "CO",
        "City": "PUEBLO",
        "Div": 999,
        "Mat": 1.063,
        "Labor": 0.711,
        "Total": 0.932,
        "Location": "PUEBLO, CO"
    },
    {
        "Year": "2023Q1",
        "Zip": 811,
        "State": "CO",
        "City": "ALAMOSA",
        "Div": 999,
        "Mat": 1.068,
        "Labor": 0.698,
        "Total": 0.93,
        "Location": "ALAMOSA, CO"
    },
    {
        "Year": "2023Q1",
        "Zip": 812,
        "State": "CO",
        "City": "SALIDA",
        "Div": 999,
        "Mat": 1.063,
        "Labor": 0.702,
        "Total": 0.928,
        "Location": "SALIDA, CO"
    },
    {
        "Year": "2023Q1",
        "Zip": 813,
        "State": "CO",
        "City": "DURANGO",
        "Div": 999,
        "Mat": 1.07,
        "Labor": 0.661,
        "Total": 0.917,
        "Location": "DURANGO, CO"
    },
    {
        "Year": "2023Q1",
        "Zip": 814,
        "State": "CO",
        "City": "MONTROSE",
        "Div": 999,
        "Mat": 1.055,
        "Labor": 0.664,
        "Total": 0.909,
        "Location": "MONTROSE, CO"
    },
    {
        "Year": "2023Q1",
        "Zip": 815,
        "State": "CO",
        "City": "GRAND JUNCTION",
        "Div": 999,
        "Mat": 1.095,
        "Labor": 0.709,
        "Total": 0.951,
        "Location": "GRAND JUNCTION, CO"
    },
    {
        "Year": "2023Q1",
        "Zip": 816,
        "State": "CO",
        "City": "GLENWOOD SPRINGS",
        "Div": 999,
        "Mat": 1.067,
        "Labor": 0.666,
        "Total": 0.918,
        "Location": "GLENWOOD SPRINGS, CO"
    },
    {
        "Year": "2023Q1",
        "Zip": 820,
        "State": "WY",
        "City": "CHEYENNE",
        "Div": 999,
        "Mat": 1.042,
        "Labor": 0.736,
        "Total": 0.928,
        "Location": "CHEYENNE, WY"
    },
    {
        "Year": "2023Q1",
        "Zip": 821,
        "State": "WY",
        "City": "YELLOWSTONE NAT'L PA",
        "Div": 999,
        "Mat": 1.019,
        "Labor": 0.704,
        "Total": 0.902,
        "Location": "YELLOWSTONE NAT'L PA, WY"
    },
    {
        "Year": "2023Q1",
        "Zip": 822,
        "State": "WY",
        "City": "WHEATLAND",
        "Div": 999,
        "Mat": 1.027,
        "Labor": 0.689,
        "Total": 0.901,
        "Location": "WHEATLAND, WY"
    },
    {
        "Year": "2023Q1",
        "Zip": 823,
        "State": "WY",
        "City": "RAWLINS",
        "Div": 999,
        "Mat": 1.039,
        "Labor": 0.703,
        "Total": 0.914,
        "Location": "RAWLINS, WY"
    },
    {
        "Year": "2023Q1",
        "Zip": 824,
        "State": "WY",
        "City": "WORLAND",
        "Div": 999,
        "Mat": 1.023,
        "Labor": 0.708,
        "Total": 0.906,
        "Location": "WORLAND, WY"
    },
    {
        "Year": "2023Q1",
        "Zip": 825,
        "State": "WY",
        "City": "RIVERTON",
        "Div": 999,
        "Mat": 1.031,
        "Labor": 0.703,
        "Total": 0.909,
        "Location": "RIVERTON, WY"
    },
    {
        "Year": "2023Q1",
        "Zip": 826,
        "State": "WY",
        "City": "CASPER",
        "Div": 999,
        "Mat": 1.056,
        "Labor": 0.717,
        "Total": 0.929,
        "Location": "CASPER, WY"
    },
    {
        "Year": "2023Q1",
        "Zip": 827,
        "State": "WY",
        "City": "NEWCASTLE",
        "Div": 999,
        "Mat": 1.021,
        "Labor": 0.703,
        "Total": 0.902,
        "Location": "NEWCASTLE, WY"
    },
    {
        "Year": "2023Q1",
        "Zip": 828,
        "State": "WY",
        "City": "SHERIDAN",
        "Div": 999,
        "Mat": 1.05,
        "Labor": 0.71,
        "Total": 0.923,
        "Location": "SHERIDAN, WY"
    },
    {
        "Year": "2023Q1",
        "Zip": 829,
        "State": "WY",
        "City": "ROCK SPRINGS",
        "Div": 999,
        "Mat": 1.063,
        "Labor": 0.714,
        "Total": 0.933,
        "Location": "ROCK SPRINGS, WY"
    },
    {
        "Year": "2023Q1",
        "Zip": 830,
        "State": "WY",
        "City": "ROCK SPRINGS",
        "Div": 999,
        "Mat": 1.063,
        "Labor": 0.714,
        "Total": 0.933,
        "Location": "ROCK SPRINGS, WY"
    },
    {
        "Year": "2023Q1",
        "Zip": 831,
        "State": "WY",
        "City": "ROCK SPRINGS",
        "Div": 999,
        "Mat": 1.063,
        "Labor": 0.714,
        "Total": 0.933,
        "Location": "ROCK SPRINGS, WY"
    },
    {
        "Year": "2023Q1",
        "Zip": 832,
        "State": "ID",
        "City": "POCATELLO",
        "Div": 999,
        "Mat": 1.08,
        "Labor": 0.786,
        "Total": 0.971,
        "Location": "POCATELLO, ID"
    },
    {
        "Year": "2023Q1",
        "Zip": 833,
        "State": "ID",
        "City": "TWIN FALLS",
        "Div": 999,
        "Mat": 1.081,
        "Labor": 0.768,
        "Total": 0.964,
        "Location": "TWIN FALLS, ID"
    },
    {
        "Year": "2023Q1",
        "Zip": 834,
        "State": "ID",
        "City": "IDAHO FALLS",
        "Div": 999,
        "Mat": 1.066,
        "Labor": 0.787,
        "Total": 0.962,
        "Location": "IDAHO FALLS, ID"
    },
    {
        "Year": "2023Q1",
        "Zip": 835,
        "State": "ID",
        "City": "LEWISTON",
        "Div": 999,
        "Mat": 1.13,
        "Labor": 0.888,
        "Total": 1.04,
        "Location": "LEWISTON, ID"
    },
    {
        "Year": "2023Q1",
        "Zip": 836,
        "State": "ID",
        "City": "BOISE",
        "Div": 999,
        "Mat": 1.07,
        "Labor": 0.819,
        "Total": 0.977,
        "Location": "BOISE, ID"
    },
    {
        "Year": "2023Q1",
        "Zip": 837,
        "State": "ID",
        "City": "BOISE",
        "Div": 999,
        "Mat": 1.07,
        "Labor": 0.819,
        "Total": 0.977,
        "Location": "BOISE, ID"
    },
    {
        "Year": "2023Q1",
        "Zip": 838,
        "State": "ID",
        "City": "COEUR D'ALENE",
        "Div": 999,
        "Mat": 1.125,
        "Labor": 0.849,
        "Total": 1.022,
        "Location": "COEUR D'ALENE, ID"
    },
    {
        "Year": "2023Q1",
        "Zip": 840,
        "State": "UT",
        "City": "SALT LAKE CITY",
        "Div": 999,
        "Mat": 1.087,
        "Labor": 0.737,
        "Total": 0.957,
        "Location": "SALT LAKE CITY, UT"
    },
    {
        "Year": "2023Q1",
        "Zip": 841,
        "State": "UT",
        "City": "SALT LAKE CITY",
        "Div": 999,
        "Mat": 1.087,
        "Labor": 0.737,
        "Total": 0.957,
        "Location": "SALT LAKE CITY, UT"
    },
    {
        "Year": "2023Q1",
        "Zip": 842,
        "State": "UT",
        "City": "OGDEN",
        "Div": 999,
        "Mat": 1.039,
        "Labor": 0.733,
        "Total": 0.925,
        "Location": "OGDEN, UT"
    },
    {
        "Year": "2023Q1",
        "Zip": 843,
        "State": "UT",
        "City": "LOGAN",
        "Div": 999,
        "Mat": 1.057,
        "Labor": 0.733,
        "Total": 0.936,
        "Location": "LOGAN, UT"
    },
    {
        "Year": "2023Q1",
        "Zip": 844,
        "State": "UT",
        "City": "OGDEN",
        "Div": 999,
        "Mat": 1.039,
        "Labor": 0.733,
        "Total": 0.925,
        "Location": "OGDEN, UT"
    },
    {
        "Year": "2023Q1",
        "Zip": 845,
        "State": "UT",
        "City": "PRICE",
        "Div": 999,
        "Mat": 1.056,
        "Labor": 0.659,
        "Total": 0.908,
        "Location": "PRICE, UT"
    },
    {
        "Year": "2023Q1",
        "Zip": 846,
        "State": "UT",
        "City": "PROVO",
        "Div": 999,
        "Mat": 1.059,
        "Labor": 0.74,
        "Total": 0.94,
        "Location": "PROVO, UT"
    },
    {
        "Year": "2023Q1",
        "Zip": 847,
        "State": "UT",
        "City": "PROVO",
        "Div": 999,
        "Mat": 1.059,
        "Labor": 0.74,
        "Total": 0.94,
        "Location": "PROVO, UT"
    },
    {
        "Year": "2023Q1",
        "Zip": 850,
        "State": "AZ",
        "City": "PHOENIX",
        "Div": 999,
        "Mat": 1.094,
        "Labor": 0.757,
        "Total": 0.968,
        "Location": "PHOENIX, AZ"
    },
    {
        "Year": "2023Q1",
        "Zip": 851,
        "State": "AZ",
        "City": "MESA/TEMPE",
        "Div": 999,
        "Mat": 1.058,
        "Labor": 0.733,
        "Total": 0.937,
        "Location": "MESA/TEMPE, AZ"
    },
    {
        "Year": "2023Q1",
        "Zip": 852,
        "State": "AZ",
        "City": "MESA/TEMPE",
        "Div": 999,
        "Mat": 1.058,
        "Labor": 0.733,
        "Total": 0.937,
        "Location": "MESA/TEMPE, AZ"
    },
    {
        "Year": "2023Q1",
        "Zip": 853,
        "State": "AZ",
        "City": "PHOENIX",
        "Div": 999,
        "Mat": 1.094,
        "Labor": 0.757,
        "Total": 0.968,
        "Location": "PHOENIX, AZ"
    },
    {
        "Year": "2023Q1",
        "Zip": 855,
        "State": "AZ",
        "City": "GLOBE",
        "Div": 999,
        "Mat": 1.059,
        "Labor": 0.719,
        "Total": 0.932,
        "Location": "GLOBE, AZ"
    },
    {
        "Year": "2023Q1",
        "Zip": 856,
        "State": "AZ",
        "City": "TUCSON",
        "Div": 999,
        "Mat": 1.046,
        "Labor": 0.744,
        "Total": 0.933,
        "Location": "TUCSON, AZ"
    },
    {
        "Year": "2023Q1",
        "Zip": 857,
        "State": "AZ",
        "City": "TUCSON",
        "Div": 999,
        "Mat": 1.046,
        "Labor": 0.744,
        "Total": 0.933,
        "Location": "TUCSON, AZ"
    },
    {
        "Year": "2023Q1",
        "Zip": 859,
        "State": "AZ",
        "City": "SHOW LOW",
        "Div": 999,
        "Mat": 1.061,
        "Labor": 0.72,
        "Total": 0.934,
        "Location": "SHOW LOW, AZ"
    },
    {
        "Year": "2023Q1",
        "Zip": 860,
        "State": "AZ",
        "City": "FLAGSTAFF",
        "Div": 999,
        "Mat": 1.069,
        "Labor": 0.73,
        "Total": 0.942,
        "Location": "FLAGSTAFF, AZ"
    },
    {
        "Year": "2023Q1",
        "Zip": 863,
        "State": "AZ",
        "City": "PRESCOTT",
        "Div": 999,
        "Mat": 1.046,
        "Labor": 0.728,
        "Total": 0.928,
        "Location": "PRESCOTT, AZ"
    },
    {
        "Year": "2023Q1",
        "Zip": 864,
        "State": "AZ",
        "City": "KINGMAN",
        "Div": 999,
        "Mat": 1.027,
        "Labor": 0.712,
        "Total": 0.91,
        "Location": "KINGMAN, AZ"
    },
    {
        "Year": "2023Q1",
        "Zip": 865,
        "State": "AZ",
        "City": "CHAMBERS",
        "Div": 999,
        "Mat": 1.026,
        "Labor": 0.718,
        "Total": 0.911,
        "Location": "CHAMBERS, AZ"
    },
    {
        "Year": "2023Q1",
        "Zip": 870,
        "State": "NM",
        "City": "ALBUQUERQUE",
        "Div": 999,
        "Mat": 1.052,
        "Labor": 0.713,
        "Total": 0.926,
        "Location": "ALBUQUERQUE, NM"
    },
    {
        "Year": "2023Q1",
        "Zip": 871,
        "State": "NM",
        "City": "ALBUQUERQUE",
        "Div": 999,
        "Mat": 1.052,
        "Labor": 0.713,
        "Total": 0.926,
        "Location": "ALBUQUERQUE, NM"
    },
    {
        "Year": "2023Q1",
        "Zip": 872,
        "State": "NM",
        "City": "ALBUQUERQUE",
        "Div": 999,
        "Mat": 1.052,
        "Labor": 0.713,
        "Total": 0.926,
        "Location": "ALBUQUERQUE, NM"
    },
    {
        "Year": "2023Q1",
        "Zip": 873,
        "State": "NM",
        "City": "GALLUP",
        "Div": 999,
        "Mat": 1.047,
        "Labor": 0.713,
        "Total": 0.923,
        "Location": "GALLUP, NM"
    },
    {
        "Year": "2023Q1",
        "Zip": 874,
        "State": "NM",
        "City": "FARMINGTON",
        "Div": 999,
        "Mat": 1.051,
        "Labor": 0.713,
        "Total": 0.925,
        "Location": "FARMINGTON, NM"
    },
    {
        "Year": "2023Q1",
        "Zip": 875,
        "State": "NM",
        "City": "SANTA FE",
        "Div": 999,
        "Mat": 1.072,
        "Labor": 0.72,
        "Total": 0.941,
        "Location": "SANTA FE, NM"
    },
    {
        "Year": "2023Q1",
        "Zip": 877,
        "State": "NM",
        "City": "LAS VEGAS",
        "Div": 999,
        "Mat": 1.031,
        "Labor": 0.713,
        "Total": 0.913,
        "Location": "LAS VEGAS, NM"
    },
    {
        "Year": "2023Q1",
        "Zip": 878,
        "State": "NM",
        "City": "SOCORRO",
        "Div": 999,
        "Mat": 1.029,
        "Labor": 0.713,
        "Total": 0.911,
        "Location": "SOCORRO, NM"
    },
    {
        "Year": "2023Q1",
        "Zip": 879,
        "State": "NM",
        "City": "TRUTH/CONSEQUENCES",
        "Div": 999,
        "Mat": 1.024,
        "Labor": 0.685,
        "Total": 0.898,
        "Location": "TRUTH/CONSEQUENCES, NM"
    },
    {
        "Year": "2023Q1",
        "Zip": 880,
        "State": "NM",
        "City": "LAS CRUCES",
        "Div": 999,
        "Mat": 1.017,
        "Labor": 0.697,
        "Total": 0.898,
        "Location": "LAS CRUCES, NM"
    },
    {
        "Year": "2023Q1",
        "Zip": 881,
        "State": "NM",
        "City": "CLOVIS",
        "Div": 999,
        "Mat": 1.034,
        "Labor": 0.723,
        "Total": 0.918,
        "Location": "CLOVIS, NM"
    },
    {
        "Year": "2023Q1",
        "Zip": 882,
        "State": "NM",
        "City": "ROSWELL",
        "Div": 999,
        "Mat": 1.052,
        "Labor": 0.723,
        "Total": 0.929,
        "Location": "ROSWELL, NM"
    },
    {
        "Year": "2023Q1",
        "Zip": 883,
        "State": "NM",
        "City": "CARRIZOZO",
        "Div": 999,
        "Mat": 1.053,
        "Labor": 0.723,
        "Total": 0.93,
        "Location": "CARRIZOZO, NM"
    },
    {
        "Year": "2023Q1",
        "Zip": 884,
        "State": "NM",
        "City": "TUCUMCARI",
        "Div": 999,
        "Mat": 1.039,
        "Labor": 0.723,
        "Total": 0.922,
        "Location": "TUCUMCARI, NM"
    },
    {
        "Year": "2023Q1",
        "Zip": 885,
        "State": "TX",
        "City": "EL PASO",
        "Div": 999,
        "Mat": 0.999,
        "Labor": 0.649,
        "Total": 0.869,
        "Location": "EL PASO, TX"
    },
    {
        "Year": "2023Q1",
        "Zip": 889,
        "State": "NV",
        "City": "LAS VEGAS",
        "Div": 999,
        "Mat": 1.108,
        "Labor": 1.08,
        "Total": 1.097,
        "Location": "LAS VEGAS, NV"
    },
    {
        "Year": "2023Q1",
        "Zip": 890,
        "State": "NV",
        "City": "LAS VEGAS",
        "Div": 999,
        "Mat": 1.108,
        "Labor": 1.08,
        "Total": 1.097,
        "Location": "LAS VEGAS, NV"
    },
    {
        "Year": "2023Q1",
        "Zip": 891,
        "State": "NV",
        "City": "LAS VEGAS",
        "Div": 999,
        "Mat": 1.108,
        "Labor": 1.08,
        "Total": 1.097,
        "Location": "LAS VEGAS, NV"
    },
    {
        "Year": "2023Q1",
        "Zip": 893,
        "State": "NV",
        "City": "ELY",
        "Div": 999,
        "Mat": 1.087,
        "Labor": 0.84,
        "Total": 0.995,
        "Location": "ELY, NV"
    },
    {
        "Year": "2023Q1",
        "Zip": 894,
        "State": "NV",
        "City": "RENO",
        "Div": 999,
        "Mat": 1.089,
        "Labor": 0.827,
        "Total": 0.991,
        "Location": "RENO, NV"
    },
    {
        "Year": "2023Q1",
        "Zip": 895,
        "State": "NV",
        "City": "RENO",
        "Div": 999,
        "Mat": 1.089,
        "Labor": 0.827,
        "Total": 0.991,
        "Location": "RENO, NV"
    },
    {
        "Year": "2023Q1",
        "Zip": 897,
        "State": "NV",
        "City": "CARSON CITY",
        "Div": 999,
        "Mat": 1.091,
        "Labor": 0.827,
        "Total": 0.993,
        "Location": "CARSON CITY, NV"
    },
    {
        "Year": "2023Q1",
        "Zip": 898,
        "State": "NV",
        "City": "ELKO",
        "Div": 999,
        "Mat": 1.072,
        "Labor": 0.768,
        "Total": 0.959,
        "Location": "ELKO, NV"
    },
    {
        "Year": "2023Q1",
        "Zip": 900,
        "State": "CA",
        "City": "LOS ANGELES",
        "Div": 999,
        "Mat": 1.103,
        "Labor": 1.346,
        "Total": 1.194,
        "Location": "LOS ANGELES, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 901,
        "State": "CA",
        "City": "LOS ANGELES",
        "Div": 999,
        "Mat": 1.103,
        "Labor": 1.346,
        "Total": 1.194,
        "Location": "LOS ANGELES, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 902,
        "State": "CA",
        "City": "LOS ANGELES",
        "Div": 999,
        "Mat": 1.103,
        "Labor": 1.346,
        "Total": 1.194,
        "Location": "LOS ANGELES, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 903,
        "State": "CA",
        "City": "INGLEWOOD",
        "Div": 999,
        "Mat": 1.019,
        "Labor": 1.321,
        "Total": 1.131,
        "Location": "INGLEWOOD, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 904,
        "State": "CA",
        "City": "INGLEWOOD",
        "Div": 999,
        "Mat": 1.019,
        "Labor": 1.321,
        "Total": 1.131,
        "Location": "INGLEWOOD, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 905,
        "State": "CA",
        "City": "INGLEWOOD",
        "Div": 999,
        "Mat": 1.019,
        "Labor": 1.321,
        "Total": 1.131,
        "Location": "INGLEWOOD, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 906,
        "State": "CA",
        "City": "LONG BEACH",
        "Div": 999,
        "Mat": 1.037,
        "Labor": 1.321,
        "Total": 1.143,
        "Location": "LONG BEACH, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 907,
        "State": "CA",
        "City": "LONG BEACH",
        "Div": 999,
        "Mat": 1.037,
        "Labor": 1.321,
        "Total": 1.143,
        "Location": "LONG BEACH, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 908,
        "State": "CA",
        "City": "LONG BEACH",
        "Div": 999,
        "Mat": 1.037,
        "Labor": 1.321,
        "Total": 1.143,
        "Location": "LONG BEACH, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 910,
        "State": "CA",
        "City": "PASADENA",
        "Div": 999,
        "Mat": 1.03,
        "Labor": 1.32,
        "Total": 1.138,
        "Location": "PASADENA, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 911,
        "State": "CA",
        "City": "PASADENA",
        "Div": 999,
        "Mat": 1.03,
        "Labor": 1.32,
        "Total": 1.138,
        "Location": "PASADENA, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 912,
        "State": "CA",
        "City": "PASADENA",
        "Div": 999,
        "Mat": 1.03,
        "Labor": 1.32,
        "Total": 1.138,
        "Location": "PASADENA, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 913,
        "State": "CA",
        "City": "VAN NUYS",
        "Div": 999,
        "Mat": 1.055,
        "Labor": 1.32,
        "Total": 1.154,
        "Location": "VAN NUYS, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 914,
        "State": "CA",
        "City": "VAN NUYS",
        "Div": 999,
        "Mat": 1.055,
        "Labor": 1.32,
        "Total": 1.154,
        "Location": "VAN NUYS, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 915,
        "State": "CA",
        "City": "VAN NUYS",
        "Div": 999,
        "Mat": 1.055,
        "Labor": 1.32,
        "Total": 1.154,
        "Location": "VAN NUYS, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 916,
        "State": "CA",
        "City": "VAN NUYS",
        "Div": 999,
        "Mat": 1.055,
        "Labor": 1.32,
        "Total": 1.154,
        "Location": "VAN NUYS, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 917,
        "State": "CA",
        "City": "ALHAMBRA",
        "Div": 999,
        "Mat": 1.066,
        "Labor": 1.32,
        "Total": 1.161,
        "Location": "ALHAMBRA, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 918,
        "State": "CA",
        "City": "ALHAMBRA",
        "Div": 999,
        "Mat": 1.066,
        "Labor": 1.32,
        "Total": 1.161,
        "Location": "ALHAMBRA, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 919,
        "State": "CA",
        "City": "SAN DIEGO",
        "Div": 999,
        "Mat": 1.097,
        "Labor": 1.279,
        "Total": 1.165,
        "Location": "SAN DIEGO, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 920,
        "State": "CA",
        "City": "SAN DIEGO",
        "Div": 999,
        "Mat": 1.097,
        "Labor": 1.279,
        "Total": 1.165,
        "Location": "SAN DIEGO, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 921,
        "State": "CA",
        "City": "SAN DIEGO",
        "Div": 999,
        "Mat": 1.097,
        "Labor": 1.279,
        "Total": 1.165,
        "Location": "SAN DIEGO, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 922,
        "State": "CA",
        "City": "PALM SPRINGS",
        "Div": 999,
        "Mat": 1.025,
        "Labor": 1.278,
        "Total": 1.119,
        "Location": "PALM SPRINGS, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 923,
        "State": "CA",
        "City": "SAN BERNARDINO",
        "Div": 999,
        "Mat": 1.001,
        "Labor": 1.272,
        "Total": 1.102,
        "Location": "SAN BERNARDINO, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 924,
        "State": "CA",
        "City": "SAN BERNARDINO",
        "Div": 999,
        "Mat": 1.001,
        "Labor": 1.272,
        "Total": 1.102,
        "Location": "SAN BERNARDINO, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 925,
        "State": "CA",
        "City": "RIVERSIDE",
        "Div": 999,
        "Mat": 1.046,
        "Labor": 1.299,
        "Total": 1.14,
        "Location": "RIVERSIDE, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 926,
        "State": "CA",
        "City": "SANTA ANA",
        "Div": 999,
        "Mat": 1.019,
        "Labor": 1.305,
        "Total": 1.125,
        "Location": "SANTA ANA, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 927,
        "State": "CA",
        "City": "SANTA ANA",
        "Div": 999,
        "Mat": 1.019,
        "Labor": 1.305,
        "Total": 1.125,
        "Location": "SANTA ANA, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 928,
        "State": "CA",
        "City": "ANAHEIM",
        "Div": 999,
        "Mat": 1.05,
        "Labor": 1.291,
        "Total": 1.14,
        "Location": "ANAHEIM, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 930,
        "State": "CA",
        "City": "OXNARD",
        "Div": 999,
        "Mat": 1.065,
        "Labor": 1.286,
        "Total": 1.147,
        "Location": "OXNARD, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 931,
        "State": "CA",
        "City": "SANTA BARBARA",
        "Div": 999,
        "Mat": 1.057,
        "Labor": 1.276,
        "Total": 1.139,
        "Location": "SANTA BARBARA, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 932,
        "State": "CA",
        "City": "BAKERSFIELD",
        "Div": 999,
        "Mat": 1.08,
        "Labor": 1.278,
        "Total": 1.154,
        "Location": "BAKERSFIELD, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 933,
        "State": "CA",
        "City": "BAKERSFIELD",
        "Div": 999,
        "Mat": 1.08,
        "Labor": 1.278,
        "Total": 1.154,
        "Location": "BAKERSFIELD, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 934,
        "State": "CA",
        "City": "SAN LUIS OBISPO",
        "Div": 999,
        "Mat": 1.065,
        "Labor": 1.293,
        "Total": 1.15,
        "Location": "SAN LUIS OBISPO, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 935,
        "State": "CA",
        "City": "MOJAVE",
        "Div": 999,
        "Mat": 1.036,
        "Labor": 1.265,
        "Total": 1.121,
        "Location": "MOJAVE, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 936,
        "State": "CA",
        "City": "FRESNO",
        "Div": 999,
        "Mat": 1.066,
        "Labor": 1.341,
        "Total": 1.169,
        "Location": "FRESNO, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 937,
        "State": "CA",
        "City": "FRESNO",
        "Div": 999,
        "Mat": 1.066,
        "Labor": 1.341,
        "Total": 1.169,
        "Location": "FRESNO, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 938,
        "State": "CA",
        "City": "FRESNO",
        "Div": 999,
        "Mat": 1.066,
        "Labor": 1.341,
        "Total": 1.169,
        "Location": "FRESNO, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 939,
        "State": "CA",
        "City": "SALINAS",
        "Div": 999,
        "Mat": 1.075,
        "Labor": 1.411,
        "Total": 1.2,
        "Location": "SALINAS, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 940,
        "State": "CA",
        "City": "SAN FRANCISCO",
        "Div": 999,
        "Mat": 1.138,
        "Labor": 1.65,
        "Total": 1.329,
        "Location": "SAN FRANCISCO, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 941,
        "State": "CA",
        "City": "SAN FRANCISCO",
        "Div": 999,
        "Mat": 1.138,
        "Labor": 1.65,
        "Total": 1.329,
        "Location": "SAN FRANCISCO, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 942,
        "State": "CA",
        "City": "SACRAMENTO",
        "Div": 999,
        "Mat": 1.053,
        "Labor": 1.368,
        "Total": 1.17,
        "Location": "SACRAMENTO, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 943,
        "State": "CA",
        "City": "PALO ALTO",
        "Div": 999,
        "Mat": 1.035,
        "Labor": 1.572,
        "Total": 1.235,
        "Location": "PALO ALTO, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 944,
        "State": "CA",
        "City": "SAN MATEO",
        "Div": 999,
        "Mat": 1.057,
        "Labor": 1.547,
        "Total": 1.24,
        "Location": "SAN MATEO, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 945,
        "State": "CA",
        "City": "VALLEJO",
        "Div": 999,
        "Mat": 1.052,
        "Labor": 1.446,
        "Total": 1.199,
        "Location": "VALLEJO, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 946,
        "State": "CA",
        "City": "OAKLAND",
        "Div": 999,
        "Mat": 1.084,
        "Labor": 1.545,
        "Total": 1.256,
        "Location": "OAKLAND, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 947,
        "State": "CA",
        "City": "BERKELEY",
        "Div": 999,
        "Mat": 1.077,
        "Labor": 1.545,
        "Total": 1.251,
        "Location": "BERKELEY, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 948,
        "State": "CA",
        "City": "RICHMOND",
        "Div": 999,
        "Mat": 1.064,
        "Labor": 1.476,
        "Total": 1.218,
        "Location": "RICHMOND, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 949,
        "State": "CA",
        "City": "SAN RAFAEL",
        "Div": 999,
        "Mat": 1.086,
        "Labor": 1.505,
        "Total": 1.242,
        "Location": "SAN RAFAEL, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 950,
        "State": "CA",
        "City": "SANTA CRUZ",
        "Div": 999,
        "Mat": 1.107,
        "Labor": 1.409,
        "Total": 1.22,
        "Location": "SANTA CRUZ, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 951,
        "State": "CA",
        "City": "SAN JOSE",
        "Div": 999,
        "Mat": 1.078,
        "Labor": 1.578,
        "Total": 1.265,
        "Location": "SAN JOSE, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 952,
        "State": "CA",
        "City": "STOCKTON",
        "Div": 999,
        "Mat": 1.06,
        "Labor": 1.361,
        "Total": 1.172,
        "Location": "STOCKTON, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 953,
        "State": "CA",
        "City": "MODESTO",
        "Div": 999,
        "Mat": 1.062,
        "Labor": 1.342,
        "Total": 1.166,
        "Location": "MODESTO, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 954,
        "State": "CA",
        "City": "SANTA ROSA",
        "Div": 999,
        "Mat": 1.058,
        "Labor": 1.515,
        "Total": 1.228,
        "Location": "SANTA ROSA, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 955,
        "State": "CA",
        "City": "EUREKA",
        "Div": 999,
        "Mat": 1.072,
        "Labor": 1.367,
        "Total": 1.182,
        "Location": "EUREKA, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 956,
        "State": "CA",
        "City": "SACRAMENTO",
        "Div": 999,
        "Mat": 1.053,
        "Labor": 1.368,
        "Total": 1.17,
        "Location": "SACRAMENTO, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 957,
        "State": "CA",
        "City": "SACRAMENTO",
        "Div": 999,
        "Mat": 1.053,
        "Labor": 1.368,
        "Total": 1.17,
        "Location": "SACRAMENTO, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 958,
        "State": "CA",
        "City": "SACRAMENTO",
        "Div": 999,
        "Mat": 1.053,
        "Labor": 1.368,
        "Total": 1.17,
        "Location": "SACRAMENTO, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 959,
        "State": "CA",
        "City": "MARYSVILLE",
        "Div": 999,
        "Mat": 1.066,
        "Labor": 1.345,
        "Total": 1.17,
        "Location": "MARYSVILLE, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 960,
        "State": "CA",
        "City": "REDDING",
        "Div": 999,
        "Mat": 1.114,
        "Labor": 1.333,
        "Total": 1.196,
        "Location": "REDDING, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 961,
        "State": "CA",
        "City": "SUSANVILLE",
        "Div": 999,
        "Mat": 1.106,
        "Labor": 1.341,
        "Total": 1.193,
        "Location": "SUSANVILLE, CA"
    },
    {
        "Year": "2023Q1",
        "Zip": 967,
        "State": "HI",
        "City": "HILO",
        "Div": 999,
        "Mat": 1.19,
        "Labor": 1.169,
        "Total": 1.182,
        "Location": "HILO, HI"
    },
    {
        "Year": "2023Q1",
        "Zip": 968,
        "State": "HI",
        "City": "HONOLULU",
        "Div": 999,
        "Mat": 1.25,
        "Labor": 1.178,
        "Total": 1.224,
        "Location": "HONOLULU, HI"
    },
    {
        "Year": "2023Q1",
        "Zip": 969,
        "State": "HI",
        "City": "STATES & POSS., GUAM",
        "Div": 999,
        "Mat": 1.456,
        "Labor": 0.614,
        "Total": 1.142,
        "Location": "STATES & POSS., GUAM, HI"
    },
    {
        "Year": "2023Q1",
        "Zip": 970,
        "State": "OR",
        "City": "PORTLAND",
        "Div": 999,
        "Mat": 1.043,
        "Labor": 1.073,
        "Total": 1.054,
        "Location": "PORTLAND, OR"
    },
    {
        "Year": "2023Q1",
        "Zip": 971,
        "State": "OR",
        "City": "PORTLAND",
        "Div": 999,
        "Mat": 1.043,
        "Labor": 1.073,
        "Total": 1.054,
        "Location": "PORTLAND, OR"
    },
    {
        "Year": "2023Q1",
        "Zip": 972,
        "State": "OR",
        "City": "PORTLAND",
        "Div": 999,
        "Mat": 1.043,
        "Labor": 1.073,
        "Total": 1.054,
        "Location": "PORTLAND, OR"
    },
    {
        "Year": "2023Q1",
        "Zip": 973,
        "State": "OR",
        "City": "SALEM",
        "Div": 999,
        "Mat": 1.069,
        "Labor": 1.048,
        "Total": 1.062,
        "Location": "SALEM, OR"
    },
    {
        "Year": "2023Q1",
        "Zip": 974,
        "State": "OR",
        "City": "EUGENE",
        "Div": 999,
        "Mat": 1.039,
        "Labor": 1.026,
        "Total": 1.034,
        "Location": "EUGENE, OR"
    },
    {
        "Year": "2023Q1",
        "Zip": 975,
        "State": "OR",
        "City": "MEDFORD",
        "Div": 999,
        "Mat": 1.05,
        "Labor": 0.998,
        "Total": 1.031,
        "Location": "MEDFORD, OR"
    },
    {
        "Year": "2023Q1",
        "Zip": 976,
        "State": "OR",
        "City": "KLAMATH FALLS",
        "Div": 999,
        "Mat": 1.05,
        "Labor": 0.981,
        "Total": 1.025,
        "Location": "KLAMATH FALLS, OR"
    },
    {
        "Year": "2023Q1",
        "Zip": 977,
        "State": "OR",
        "City": "BEND",
        "Div": 999,
        "Mat": 1.038,
        "Labor": 1.005,
        "Total": 1.026,
        "Location": "BEND, OR"
    },
    {
        "Year": "2023Q1",
        "Zip": 978,
        "State": "OR",
        "City": "PENDLETON",
        "Div": 999,
        "Mat": 1.012,
        "Labor": 0.999,
        "Total": 1.007,
        "Location": "PENDLETON, OR"
    },
    {
        "Year": "2023Q1",
        "Zip": 979,
        "State": "OR",
        "City": "VALE",
        "Div": 999,
        "Mat": 0.992,
        "Labor": 0.878,
        "Total": 0.949,
        "Location": "VALE, OR"
    },
    {
        "Year": "2023Q1",
        "Zip": 980,
        "State": "WA",
        "City": "SEATTLE",
        "Div": 999,
        "Mat": 1.086,
        "Labor": 1.164,
        "Total": 1.115,
        "Location": "SEATTLE, WA"
    },
    {
        "Year": "2023Q1",
        "Zip": 981,
        "State": "WA",
        "City": "SEATTLE",
        "Div": 999,
        "Mat": 1.086,
        "Labor": 1.164,
        "Total": 1.115,
        "Location": "SEATTLE, WA"
    },
    {
        "Year": "2023Q1",
        "Zip": 982,
        "State": "WA",
        "City": "EVERETT",
        "Div": 999,
        "Mat": 1.072,
        "Labor": 1.081,
        "Total": 1.075,
        "Location": "EVERETT, WA"
    },
    {
        "Year": "2023Q1",
        "Zip": 983,
        "State": "WA",
        "City": "TACOMA",
        "Div": 999,
        "Mat": 1.084,
        "Labor": 1.097,
        "Total": 1.089,
        "Location": "TACOMA, WA"
    },
    {
        "Year": "2023Q1",
        "Zip": 984,
        "State": "WA",
        "City": "TACOMA",
        "Div": 999,
        "Mat": 1.084,
        "Labor": 1.097,
        "Total": 1.089,
        "Location": "TACOMA, WA"
    },
    {
        "Year": "2023Q1",
        "Zip": 985,
        "State": "WA",
        "City": "OLYMPIA",
        "Div": 999,
        "Mat": 1.077,
        "Labor": 1.106,
        "Total": 1.088,
        "Location": "OLYMPIA, WA"
    },
    {
        "Year": "2023Q1",
        "Zip": 986,
        "State": "WA",
        "City": "VANCOUVER",
        "Div": 999,
        "Mat": 1.097,
        "Labor": 1.029,
        "Total": 1.072,
        "Location": "VANCOUVER, WA"
    },
    {
        "Year": "2023Q1",
        "Zip": 987,
        "State": "WA",
        "City": "SEATTLE",
        "Div": 999,
        "Mat": 1.086,
        "Labor": 1.164,
        "Total": 1.115,
        "Location": "SEATTLE, WA"
    },
    {
        "Year": "2023Q1",
        "Zip": 988,
        "State": "WA",
        "City": "WENATCHEE",
        "Div": 999,
        "Mat": 1.074,
        "Labor": 0.85,
        "Total": 0.991,
        "Location": "WENATCHEE, WA"
    },
    {
        "Year": "2023Q1",
        "Zip": 989,
        "State": "WA",
        "City": "YAKIMA",
        "Div": 999,
        "Mat": 1.08,
        "Labor": 0.971,
        "Total": 1.039,
        "Location": "YAKIMA, WA"
    },
    {
        "Year": "2023Q1",
        "Zip": 990,
        "State": "WA",
        "City": "SPOKANE",
        "Div": 999,
        "Mat": 1.037,
        "Labor": 0.9,
        "Total": 0.986,
        "Location": "SPOKANE, WA"
    },
    {
        "Year": "2023Q1",
        "Zip": 991,
        "State": "WA",
        "City": "SPOKANE",
        "Div": 999,
        "Mat": 1.037,
        "Labor": 0.9,
        "Total": 0.986,
        "Location": "SPOKANE, WA"
    },
    {
        "Year": "2023Q1",
        "Zip": 992,
        "State": "WA",
        "City": "SPOKANE",
        "Div": 999,
        "Mat": 1.037,
        "Labor": 0.9,
        "Total": 0.986,
        "Location": "SPOKANE, WA"
    },
    {
        "Year": "2023Q1",
        "Zip": 993,
        "State": "WA",
        "City": "RICHLAND",
        "Div": 999,
        "Mat": 1.032,
        "Labor": 0.95,
        "Total": 1.001,
        "Location": "RICHLAND, WA"
    },
    {
        "Year": "2023Q1",
        "Zip": 994,
        "State": "WA",
        "City": "CLARKSTON",
        "Div": 999,
        "Mat": 1.02,
        "Labor": 0.758,
        "Total": 0.923,
        "Location": "CLARKSTON, WA"
    },
    {
        "Year": "2023Q1",
        "Zip": 995,
        "State": "AK",
        "City": "ANCHORAGE",
        "Div": 999,
        "Mat": 1.281,
        "Labor": 1.099,
        "Total": 1.213,
        "Location": "ANCHORAGE, AK"
    },
    {
        "Year": "2023Q1",
        "Zip": 996,
        "State": "AK",
        "City": "ANCHORAGE",
        "Div": 999,
        "Mat": 1.281,
        "Labor": 1.099,
        "Total": 1.213,
        "Location": "ANCHORAGE, AK"
    },
    {
        "Year": "2023Q1",
        "Zip": 997,
        "State": "AK",
        "City": "FAIRBANKS",
        "Div": 999,
        "Mat": 1.249,
        "Labor": 1.112,
        "Total": 1.198,
        "Location": "FAIRBANKS, AK"
    },
    {
        "Year": "2023Q1",
        "Zip": 998,
        "State": "AK",
        "City": "JUNEAU",
        "Div": 999,
        "Mat": 1.218,
        "Labor": 1.099,
        "Total": 1.173,
        "Location": "JUNEAU, AK"
    },
    {
        "Year": "2023Q1",
        "Zip": 999,
        "State": "AK",
        "City": "KETCHIKAN",
        "Div": 999,
        "Mat": 1.332,
        "Labor": 1.1,
        "Total": 1.245,
        "Location": "KETCHIKAN, AK"
    }
];
export default cciLabor;